import React from 'react'
import { useState, useEffect, useRef } from 'react'
import '../Css/Game.css';
import GameTable from '../Images/GameTable.png'
import MbGameTable from '../Images/MbGameTable.png'
import EmptySeat from '../Images/EmptySeat.svg'
import { useMediaQuery } from 'react-responsive'
import socketConfig from '../Socket/config.js'
import Modal from 'react-modal';
import * as io from 'socket.io-client';
import '../Css/Game.css'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import useSound from 'use-sound';
import chipsMove from '../Audio/call.wav';
import collect from '../Audio/collect.wav';
import foldCard from '../Audio/fold.mp3';
import cardDealt from '../Audio/deal_cards.wav';
import checkNew from '../Audio/checkTurn.mp3';
import myTurn from '../Audio/my_turn.wav';
import winner from '../Audio/win_sound.mp3';
import card_shuffle from '../Audio/card_shuffle.wav';
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import socket from '../socket';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

const URL = process.env.REACT_APP_SOCKET_URL;

const GameScreen = () => {
    const [cookies, setCookie] = useCookies(['roomId', 'roomType', 'gameType', 'gameFormat']);
    const [voice, setVoice] = useState(true);
    const [selectedHour, setSelectedHour] = useState(0);
    // const [hoursOption, setHoursOption] = useState(['01', '02', '03', '04', '05', '06', '07', '08', '09', 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24])
    const [hoursOption, setHoursOption] = useState([
        { 'value': '02:00', 'name': 'STARTER (Get 2 Hour, $25 per hour)' },
        { 'value': '05:00', 'name': 'BOOSTER (Get 5 Hour, $22 per hour)' },
        { 'value': '10:00', 'name': 'BRONZE (Get 10 Hour, $20 per hour)' },
        { 'value': '20:00', 'name': 'SILVER (Get 20 Hour, $18 per hour)' },
        { 'value': '30:00', 'name': 'GOLD (Get 30 Hour, $15 per hour)' },
        { 'value': '50:00', 'name': 'PLATINUM (Get 50 Hour, $12 per hour)' },
        { 'value': '75:00', 'name': 'TITANIUM (Get 75 Hour, $10 per hour)' },
        { 'value': '100:00', 'name': 'DIAMOND (Get 100 Hour, $5 per hour)' },
    ])
    const navigate = useNavigate();
    const [chipsVoice] = useSound(chipsMove);
    const [foldCardVoice] = useSound(foldCard);
    const [cardDealtVoice] = useSound(cardDealt);
    const [checkActionVoice] = useSound(checkNew);
    const [myTurnVoice] = useSound(myTurn);
    const [winnerVoice] = useSound(winner);
    const [collectVoice] = useSound(collect);
    const [cardShuffleVoice] = useSound(card_shuffle);
    const aspectRatio = 16 / 9; // 4:3 aspect ratio
    const [showCard, setShowCard] = useState(false);
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [isFooterPanel, setIsFooterPanel] = useState(false);
    const [tableData, setTableData] = useState();
    const [emptySeatImg, setEmptySeatImg] = useState();
    const [playerProfile, setPlayerProfile] = useState();
    const [blockCard, setBlockCard] = useState();
    const [displayCard, setDisplayCard] = useState();
    const [displayBackCard, setDisplayBackCard] = useState();
    const [displayCardSecond, setDisplayCardSecond] = useState();
    const [hiddenCards, sethiddenCards] = useState([]);
    const [hiddenCard1Css, setHiddenCard1Css] = useState();
    const [hiddenCard2Css, setHiddenCard2Css] = useState();
    const [hiddenCard3Css, setHiddenCard3Css] = useState();
    const [hiddenCard4Css, setHiddenCard4Css] = useState();
    const [hiddenCard5Css, setHiddenCard5Css] = useState();
    const [tablePortBlock, setTablePortBlock] = useState();
    const [tableImage, setTableImage] = useState();
    const [emptySeat1, setEmptySeat1] = useState();
    const [emptySeat2, setEmptySeat2] = useState();
    const [emptySeat3, setEmptySeat3] = useState();
    const [emptySeat4, setEmptySeat4] = useState();
    const [emptySeat5, setEmptySeat5] = useState();
    const [emptySeat6, setEmptySeat6] = useState();
    const [emptySeat7, setEmptySeat7] = useState();
    const [emptySeat8, setEmptySeat8] = useState();
    const [emptySeat9, setEmptySeat9] = useState();
    const [filledSeat1, setFilledSeat1] = useState();
    const [filledSeat2, setFilledSeat2] = useState();
    const [filledSeat3, setFilledSeat3] = useState();
    const [filledSeat4, setFilledSeat4] = useState();
    const [filledSeat5, setFilledSeat5] = useState();
    const [filledSeat6, setFilledSeat6] = useState();
    const [filledSeat7, setFilledSeat7] = useState();
    const [filledSeat8, setFilledSeat8] = useState();
    const [filledSeat9, setFilledSeat9] = useState();

    const [cardAnimation1, setCardAnimation1] = useState();
    const [cardAnimation2, setCardAnimation2] = useState();
    const [cardAnimation3, setCardAnimation3] = useState();
    const [cardAnimation4, setCardAnimation4] = useState();
    const [cardAnimation5, setCardAnimation5] = useState();
    const [cardAnimation6, setCardAnimation6] = useState();
    const [cardAnimation7, setCardAnimation7] = useState();
    const [cardAnimation8, setCardAnimation8] = useState();
    const [cardAnimation9, setCardAnimation9] = useState();


    const [playerChips0, setPlayerChips0] = useState();
    const [playerChips1, setPlayerChips1] = useState();
    const [playerChips2, setPlayerChips2] = useState();
    const [playerChips3, setPlayerChips3] = useState();
    const [playerChips4, setPlayerChips4] = useState();
    const [playerChips5, setPlayerChips5] = useState();
    const [playerChips6, setPlayerChips6] = useState();
    const [playerChips7, setPlayerChips7] = useState();
    const [playerChips8, setPlayerChips8] = useState();
    const [playerChips9, setPlayerChips9] = useState();

    const [displayCard1, setDisplayCard1] = useState(false);
    const [displayCard2, setDisplayCard2] = useState(false);
    const [displayCard3, setDisplayCard3] = useState(false);
    const [displayCard4, setDisplayCard4] = useState(false);


    const [animationCardDisplay, setAnimationCardDisplay] = useState({ display: 'none' });
    const [gameBootData, setGameBootData] = useState({});
    const [cardDistribute, setCardDistribute] = useState(false)
    const [gameStart, setGameStart] = useState(false)
    const [isRoomModalOpen, setIsRoomModalOpen] = useState(false);
    const [isBuyHourModalOpen, setIsBuyHourModalOpen] = useState(false);
    const [isLowBalanceModalOpen, setIsLowBalanceModalOpen] = useState(false);
    const [isCloseLowBalanceModalOpen, setIsCloseLowBalanceModalOpen] = useState(true);
    const [isCloseInsufficientBalanceModalOpen, setIsCloseInsufficientBalanceModalOpen] = useState(true);
    const [isInsufficientBalanceModalOpen, setIsInsufficientBalanceModalOpen] = useState(false);
    const [emptyTable, setEmptyTable] = useState(true)
    const [seatPosition, setSeatPosition] = useState()
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(0);
    const [minBuyIn, setMinBuyIn] = useState(0);
    const [maxBuyIn, setMaxBuyIn] = useState(100);
    const [gameData, setGameData] = useState({});
    const [playerInfo, setPlayerInfo] = useState([]);
    const [playerCards, setPlayerCards] = useState([]);
    const [gameFinishedPlayersCards, setGameFinishedPlayersCards] = useState([]);
    const [gamePlayData, setGamePlayData] = useState({});
    const [minRaise, setMinRaise] = useState(-1);
    const [maxRaise, setMaxRaise] = useState(100);
    const [raiseAmount, setRaiseAmount] = useState('');
    const [playerActionData, setPlayerActionData] = useState(0);
    const [tablePot, setTablePot] = useState(0);
    const [maxTimer, setMaxTimer] = useState(20);
    const [playerSidePot, setPlayerSidePot] = useState(0);
    const [winnerSeatIndex, setWinnerSeatIndex] = useState(-1);
    const [winningAmount, setWinningAmount] = useState(-1);
    const [winningType, setWinningType] = useState('');
    const [tableSeats, setTableSeats] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8]);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
    const [roomData, setRoomData] = useState({})
    const [idealPlayer, setIdealPlayer] = useState({})
    const [buyIn, setBuyIn] = useState(false)
    const [isLoader, setIsLoader] = useState(true);
    const [sitOutNextHand, setSitOutNextHand] = useState(false);
    const [sitOutNextBigBlind, setSitOutNextBigBlind] = useState(false);
    const [defaultAction, setDefaultAction] = useState('');
    const [isMobile, setIsMobile] = useState(false)
    const [windowDimension, setWindowDimension] = useState(null);
    const [playerData, setPlayerData] = useState(JSON.parse(localStorage.getItem('user')));
    const [interval, setIntervalId] = useState('');
    const [socketIo, setsocketIo] = useState('');
    const [playerActionType, setPlayerActionType] = useState('');
    const [sidebarWidth, setSidebarWidth] = useState({});
    const [sidebarMobileWidth, setSidebarMobileWidth] = useState({});
    const [userMessage, setUserMessage] = useState('');
    const [openEmojiBox, setOpenEmojiBox] = useState(false);
    const [gameChatMessages, setGameChatMessages] = useState([]);
    const messageEl = useRef(null);
    const [sitOut, setSitOut] = useState({
        nextBlind: false,
        bigBlind: false
    });
    const roomType = cookies.roomType;
    const roomId = cookies.roomId;
    const gameType = cookies.gameType;
    const gameFormat = cookies.gameFormat;

    useEffect(() => {
        setTimeout(() => {
            setIsLoader(false);
        }, 5000)
        let socketIoD = '';
        if (!roomType) {
            navigate('/lobby');
        }
        if (!socketIo) {
            socketIoD = io(URL + roomType);
            setsocketIo(socketIoD);
        }
        const mobile = window.innerWidth <= 1024;
        setIsMobile(mobile);
    }, [socketIo]);

    useEffect(() => {
        const mobile = window.innerWidth <= 1024;
        setIsMobile(mobile);
    }, [window.innerWidth]);

    useEffect(() => {
        if (messageEl) {
            messageEl.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });
        }
    }, [openEmojiBox])

    useEffect(() => {
        if (socketIo) {
            const socketId = socketIo.id;

            const handleTabClose = event => {
                leaveGame();
            };
            window.addEventListener('beforeunload', handleTabClose);
            const resizeWindow = () => {
                const width = window.outerWidth;
                const expectedHeight = Math.round(width / aspectRatio);
                window.resizeTo(width, expectedHeight);
            };
            const mobile = window.innerWidth <= 1024;
            const updateScreenSize = () => {

                if (mobile) {
                    setHiddenCard1Css({
                        top: window.innerHeight / 2.5,
                        // top: '33%',
                        left: '28%'
                    });
                    setHiddenCard2Css({
                        top: window.innerHeight / 2.5,
                        // top: '33%',
                        left: '37%'
                    });
                    setHiddenCard3Css({
                        top: window.innerHeight / 2.5,
                        left: '46%'
                    });
                    setHiddenCard4Css({
                        top: window.innerHeight / 2.5,
                        // top: '33%',
                        left: '55%'
                    });
                    setHiddenCard5Css({
                        top: window.innerHeight / 2.5,
                        // top: '33%',
                        left: '64%'
                    });
                    setTableImage({
                        top: window.innerHeight / 6,
                        left: window.innerWidth / 11.5,
                        width: window.innerWidth / 1.2,
                        height: window.innerHeight / 1.6
                    });

                    setEmptySeatImg({
                        width: window.innerHeight / 22,
                        height: window.innerHeight / 22
                    });

                    setPlayerProfile({
                        width: window.innerHeight / 15,
                        height: window.innerHeight / 15
                    });

                    setEmptySeat1({
                        right: window.innerWidth / 2.3,
                        top: window.innerHeight / 1.380
                    });

                    setEmptySeat2({
                        right: window.innerWidth / 1.400,
                        top: window.innerHeight / 1.5
                    });

                    setEmptySeat3({
                        right: window.innerWidth / 1.260,
                        top: window.innerHeight / 2
                    });

                    setEmptySeat4({
                        right: window.innerWidth / 1.330,
                        top: window.innerHeight / 3
                    });

                    setEmptySeat5({
                        right: window.innerWidth / 1.650,
                        top: window.innerHeight / 5.5
                    });

                    setEmptySeat6({
                        left: window.innerWidth / 1.650,
                        top: window.innerHeight / 5.5
                    });

                    setEmptySeat7({
                        left: window.innerWidth / 1.320,
                        top: window.innerHeight / 3
                    });

                    setEmptySeat8({
                        left: window.innerWidth / 1.260,
                        top: window.innerHeight / 2
                    });

                    setEmptySeat9({
                        left: window.innerWidth / 1.3,
                        top: window.innerHeight / 1.5
                    });


                    setFilledSeat1({
                        right: window.innerWidth / 2.4,
                        top: window.innerHeight / 1.450
                    });

                    setFilledSeat2({
                        right: window.innerWidth / 1.400,
                        top: window.innerHeight / 1.575
                    });

                    setFilledSeat3({
                        right: window.innerWidth / 1.260,
                        top: window.innerHeight / 2.150
                    });

                    setFilledSeat4({
                        right: window.innerWidth / 1.330,
                        top: window.innerHeight / 3.5
                    });

                    setFilledSeat5({
                        right: window.innerWidth / 1.650,
                        top: window.innerHeight / 7
                    });

                    setFilledSeat6({
                        left: window.innerWidth / 1.650,
                        top: window.innerHeight / 7
                    });

                    setFilledSeat7({
                        left: window.innerWidth / 1.320,
                        top: window.innerHeight / 3.5
                    });

                    setFilledSeat8({
                        left: window.innerWidth / 1.260,
                        top: window.innerHeight / 2.150
                    });

                    setFilledSeat9({
                        left: window.innerWidth / 1.3,
                        top: window.innerHeight / 1.575
                    });

                    setTablePortBlock({
                        left: window.innerWidth / 2.7,
                        top: window.innerHeight / 3.5,
                    });


                    setPlayerChips0({
                        left: window.innerWidth / 2.3,
                        top: window.innerHeight / 2,
                        // right: window.innerWidth / 2.150,
                        // top: window.innerWidth / 2.150,
                    });

                    setPlayerChips1({
                        right: window.innerWidth / 2.150,
                        top: window.innerHeight / 1.600
                    });

                    setPlayerChips2({
                        right: window.innerWidth / 1.6,
                        top: window.innerHeight / 1.650
                    });

                    setPlayerChips3({
                        right: window.innerWidth / 1.460,
                        top: window.innerHeight / 2
                    });

                    setPlayerChips4({
                        right: window.innerWidth / 1.500,
                        top: window.innerHeight / 3
                    });

                    setPlayerChips5({
                        right: window.innerWidth / 1.7,
                        top: window.innerHeight / 3.9
                    });

                    setPlayerChips6({
                        left: window.innerWidth / 1.7,
                        top: window.innerHeight / 3.9
                    });

                    setPlayerChips7({
                        left: window.innerWidth / 1.500,
                        top: window.innerHeight / 3
                    });

                    setPlayerChips8({
                        left: window.innerWidth / 1.460,
                        top: window.innerHeight / 2
                    });

                    setPlayerChips9({
                        left: window.innerWidth / 1.5,
                        top: window.innerHeight / 1.650
                    });

                    setDisplayCard({
                        width: window.innerWidth / 15,
                        height: window.innerHeight / 20
                    });

                    setDisplayBackCard({
                        width: window.innerWidth / 15,
                        height: window.innerHeight / 20
                    });

                    setDisplayCardSecond({
                        width: window.innerWidth / 26,
                        height: window.innerHeight / 18
                    });

                    setBlockCard({
                        width: window.innerWidth / 18,
                        height: window.innerHeight / 18
                    });

                } else {
                    setHiddenCard1Css({
                        width: window.innerWidth / 30,
                        height: window.innerWidth / 30,
                        top: window.innerWidth / 5.8,
                        right: window.innerWidth / 1.750
                    });
                    setHiddenCard2Css({
                        width: window.innerWidth / 30,
                        height: window.innerWidth / 30,
                        top: window.innerWidth / 5.8,
                        right: window.innerWidth / 1.890
                    });
                    setHiddenCard3Css({
                        width: window.innerWidth / 30,
                        height: window.innerWidth / 30,
                        top: window.innerWidth / 5.8,
                        right: window.innerWidth / 2.05
                    });
                    setHiddenCard4Css({
                        width: window.innerWidth / 30,
                        height: window.innerWidth / 30,
                        top: window.innerWidth / 5.8,
                        right: window.innerWidth / 2.25
                    });
                    setHiddenCard5Css({
                        width: window.innerWidth / 30,
                        height: window.innerWidth / 30,
                        top: window.innerWidth / 5.8,
                        right: window.innerWidth / 2.48
                    });
                    setTableImage({
                        left: window.innerWidth / 4.2,
                        top: window.innerWidth / 20,
                        width: window.innerWidth / 1.900,
                        height: window.innerWidth / 3.1,
                    });
                    setEmptySeatImg({
                        width: window.innerWidth / 28,
                        height: window.innerWidth / 28
                    });
                    setPlayerProfile({
                        width: window.innerWidth / 20,
                        height: window.innerWidth / 20
                    });

                    setEmptySeat1({
                        right: window.innerWidth / 2.100,
                        top: window.innerWidth / 3.10
                    });

                    setEmptySeat2({
                        right: window.innerWidth / 1.525,
                        top: window.innerWidth / 3.30
                    });

                    setEmptySeat3({
                        right: window.innerWidth / 1.390,
                        top: window.innerWidth / 4.7
                    });

                    setEmptySeat4({
                        right: window.innerWidth / 1.450,
                        top: window.innerWidth / 10.5
                    });

                    setEmptySeat5({
                        right: window.innerWidth / 1.750,
                        top: window.innerWidth / 18
                    });

                    setEmptySeat6({
                        left: window.innerWidth / 1.750,
                        top: window.innerWidth / 18
                    });

                    setEmptySeat7({
                        left: window.innerWidth / 1.450,
                        top: window.innerWidth / 10.5
                    });

                    setEmptySeat8({
                        left: window.innerWidth / 1.390,
                        top: window.innerWidth / 4.7
                    });

                    setEmptySeat9({
                        left: window.innerWidth / 1.525,
                        top: window.innerWidth / 3.30
                    });

                    setFilledSeat1({
                        right: window.innerWidth / 2.1,
                        top: window.innerWidth / 3.4
                    });

                    setFilledSeat2({
                        right: window.innerWidth / 1.60,
                        top: window.innerWidth / 3.5
                    });

                    setFilledSeat3({
                        right: window.innerWidth / 1.400,
                        top: window.innerWidth / 5
                    });

                    setFilledSeat4({
                        right: window.innerWidth / 1.430,
                        top: window.innerWidth / 13
                    });

                    setFilledSeat5({
                        right: window.innerWidth / 1.800,
                        top: window.innerWidth / 45
                    });

                    setFilledSeat6({
                        left: window.innerWidth / 1.800,
                        top: window.innerWidth / 45
                    });

                    setFilledSeat7({
                        left: window.innerWidth / 1.430,
                        top: window.innerWidth / 13
                    });

                    setFilledSeat8({
                        left: window.innerWidth / 1.400,
                        top: window.innerWidth / 5
                    });

                    setFilledSeat9({
                        left: window.innerWidth / 1.60,
                        top: window.innerWidth / 3.5
                    });

                    setTablePortBlock({
                        left: window.innerWidth / 2.15,
                        top: window.innerWidth / 8,
                    });

                    setPlayerChips0({
                        left: window.innerWidth / 2.12,
                        top: window.innerWidth / 6.8,
                    });

                    setPlayerChips1({
                        right: window.innerWidth / 2.02,
                        top: window.innerWidth / 4.1
                    });

                    setPlayerChips2({
                        right: window.innerWidth / 1.600,
                        top: window.innerWidth / 3.98
                    });

                    setPlayerChips3({
                        right: window.innerWidth / 1.480,
                        top: window.innerWidth / 4.7
                    });

                    setPlayerChips4({
                        right: window.innerWidth / 1.510,
                        top: window.innerWidth / 7.2
                    });

                    setPlayerChips5({
                        right: window.innerWidth / 1.75,
                        top: window.innerWidth / 9.3
                    });

                    setPlayerChips6({
                        left: window.innerWidth / 1.75,
                        top: window.innerWidth / 9.3
                    });

                    setPlayerChips7({
                        left: window.innerWidth / 1.510,
                        top: window.innerWidth / 7.2
                    });

                    setPlayerChips8({
                        left: window.innerWidth / 1.480,
                        top: window.innerWidth / 4.7
                    });

                    setPlayerChips9({
                        left: window.innerWidth / 1.600,
                        top: window.innerWidth / 3.98
                    });
                    setDisplayCard({
                        width: window.innerWidth / 32,
                        height: window.innerWidth / 22
                    });
                    setDisplayBackCard({
                        width: window.innerWidth / 40,
                        height: window.innerWidth / 30
                    });

                    setDisplayCardSecond({
                        width: window.innerWidth / 26,
                        height: window.innerWidth / 18
                    });

                    setBlockCard({
                        width: window.innerWidth / 18,
                        height: window.innerWidth / 18
                    });
                }
            };

            window.addEventListener("resize", resizeWindow);
            window.addEventListener("resize", updateScreenSize);

            updateScreenSize();

            if (roomId) {
                if (gameFormat == 'tournament' || gameFormat == 'sng') {
                    setEmptyTable(false);
                }

                socketIo.emit('OnReservedSeatList', { roomId });
                socketIo.on('OnReservedSeatList', (data) => {
                });

                socketIo.emit('playerInfoList', { roomId });
                socketIo.on('playerInfoList', (data) => {
                    handlePlayerInfo(data.playerInfo)
                });
                const data = {
                    playerId: playerData.playerId,
                    roomId: roomId,
                    latitude: 0,
                    longitude: 0,
                    productName: 'BytePoker'
                }
                socketIo.emit('SubscribeRoom', data, (res) => {
                    if (res.result) {
                        socketIo.on('Chat', (res) => {
                            console.log(res);
                            if (res.message != '') {
                                const oldMessage = gameChatMessages;
                                oldMessage.push(res);
                                setGameChatMessages(oldMessage)
                            }
                        });

                        socketIo.emit('OnSubscribeRoom', { socketId });
                        socketIo.on('OnSubscribeRoom', (data) => {
                        });


                        setRoomData(res.result);

                        socketIo.emit('OnGameStartWait', { roomId });
                        socketIo.on('OnGameStartWait', (data) => {

                        });

                        socketIo.emit('GameStarted', { roomId });
                        socketIo.on('GameStarted', (data) => {
                            cardAnimation();
                            setGameFinishedPlayersCards({});
                            setDefaultAction('');
                            setSitOut({
                                nextBlind: false,
                                bigBlind: false
                            });
                            setWinnerSeatIndex(-1);
                            setWinningAmount(-1);
                            setWinningType('');
                        });

                        socketIo.emit('superPlayerData', { socketId });
                        socketIo.on('superPlayerData', (data) => {

                        });
                        socketIo.emit('OnReservedSeatList', { roomId });
                        socketIo.on('OnReservedSeatList', (data) => {

                        });
                        socketIo.emit('onIdealPlayer', { roomId });
                        socketIo.on('onIdealPlayer', (data) => {
                            if (data.status == true) {
                                setIdealPlayer(data);
                            } else {
                                setIdealPlayer({});
                            }
                        });
                        socketIo.emit('RevertPoint', { roomId });
                        socketIo.on('RevertPoint', (data) => {
                            console.log('RevertPoint');
                            console.log(data);
                        });

                        socketIo.emit('OnWaitingJoinRoom', { socketId });
                        socketIo.on('OnWaitingJoinRoom', (data) => {
                            console.log('OnWaitingJoinRoom')
                            console.log(data)
                        })
                        socketIo.emit('OnPlayerCards', { socketId });
                        socketIo.on('OnPlayerCards', (data) => {
                            if (data) {
                                setPlayerCards(data)
                            }
                        });
                        socketIo.emit('OnTurnTimer', { roomId });
                        socketIo.on('OnTurnTimer', (data) => {
                            setPlayerActionType('')
                            setGamePlayData(data);
                            setMaxTimer(data.maxTimer)
                            setIsFooterPanel(true);
                            setIsPanelOpen(true);
                            if (data.timer == (data.maxTimer - 1)) {
                                setMinRaise(data.buttonAction.minRaise)
                                setMaxRaise(data.buttonAction.maxRaise)
                                setRaiseAmount(data.buttonAction.minRaise);
                            }
                            if (data.playerId == playerData.playerId && data.timer == (data.maxTimer - 1)) {
                                playVoice(5)
                            }
                        });
                        socketIo.emit('OnPlayersCardsDistribution', { roomId });
                        socketIo.on('OnPlayersCardsDistribution', (data) => {

                        });

                        socketIo.emit('GameFinishedPlayersCards', { roomId });
                        socketIo.on('GameFinishedPlayersCards', (data) => {
                            setGameFinishedPlayersCards(data);
                        });

                        socketIo.emit('GameFinished', { roomId });
                        socketIo.on('GameFinished', (data) => {

                            if (data) {
                                setPlayerSidePot({})
                                setIsPanelOpen(false);
                                setIsFooterPanel(false);
                                setGamePlayData({});
                                if (data.winners.length > 0) {
                                    setWinnerSeatIndex(data.winners[0].winnerSeatIndex);
                                    setWinningAmount(data.winners[0].amount);
                                    setWinningType(data.winners[0].winningType);
                                    setTimeout(() => {
                                        setWinnerSeatIndex(-1);
                                        setWinningAmount(-1);
                                        setWinningType('');
                                        setGameStart(false);
                                        setDisplayCard1(false);
                                        setDisplayCard2(false);
                                        setDisplayCard3(false);
                                        setDisplayCard4(false);
                                        sethiddenCards({});
                                        setPlayerCards({})
                                        setGameFinishedPlayersCards({});
                                    }, 2000)
                                }
                            }
                        });

                    }
                });
            }

            return () => {
                window.removeEventListener("resize", resizeWindow);
                window.removeEventListener("resize", updateScreenSize);
                window.removeEventListener('beforeunload', handleTabClose);
            };

        }
    }, [socketIo, aspectRatio, window.innerWidth]);

    useEffect(() => {
        if (socketIo) {

        }
    }, [playerActionData]);
    useEffect(() => {
        if (socketIo) {
            socketIo.emit('PlayerLeft', { roomId });
            socketIo.on('PlayerLeft', (data) => {
                let gamePlayer = [...playerInfo];
                if (gamePlayer.length > 0) {
                    let playerInfoData = gamePlayer.find((playerEle) => {
                        if (playerEle.id === data.playerId) {
                            return playerEle;
                        }
                    });
                    var index = gamePlayer.indexOf(playerInfoData);
                    if (index !== -1) {
                        gamePlayer.splice(index, 1);
                        handlePlayerInfo(gamePlayer)
                    }
                }
            });

            socketIo.emit('OnGameBoot', { roomId });
            socketIo.on('OnGameBoot', (data) => {
                console.log('OnGameBoot')
                console.log(data)

                let gamePlayer = [...playerInfo];
                console.log('gamePlayer')
                console.log(gamePlayer)
                if (gamePlayer.length > 0) {
                    gamePlayer?.map((plr, i) => {
                        if (plr.id != null) {
                            if (plr.id == data.dealerPlayerId) {
                                gamePlayer[i].isDealer = true;
                            } else {
                                gamePlayer[i].isDealer = false;
                            }
                            if (plr.id == data.smallBlindPlayerId) {
                                gamePlayer[i].BetAmount = data.smallBlindChips;
                                gamePlayer[i].BuyInAmount = data.smallBlindPlayerChips;
                            }
                            if (plr.id == data.bigBlindPlayerId) {
                                gamePlayer[i].BetAmount = data.bigBlindChips;
                                gamePlayer[i].BuyInAmount = data.bigBlindPlayerChips;
                            }
                            if (data.straddlePlayerId != null && plr.id == data.straddlePlayerId) {
                                gamePlayer[i].BetAmount = data.straddleChips;
                                gamePlayer[i].BuyInAmount = data.straddlePlayerChips;
                            }

                            data?.bigBlindPlayerList?.map((bigBlindPlr, k) => {
                                if (plr.id == bigBlindPlr.playerId) {
                                    gamePlayer[k].BetAmount = bigBlindPlr.chips;
                                    gamePlayer[k].BuyInAmount = bigBlindPlr.playerChips;
                                }
                            })
                        }
                    });

                    handlePlayerInfo(gamePlayer)
                }
                setTablePot(data.totalTablePotAmount)
                setGameBootData(data);
            });

            socketIo.emit('RevertPointFolded', { roomId });
            socketIo.on('RevertPointFolded', (data) => {
                let gamePlayer = [...playerInfo];
                if (gamePlayer.length > 0) {
                    let playerInfoData = gamePlayer.find((playerEle) => {
                        if (playerEle.id === data.playerId) {
                            return playerEle;
                        }
                    });

                    var index = gamePlayer.indexOf(playerInfoData);
                    if (gamePlayer[index]) {
                        gamePlayer[index].BuyInAmount = data.chips
                    }

                    if (gamePlayer.length > 0) {
                        handlePlayerInfo(gamePlayer)
                    }

                }
                setIsFooterPanel(false);
                setWinnerSeatIndex(data.winnerSeatIndex);
                setWinningAmount(data.amount);
                setTimeout(() => {
                    setWinnerSeatIndex(-1);
                    setWinningAmount(-1);
                    setGameStart(false);
                    setDisplayCard1(false);
                    setDisplayCard2(false);
                    setDisplayCard3(false);
                    setDisplayCard4(false);
                    sethiddenCards({});
                    setPlayerCards({})
                }, 2000)
            });

            socketIo.emit('RoundComplete', { roomId });
            socketIo.on('RoundComplete', (data) => {

                if (data) {
                    setPlayerSidePot(data.PlayerSidePot);
                    if (data.PlayerSidePot.mainPot > 0) {

                        let gamePlayer = [...playerInfo];
                        if (gamePlayer.length > 0) {
                            gamePlayer?.map((pyr, index) => {
                                if (gamePlayer[index].BetAmount > 0) {
                                    // setTimeout(() => {
                                    //     playVoice(7)
                                    // }, 300)
                                }
                                gamePlayer[index].BetAmount = 0
                            });
                            handlePlayerInfo(gamePlayer)
                        }
                    }

                    setTimeout(() => {
                        sethiddenCards(data.cards);
                    }, 1000)
                }
            });

        };
    }, [socketIo, playerInfo]);

    useEffect(() => {
        if (winnerSeatIndex >= 0) {
            playVoice(6)
        }
    }, [winnerSeatIndex]);

    useEffect(() => {
        if (gameStart == true) {
            playVoice(8);
        }
    }, [gameStart]);

    useEffect(() => {
        if (playerActionType != '') {
            if (playerActionType == 6) {
                playVoice(2);
            } else {
                if (playerActionType == 2) {
                    playVoice(4);
                } else {
                    playVoice(1);
                }
            }
        }
    }, [playerActionType]);

    useEffect(() => {
        const data = {
            playerId: playerData.playerId,
            productName: 'BytePoker'
        }
        socket.emit('gamePlayedLastTime', data, (res) => {
        });

        const intervalId = setInterval(() => {
            socket.emit('gamePlayedLastTime', data, (res) => {
            });
            socket.emit('Playerprofile', data, (res) => {
                localStorage.setItem('user', JSON.stringify(res.result));
                setPlayerData(res.result);
                if (res.result.hour_balance == "00:05") {
                    if (isCloseLowBalanceModalOpen == true) {
                        setIsLowBalanceModalOpen(true);
                    }
                }
                if (res.result.hour_balance == "00:00") {
                    if (isCloseInsufficientBalanceModalOpen == true) {
                        setIsInsufficientBalanceModalOpen(true);
                    }
                }

            });
        }, 5000);

        setIntervalId(intervalId);

        return () => clearInterval(intervalId);

    }, [isCloseLowBalanceModalOpen, isCloseInsufficientBalanceModalOpen]);

    const cardAnimation = () => {
        setCardDistribute(true);
        const playerLength = playerInfo.length;
        const plus = 25;
        let timeOut1 = plus;
        let timeOut2 = timeOut1 + plus;
        let timeOut3 = timeOut2 + plus;
        let timeOut4 = timeOut3 + plus;
        let timeOut5 = timeOut4 + plus;
        let timeOut6 = timeOut5 + plus;
        let timeOut7 = timeOut6 + plus;
        let timeOut8 = timeOut7 + plus;
        let timeOut9 = timeOut8 + plus;
        let timeOut10 = timeOut9 + plus;
        let timeOut11 = timeOut10 + timeOut9 + plus + plus;
        let timeOut12 = timeOut11 + timeOut9 + plus;
        let timeOut13 = timeOut12 + timeOut9 + plus + plus;
        let timeOut14 = timeOut13 + timeOut9 + plus;
        let timeOut15 = timeOut14 + timeOut9 + plus + plus;
        let timeOut16 = timeOut15 + timeOut9 + plus;
        const data = {
            'timeOut1': timeOut1,
            'timeOut2': timeOut2,
            'timeOut3': timeOut3,
            'timeOut4': timeOut4,
            'timeOut5': timeOut5,
            'timeOut6': timeOut6,
            'timeOut7': timeOut7,
            'timeOut8': timeOut8,
            'timeOut9': timeOut9,
            'timeOut10': timeOut10,
            'timeOut11': timeOut11,
            'timeOut12': timeOut12,
            'timeOut13': timeOut13,
            'timeOut14': timeOut14,
            'timeOut15': timeOut15,
        }

        cardAnimationShow(data);
        setGameStart(true);

        if (gameType == 'omaha') {
            setTimeout(() => {
                setDisplayCard1(true);
                resetCardAnimationPostion();
            }, (timeOut10))

            setTimeout(() => {
                cardAnimationShow(data);
            }, timeOut11)

            setTimeout(() => {
                setDisplayCard2(true);
                resetCardAnimationPostion();
            }, (timeOut12))

            setTimeout(() => {
                cardAnimationShow(data);
            }, timeOut13)

            setTimeout(() => {
                setDisplayCard3(true);
                resetCardAnimationPostion();
            }, (timeOut14))

            setTimeout(() => {
                cardAnimationShow(data);
            }, timeOut15)

            setTimeout(() => {
                setDisplayCard4(true);
                resetCardAnimationPostion();
            }, (timeOut16))

            setTimeout(() => {
                setCardDistribute(false);
            }, (timeOut16 + 500));
        } else {
            setTimeout(() => {
                setDisplayCard2(true);
                resetCardAnimationPostion();
            }, (timeOut10))

            setTimeout(() => {
                cardAnimationShow(data);
            }, timeOut11)

            setTimeout(() => {
                setDisplayCard3(true);
                resetCardAnimationPostion();
            }, (timeOut12))

            setTimeout(() => {
                setCardDistribute(false);
            }, (timeOut12 + 500));
        }
    }

    const cardAnimationShow = (data) => {
        setAnimationCardDisplay({ display: 'block' })
        let timeOut1 = data.timeOut1;
        let timeOut2 = data.timeOut2;
        let timeOut3 = data.timeOut3;
        let timeOut4 = data.timeOut4;
        let timeOut5 = data.timeOut5;
        let timeOut6 = data.timeOut6;
        let timeOut7 = data.timeOut7;
        let timeOut8 = data.timeOut8;
        let timeOut9 = data.timeOut9;

        if (isMobile == true) {
            setTimeout(() => {
                setCardAnimation1({
                    right: window.innerWidth / 2.2,
                    top: window.innerHeight / 1.400
                });
            }, timeOut1)
            setTimeout(() => {
                setCardAnimation2({
                    right: window.innerWidth / 1.340,
                    top: window.innerHeight / 1.550
                });
            }, timeOut2)
            setTimeout(() => {
                setCardAnimation3({
                    right: window.innerWidth / 1.220,
                    top: window.innerHeight / 2
                });
            }, timeOut3)
            setTimeout(() => {
                setCardAnimation4({
                    right: window.innerWidth / 1.280,
                    top: window.innerHeight / 3
                });
            }, timeOut4)
            setTimeout(() => {
                setCardAnimation5({
                    right: window.innerWidth / 1.560,
                    top: window.innerHeight / 6
                });
            }, timeOut5)
            setTimeout(() => {
                setCardAnimation6({
                    left: window.innerWidth / 1.560,
                    top: window.innerHeight / 6
                });
            }, timeOut6)
            setTimeout(() => {
                setCardAnimation8({
                    left: window.innerWidth / 1.220,
                    top: window.innerHeight / 2
                });
            }, timeOut7)
            setTimeout(() => {
                setCardAnimation8({
                    left: window.innerWidth / 1.260,
                    top: window.innerHeight / 2.550
                });
            }, timeOut8)
            setTimeout(() => {
                setCardAnimation9({
                    left: window.innerWidth / 1.260,
                    top: window.innerHeight / 1.550
                });
            }, timeOut9);
        } else {
            setTimeout(() => {
                setCardAnimation1({
                    right: window.innerWidth / 2.03,
                    top: window.innerWidth / 3.3
                });
            }, timeOut1)
            setTimeout(() => {
                setCardAnimation2({
                    right: window.innerWidth / 1.565,
                    top: window.innerWidth / 3.4
                });
            }, timeOut2)
            setTimeout(() => {
                setCardAnimation3({
                    right: window.innerWidth / 1.375,
                    top: window.innerWidth / 4.8
                });
            }, timeOut3)
            setTimeout(() => {
                setCardAnimation4({
                    right: window.innerWidth / 1.405,
                    top: window.innerWidth / 12
                });
            }, timeOut4)
            setTimeout(() => {
                setCardAnimation5({
                    right: window.innerWidth / 1.760,
                    top: window.innerWidth / 35
                });
            }, timeOut5)
            setTimeout(() => {
                setCardAnimation6({
                    left: window.innerWidth / 1.760,
                    top: window.innerWidth / 35
                });
            }, timeOut6)
            setTimeout(() => {
                setCardAnimation7({
                    left: window.innerWidth / 1.405,
                    top: window.innerWidth / 12
                });
            }, timeOut7)
            setTimeout(() => {
                setCardAnimation8({
                    left: window.innerWidth / 1.375,
                    top: window.innerWidth / 4.8
                });
            }, timeOut8)
            setTimeout(() => {
                setCardAnimation9({
                    left: window.innerWidth / 1.565,
                    top: window.innerWidth / 3.4
                });
            }, timeOut9);
        }
    }

    const resetCardAnimationPostion = () => {
        setAnimationCardDisplay({ display: 'none' })

        if (isMobile == true) {
            setCardAnimation1({
                top: window.innerHeight / 2.9,
                left: '46%'
            });
            setCardAnimation2({
                top: window.innerHeight / 2.9,
                left: '46%'
            });
            setCardAnimation3({
                top: window.innerHeight / 2.9,
                left: '46%'
            });
            setCardAnimation4({
                top: window.innerHeight / 2.9,
                left: '46%'
            });
            setCardAnimation5({
                top: window.innerHeight / 2.9,
                left: '46%'
            });
            setCardAnimation6({
                top: window.innerHeight / 2.9,
                left: '46%'
            });
            setCardAnimation7({
                top: window.innerHeight / 2.9,
                left: '46%'
            });
            setCardAnimation8({
                top: window.innerHeight / 2.9,
                left: '46%'
            });
            setCardAnimation9({
                top: window.innerHeight / 2.9,
                left: '46%'
            });
        } else {
            setCardAnimation1({
                top: window.innerWidth / 5.8,
                right: window.innerWidth / 2.05
            });
            setCardAnimation2({
                top: window.innerWidth / 5.8,
                right: window.innerWidth / 2.05
            });
            setCardAnimation3({
                top: window.innerWidth / 5.8,
                right: window.innerWidth / 2.05
            });
            setCardAnimation4({
                top: window.innerWidth / 5.8,
                right: window.innerWidth / 2.05
            });
            setCardAnimation5({
                top: window.innerWidth / 5.8,
                right: window.innerWidth / 2.05
            });
            setCardAnimation6({
                top: window.innerWidth / 5.8,
                left: window.innerWidth / 2.05
            });
            setCardAnimation7({
                top: window.innerWidth / 5.8,
                left: window.innerWidth / 2.05
            });
            setCardAnimation8({
                top: window.innerWidth / 5.8,
                left: window.innerWidth / 2.05
            });
            setCardAnimation9({
                top: window.innerWidth / 5.8,
                left: window.innerWidth / 2.05
            });
        }
    }

    const handlePlayerInfo = (playerInfoArr) => {
        if (playerInfoArr.length > 0) {
            let playerDataInfo = playerInfoArr.find((playerEle) => {
                return playerEle.id === playerData.playerId;
            });

            if (playerDataInfo && playerDataInfo.seatIndex) {
                setSeatPositionCss(playerDataInfo.seatIndex)
            }

            playerInfoArr.map((player, index) => {
                if (playerInfoArr[index].BuyInAmount) {
                    playerInfoArr[index].BuyInAmount = playerInfoArr[index].BuyInAmount
                } else {
                    if (player.waitingGameChips > 0) {
                        playerInfoArr[index].BuyInAmount = player.waitingGameChips
                    } else {
                        playerInfoArr[index].BuyInAmount = player.chips
                    }
                    if (player.id == playerData.playerId) {
                        setEmptyTable(false);
                    }
                }
                if (playerInfoArr[index].BetAmount) {
                    playerInfoArr[index].BetAmount = playerInfoArr[index].BetAmount
                } else {
                    playerInfoArr[index].BetAmount = player.betAmount
                }
            });
            console.log(playerInfoArr);
            setPlayerInfo(playerInfoArr);
        }
    }



    const getActionName = (action) => {
        //  console.log("getActionName action")
        //  console.log(action)
        if (action == 0) {
            return 'SmallBlind';
        } else if (action == 1) {
            return 'BigBlind';
        } else if (action == 2) {
            return 'Check';
        } else if (action == 3) {
            return 'Raise';
        } else if (action == 4) {
            return 'Call';
        } else if (action == 6) {
            return 'Fold';
        } else if (action == 7) {
            return 'Timeout';
        } else if (action == 8) {
            return 'Allin';
        }
        return ''
    }
    const playVoice = (type) => {
        if (voice) {
            if (type == 1) {
                chipsVoice();
            }
            if (type == 2) {
                foldCardVoice();
            }
            if (type == 3) {
                cardDealtVoice();
            }
            if (type == 4) {
                checkActionVoice();
            }
            if (type == 5) {
                myTurnVoice();
            }
            if (type == 6) {
                winnerVoice();
            }
            if (type == 7) {
                collectVoice();
            }
            if (type == 8) {
                cardShuffleVoice();
            }
        }
    }
    const getCard = (position) => {
        if (position == 0) {
            return '../Images/Icons/BackCard.png'
        }
        if (playerCards && playerCards.cards && playerCards.cards.length > 0) {
            const Card1 = playerCards.cards[0];
            const Card2 = playerCards.cards[1];
            const Card3 = playerCards.cards[2];
            const Card4 = playerCards.cards[3];
            if (position == 1) {
                return '../Images/Card/' + Card1 + '.png'
            }
            if (position == 2) {
                return '../Images/Card/' + Card2 + '.png'
            }
            if (position == 3) {
                return '../Images/Card/' + Card3 + '.png'
            }
            if (position == 4) {
                return '../Images/Card/' + Card4 + '.png'
            }
        } else {
            return '../Images/Icons/BackCard.png'
        }
    }

    const sitOutCheckbox = (e, type) => {
        let data = {
            nextHand: e.target.checked,
            bigBlind: false
        }
        if (type == 1) {
            data = {
                nextHand: false,
                bigBlind: e.target.checked
            }
        }
        setSitOut(data)
        const SitOutNextHandData = {
            playerId: playerData.playerId,
            roomId: roomId,
            actionValue: data.nextHand,
            latitude: 0,
            longitude: 0,
            productName: 'BytePoker'
        }
        const SitOutNextBigBlindData = {
            playerId: playerData.playerId,
            roomId: roomId,
            actionValue: data.bigBlind,
            latitude: 0,
            longitude: 0,
            productName: 'BytePoker'
        }
        socketIo.emit('SitOutNextBigBlind', SitOutNextBigBlindData, (res) => { });
        socketIo.emit('SitOutNextHand', SitOutNextHandData, (res) => { });
    }
    const defaultActionSelection = (e, type) => {
        let option = ''
        if (type == 0) {
            if (e.target.checked == true) {
                option = 'isFold'
            } else {
                option = 'removeIsFold'
            }
        }
        if (type == 1) {
            if (e.target.checked == true) {
                option = 'isCheck'
            } else {
                option = 'removeIsCheck'
            }
        }
        if (type == 2) {
            if (e.target.checked == true) {
                option = 'isCall'
            } else {
                option = 'removeIsCall'
            }
        }
        setDefaultAction(option)
        const data = {
            playerId: playerData.playerId,
            roomId: roomId,
            option: option,
            latitude: 0,
            longitude: 0,
            productName: 'BytePoker'
        }
        socketIo.emit('DefaultActionSelection', data, (res) => { });
    }

    const leaveGame = () => {
        const data = {
            playerId: playerData.playerId,
            roomId: roomId,
            latitude: 0,
            longitude: 0,
            productName: 'BytePoker'
        }
        socketIo.emit('LeaveRoom', data, (res) => {
            toast.success("Leaving the Game...", {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            navigate('/lobby');
        })
    }

    const goBack = () => {
        navigate('/lobby');
    }
    const handleTabClosing = (event) => {
        const data = {
            playerId: playerData.playerId,
            roomId: roomId,
            latitude: 0,
            longitude: 0,
            productName: 'BytePoker'
        }

        socketIo.emit('LeaveRoom', data, (res) => { });
    }
    const playerProfilePhoto = (img) => {
        return '../Images/Avtar/' + (img + 1) + '.png'
    }
    const selectBuyIn = (seat) => {
        const mainSocket = io(URL);
        const data = {
            playerId: playerData.playerId,
            roomId: roomId,
            latitude: 0,
            longitude: 0,
            productName: 'BytePoker'
        }
        //  console.log(data)
        mainSocket.emit('GetBuyinsAndPlayerchips', data, (res) => {
            if (res.result) {
                setTableData(res.result);
                setMinBuyIn(res.result.minBuyIn)
                if (res.result.maxBuyIn > 0) {
                    setMaxBuyIn(res.result.maxBuyIn)
                } else {
                    setMaxBuyIn(playerData.chips)
                }
                setStart(res.result.minBuyIn);
                setIsRoomModalOpen(true);
                setSeatPosition(seat);
            }
        });
    }

    const closeModal = () => {
        setIsRoomModalOpen(false);
        setIsLogoutModalOpen(false);
        setIsInfoModalOpen(false);
        setIsBuyHourModalOpen(false);
    }
    const openHourBuyModal = (type) => {
        if (type == 1) {
            setIsLowBalanceModalOpen(false);
            setIsCloseLowBalanceModalOpen(false);
            setIsBuyHourModalOpen(true);
        }
        if (type == 2) {
            setIsInsufficientBalanceModalOpen(false)
            setIsCloseInsufficientBalanceModalOpen(false);
            setIsBuyHourModalOpen(true)
        }
    }

    const closeHourWarningModal = (type) => {
        if (type == 1) {
            setIsLowBalanceModalOpen(false);
            setIsCloseLowBalanceModalOpen(false);
        }
        if (type == 2) {
            setIsInsufficientBalanceModalOpen(false)
            setIsCloseInsufficientBalanceModalOpen(false);
        }
    }

    const buyPackage = (type) => {
        if (selectedHour != 0) {
            if (type == 0) {
                const data = {
                    playerId: playerData.playerId,
                    selectedHour: selectedHour
                }
                socket.emit('buyHourlyPackage', data, (res) => {
                    if (res.status == 'success') {
                        toast.success(res.message, {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                        setTimeout(() => {
                            closeModal();
                        }, 1000)
                    } else {
                        toast.error(res.message, {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                    }
                });
            } else {
                closeModal();
            }
        } else {
            closeModal();
        }
    }

    const PlayerAction = (action, hasRaised, amount) => {
        if (action == 6) {
            let playerCardsOld = playerCards;
            playerCardsOld.flipAnimation = false
            setPlayerCards(playerCardsOld)
            // playVoice(2)
        } else {
            // if (action == 2) {
            //     playVoice(4)
            // } else {
            //     playVoice(1);
            // }
        }
        const data = {
            playerId: playerData.playerId,
            betAmount: amount,
            action: action,
            roomId: roomId,
            hasRaised: hasRaised,
            productName: 'BytePoker'
        }

        socketIo.emit('PlayerAction', data, (res) => {
            setIsPanelOpen(false);
        });


        socketIo.on('PlayerAction', (data) => {
            if (data.action.playerId == playerData.playerId) {
                setIsPanelOpen(false);
                if (data.action.action == 6) {
                    let playerCardsOld = playerCards;
                    playerCardsOld.flipAnimation = false
                    setPlayerCards(playerCardsOld)
                }
            }
            console.log("data.action.action");
            console.log(data.action.action);
            setPlayerActionType(data.action.action)

            setDefaultAction('');
            setPlayerActionData(data.action);
            setTimeout(() => {
                setPlayerActionData({});
            }, 2000);
            let gamePlayer = [...playerInfo];
            if (gamePlayer.length > 0) {
                let playerInfoData = gamePlayer.find((playerEle) => {
                    if (playerEle.id === data.action.playerId) {
                        return playerEle;
                    }
                });

                var index = gamePlayer.indexOf(playerInfoData);
                if (gamePlayer[index]) {
                    gamePlayer[index].BuyInAmount = data.playerBuyIn
                    gamePlayer[index].BetAmount = data.action.totalBetAmount
                }
            }
            setTablePot(data.totalTablePotAmount)
        });

    }

    const ReconnectGame = () => {
        const data = {
            playerId: playerData.playerId,
            roomId: roomId,
            latitude: 0,
            longitude: 0,
            productName: 'BytePoker'
        }
        socketIo.emit('ReconnectGame', data, (res) => {

        });
    }

    const sitOnTable = (type) => {
        const data = {
            playerId: playerData.playerId,
            roomId: roomId,
            chips: start,
            seatIndex: seatPosition,
            latitude: 0,
            longitude: 0,
            productName: 'BytePoker'
        }
        if (type == 0) {
            socketIo.emit('JoinRoom', data, (res) => {
                if (res.result) {
                    setIsRoomModalOpen(false);
                    setEmptyTable(false);
                    setGameData(res.result);
                }
            });
        }
        if (type == 1) {
            socketIo.emit('PlayerAddChips', data, (res) => {
                if (res.result) {
                    setIsRoomModalOpen(false);
                    setBuyIn(false);
                }
            });
        }
    }

    const getReBuyInChips = () => {
        const data = {
            playerId: playerData.playerId,
            roomId: roomId,
            latitude: 0,
            longitude: 0,
            productName: 'BytePoker'
        }
        socketIo.emit('GetPlayerReBuyInChips', data, (res) => {
            if (res.result) {
                setMinBuyIn(res.result.minBuyIn)
                if (res.result.maxBuyIn > 0) {
                    setMaxBuyIn(res.result.maxBuyIn)
                } else {
                    setMaxBuyIn(playerData.chips)
                }
                setStart(res.result.minBuyIn);
                setIsRoomModalOpen(true);
                setTableData(res.result);
                setBuyIn(true);
            }
        });
    }

    const sendPlayerOnline = () => {
        const data = {
            playerId: playerData.playerId,
            roomId: roomId,
            latitude: 0,
            longitude: 0,
            productName: 'BytePoker'
        }
        socketIo.emit('PlayerOnline', data, (res) => {
            //  console.log(res);
        });
    }

    function onStartChange(val) {
        setStart(val);
    }
    const h1class = {
        color: '#fff'
    }
    const checkPlayerSeat = (player, seat) => {
        let seatIndex = playerInfo.find((playerEle) => {
            return playerEle.seatIndex === (seat);
        });

        if (seatIndex != undefined) {
            if (seatIndex.id == player.id) {
                return true;
            }
        }
        return false;
    }

    const checkSeatAvailable = (seat) => {
        let seatIndex = playerInfo.find((playerEle) => {
            return playerEle.seatIndex === (seat);
        });
        if (seatIndex != undefined) {
            return false;
        } else {
            return true;
        }
    }

    const setSeatPositionCss = (seat) => {
        if (seat == 0) {
            setTableSeats([0, 1, 2, 3, 4, 5, 6, 7, 8])
        }
        if (seat == 1) {
            setTableSeats([1, 2, 3, 4, 5, 6, 7, 8, 0])
        }
        if (seat == 2) {
            setTableSeats([2, 3, 4, 5, 6, 7, 8, 0, 1])
        }
        if (seat == 3) {
            setTableSeats([3, 4, 5, 6, 7, 8, 0, 1, 2])
        }
        if (seat == 4) {
            setTableSeats([4, 5, 6, 7, 8, 0, 1, 2, 3])
        }
        if (seat == 5) {
            setTableSeats([5, 6, 7, 8, 0, 1, 2, 3, 4])
        }
        if (seat == 6) {
            setTableSeats([6, 7, 8, 0, 1, 2, 3, 4, 5])
        }
        if (seat == 7) {
            setTableSeats([7, 8, 0, 1, 2, 3, 4, 5, 6])
        }
        if (seat == 8) {
            setTableSeats([8, 0, 1, 2, 3, 4, 5, 6, 7])
        }
    }
    const getCss = (seat) => {
        let seatIndex = tableSeats.indexOf(seat);
        const finalIndex = (seatIndex);
        if (finalIndex == 0) { return emptySeat1; }
        if (finalIndex == 1) { return emptySeat2; }
        if (finalIndex == 2) { return emptySeat3; }
        if (finalIndex == 3) { return emptySeat4; }
        if (finalIndex == 4) { return emptySeat5; }
        if (finalIndex == 5) { return emptySeat6; }
        if (finalIndex == 6) { return emptySeat7; }
        if (finalIndex == 7) { return emptySeat8; }
        if (finalIndex == 8) { return emptySeat9; }
    }
    const getFillCss = (seat) => {
        let seatIndex = tableSeats.indexOf(seat);
        const finalIndex = (seatIndex);
        if (finalIndex == 0) { return filledSeat1; }
        if (finalIndex == 1) { return filledSeat2; }
        if (finalIndex == 2) { return filledSeat3; }
        if (finalIndex == 3) { return filledSeat4; }
        if (finalIndex == 4) { return filledSeat5; }
        if (finalIndex == 5) { return filledSeat6; }
        if (finalIndex == 6) { return filledSeat7; }
        if (finalIndex == 7) { return filledSeat8; }
        if (finalIndex == 8) { return filledSeat9; }
    }

    const getCardAnimationCss = (seat) => {
        let seatIndex = tableSeats.indexOf(seat);
        const finalIndex = (seatIndex);
        if (finalIndex == 0) { return cardAnimation1; }
        if (finalIndex == 1) { return cardAnimation2; }
        if (finalIndex == 2) { return cardAnimation3; }
        if (finalIndex == 3) { return cardAnimation4; }
        if (finalIndex == 4) { return cardAnimation5; }
        if (finalIndex == 5) { return cardAnimation6; }
        if (finalIndex == 6) { return cardAnimation7; }
        if (finalIndex == 7) { return cardAnimation8; }
        if (finalIndex == 8) { return cardAnimation9; }
    }


    const getChipsCss = (seat) => {
        let seatIndex = tableSeats.indexOf(seat);
        const finalIndex = (seatIndex);
        if (finalIndex == 0) { return playerChips1; }
        if (finalIndex == 1) { return playerChips2; }
        if (finalIndex == 2) { return playerChips3; }
        if (finalIndex == 3) { return playerChips4; }
        if (finalIndex == 4) { return playerChips5; }
        if (finalIndex == 5) { return playerChips6; }
        if (finalIndex == 6) { return playerChips7; }
        if (finalIndex == 7) { return playerChips8; }
        if (finalIndex == 8) { return playerChips9; }
    }

    const CardClassName = (className) => {
        setTimeout(() => {
            document.querySelector('.' + className).classList.add('flipped');
        }, 100)
    }
    const cardFlipice = (time) => {
        if (time == 0) {
            setTimeout(() => {
                playVoice(3)
            }, 500)
        }
        if (time == 1) {
            setTimeout(() => {
                playVoice(3)
            }, 1000)
        }
        if (time == 2) {
            setTimeout(() => {
                playVoice(3)
            }, 1500)
        }
        if (time == 3) {
            setTimeout(() => {
                playVoice(3)
            }, 500)
        }
        if (time == 4) {
            setTimeout(() => {
                playVoice(3)
            }, 500)
        }
    }
    const hidddenCardClassName = (className) => {
        if (className == 'hiddenCard1') {
            setTimeout(() => {
                document.querySelector('.' + className).classList.add('flipped');
            }, 500)
        }
        if (className == 'hiddenCard2') {
            setTimeout(() => {
                document.querySelector('.' + className).classList.add('flipped');
            }, 1000)
        }
        if (className == 'hiddenCard3') {
            setTimeout(() => {
                document.querySelector('.' + className).classList.add('flipped');
            }, 1500)
        }
        if (className == 'hiddenCard4') {
            setTimeout(() => {
                document.querySelector('.' + className).classList.add('flipped');
            }, 500)
        }
        if (className == 'hiddenCard5') {
            setTimeout(() => {
                document.querySelector('.' + className).classList.add('flipped');
            }, 500)
        }
    }

    const raiseAmountChange = (value) => {
        setRaiseAmount(value)
    }


    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#242234',
            width: '40%',
            height: '50%',
            border: 'none'
        },
    };

    const customStylesLogout = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#242234',
            width: '40%',
            height: '50%',
            border: 'none'
        },
    };

    const customStylesMobile = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#242234',
            width: '75%',
            height: '40%',
            border: 'none'
        },
    };

    const customStylesLogoutMobile = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#242234',
            width: '75%',
            height: '40%',
            border: 'none'
        },
    };

    const muteVolume = () => {
        setVoice(false);
    }


    const openSideBar = () => {
        console.log("click");
        setSidebarWidth({ width: '20.5%' });
        setSidebarMobileWidth({ width: '100%' });
    }

    const closeSideBar = () => {
        setSidebarWidth({ width: '0%' });
        setSidebarMobileWidth({ width: '0%' });
    }

    const sendMessage = () => {
        const messageData = {
            playerId: playerData.playerId,
            username: playerData.username,
            roomId: roomId,
            message: userMessage,
            productName: 'BytePoker'
        }
        socketIo.emit('Chat', messageData, (res) => {
            if (res.status) {
                setUserMessage('');
                setOpenEmojiBox(false);
            }
        });
    }

    const selectEmoji = (e) => {
        const oldUserMessage = userMessage + e.native;
        setUserMessage(oldUserMessage);
    }
    return (
        <div>
            <ToastContainer />
            {isMobile ?
                <div>
                    <div className='mobileView fixed-screen'>
                        <div className='game-page'>
                            <div className='gameTable'>
                                <div className='gameTableHeader'>
                                    <div className='leftPanel'>
                                        <button type='button' className='plainBtn headerBtn' data-title="Leave Game"><img alt="poker" src={'../Images/Icons/NewBackIcon.png'} onClick={() => setIsLogoutModalOpen(true)} />
                                            {/* <span className='muteTitle'>Table Info</span> */}
                                        </button>
                                        {/* <div className='backIcon'>
                                            <button type='button' className='plainBtn' data-title="Leave Game"><img alt="poker" src={'../Images/Icons/NewBackIcon.png'} onClick={() => setIsLogoutModalOpen(true)} /></button>
                                        </div> */}
                                    </div>
                                    <div className='rightPanel'>
                                        <button type='button' className='plainBtn headerBtn' data-title='Buy Hours'><img alt="poker" src={'../Images/Icons/PlusBox.png'} onClick={() => openHourBuyModal(1)} />
                                            {/* <span className='muteTitle'>Table Info</span> */}
                                        </button>
                                        <div className='playerLeftHours'>
                                            {/* <button type='button' className='plainBtn' data-title='Buy Hours' ><img alt="poker" src={'../Images/Icons/PlusBox.png'} /></button> */}
                                            <div className='d-flex align-item-center'>
                                                <img alt="poker" className="timeIcon" src={'../Images/Icons/Timer.png'} />
                                                <span>{playerData ? playerData.hour_balance : '00.00'}</span>
                                            </div>
                                        </div>

                                        {/* 
                                        <div className='playerLeftHours'>
                                            <button type='button' className='plainBtn buyHourBtn' data-title='Buy Hours' onClick={() => openHourBuyModal(1)}><img alt="poker" src={'../Images/Icons/PlusBox.png'} /></button>
                                            <div className='d-flex align-item-center'>
                                                <img alt="poker" className="timeIcon" src={'../Images/Icons/Watch.png'} />
                                                <span>{playerData ? playerData.hour_balance : '00.00'}</span>
                                            </div>
                                        </div> */}

                                        {roomType != socketConfig.CashRegularTexas ?
                                            <button type='button' className='plainBtn headerBtn' data-title='Add money to table' onClick={() => getReBuyInChips()}>
                                                <img alt="poker" src={'../Images/Icons/BuyChips.png'} />
                                                {/* <span className='muteTitle'>Buy Chips</span> */}
                                            </button>
                                            : ''}
                                        <button type='button' className='plainBtn headerBtn' data-title='Info'><img alt="poker" src={'../Images/Icons/InfoIcon.png'} />
                                            {/* <span className='muteTitle'>Table Info</span> */}
                                        </button>
                                        <button type='button' className='plainBtn headerBtn' data-title='Settings'><img alt="poker" src={'../Images/Icons/Setting.png'} />
                                            {/* <span className='muteTitle'>Table Info</span> */}
                                        </button>
                                        <button type='button' className='plainBtn headerBtn' data-title='FullScreen'><img alt="poker" src={'../Images/Icons/FullScreen.png'} />
                                            {/* <span className='muteTitle'>Table Info</span> */}
                                        </button>

                                    </div>
                                </div>

                                <div className='tableDetails'>
                                    <p className='color-1'>{roomData.room?.name} - <span style={{ textTransform: "capitalize" }}>{roomData.room?.tableType} ,</span> <span className='color-2'>Stakes : {roomData.room?.smallBlind}/{roomData.room?.bigBlind}</span></p>

                                </div>
                                <img alt="poker" className='poker-table-box' src={MbGameTable} style={tableImage}></img>
                                {cardDistribute ? <div className='hiddenCard hiddenCardShuffle' style={hiddenCard3Css} >
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front">
                                            <img alt="poker" src={'../Images/Icons/BackCard.png'}></img>
                                        </div>
                                    </div>
                                </div> : ''}
                                {/* 
                                <div className='tablePot' style={tablePortBlock}>Pot : 100.00</div>
                                <div className='winnerType'>Pair</div>

                                <div className='mainhiddenCard hiddenCard slide-in hiddenCard1' onLoad={() => hidddenCardClassName('hiddenCard1')} style={hiddenCard1Css} >
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front">
                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                        </div>
                                        <div className="flip-card-back" onLoad={() => cardFlipice(0)}>
                                            <img alt="poker" src={'../Images/Card/AH.png'}></img>
                                        </div>
                                    </div>
                                </div>

                                <div className='mainhiddenCard hiddenCard slide-in hiddenCard2' onLoad={() => hidddenCardClassName('hiddenCard2')} style={hiddenCard2Css} >
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front">
                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                        </div>
                                        <div className="flip-card-back" onLoad={() => cardFlipice(1)}>
                                            <img alt="poker" src={'../Images/Card/AH.png'}></img>
                                        </div>
                                    </div>
                                </div>

                                <div className='mainhiddenCard hiddenCard slide-in hiddenCard3' onLoad={() => hidddenCardClassName('hiddenCard3')} style={hiddenCard3Css} >
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front">
                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                        </div>
                                        <div className="flip-card-back" onLoad={() => cardFlipice(2)}>
                                            <img alt="poker" src={'../Images/Card/AH.png'}></img>
                                        </div>
                                    </div>
                                </div>


                                <div className='mainhiddenCard hiddenCard slide-in hiddenCard4' onLoad={() => hidddenCardClassName('hiddenCard4')} style={hiddenCard4Css} >
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front">
                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                        </div>
                                        <div className="flip-card-back" onLoad={() => cardFlipice(3)}>
                                            <img alt="poker" src={'../Images/Card/AH.png'}></img>
                                        </div>
                                    </div>
                                </div>


                                <div className='mainhiddenCard hiddenCard slide-in hiddenCard5' onLoad={() => hidddenCardClassName('hiddenCard5')} style={hiddenCard5Css} >
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front">
                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                        </div>
                                        <div className="flip-card-back" onLoad={() => cardFlipice(4)}>
                                            <img alt="poker" src={'../Images/Card/AH.png'}></img>
                                        </div>
                                    </div>
                                </div>


                                <div className="collectChipsImage" style={playerChips0}>
                                    <img alt="poker" src={'../Images/Icons/ChipsIcon.png'} ></img>
                                    <p>100.00</p>
                                </div> */}

                                {gameStart == true ? <div className='tablePot' style={tablePortBlock}>Pot : {tablePot.toFixed(2)}</div> : ''}
                                {winningType ? <div className='winnerType'>{winningType}</div> : ''}
                                {hiddenCards.length > 0 ?
                                    <div className='mainhiddenCard hiddenCard slide-in hiddenCard1' onLoad={() => hidddenCardClassName('hiddenCard1')} style={hiddenCard1Css} >
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front">
                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                            </div>
                                            <div className="flip-card-back" onLoad={() => cardFlipice(0)}>
                                                <img alt="poker" src={'../Images/Card/' + hiddenCards[0] + '.png'}></img>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                                {hiddenCards.length > 1 ?
                                    <div className='mainhiddenCard hiddenCard slide-in hiddenCard2' onLoad={() => hidddenCardClassName('hiddenCard2')} style={hiddenCard2Css} >
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front">
                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                            </div>
                                            <div className="flip-card-back" onLoad={() => cardFlipice(1)}>
                                                <img alt="poker" src={'../Images/Card/' + hiddenCards[1] + '.png'}></img>
                                            </div>
                                        </div>
                                    </div> : ''}
                                {hiddenCards.length > 2 ?
                                    <div className='mainhiddenCard hiddenCard slide-in hiddenCard3' onLoad={() => hidddenCardClassName('hiddenCard3')} style={hiddenCard3Css} >
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front">
                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                            </div>
                                            <div className="flip-card-back" onLoad={() => cardFlipice(2)}>
                                                <img alt="poker" src={'../Images/Card/' + hiddenCards[2] + '.png'}></img>
                                            </div>
                                        </div>
                                    </div> : ''}

                                {hiddenCards.length > 3 ?
                                    <div className='mainhiddenCard hiddenCard slide-in hiddenCard4' onLoad={() => hidddenCardClassName('hiddenCard4')} style={hiddenCard4Css} >
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front">
                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                            </div>
                                            <div className="flip-card-back" onLoad={() => cardFlipice(3)}>
                                                <img alt="poker" src={'../Images/Card/' + hiddenCards[3] + '.png'}></img>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                                {hiddenCards.length > 4 ?
                                    <div className='mainhiddenCard hiddenCard slide-in hiddenCard5' onLoad={() => hidddenCardClassName('hiddenCard5')} style={hiddenCard5Css} >
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front">
                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                            </div>
                                            <div className="flip-card-back" onLoad={() => cardFlipice(4)}>
                                                <img alt="poker" src={'../Images/Card/' + hiddenCards[4] + '.png'}></img>
                                            </div>
                                        </div>
                                    </div> : ''}

                                {playerSidePot.mainPot > 0 ?
                                    <div className="collectChipsImage" style={playerChips0}>
                                        <img alt="poker" src={'../Images/Icons/ChipsIcon.png'} ></img>
                                        <p>{playerSidePot.mainPot.toFixed(2)}</p>
                                    </div>
                                    : ''}
                                {/* <div style={animationCardDisplay}>
                                    <div className='animation-card-image' style={getCardAnimationCss(0)}>
                                        <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                    </div>
                                </div>
                                <div className="chipsImage" style={getChipsCss(0)}>
                                    <img alt="poker" src={'../Images/Icons/TableChips.png'} ></img>
                                    <p>10.00</p>
                                </div>
                                <div className="fill-seat-wrapper" style={getFillCss(0)}>
                                    <img alt="poker" className="playerAvatarImg" src='../Images/Avtar/4.png' style={playerProfile}></img>
                                    <p className='playerAction'>Check</p>

                                    <div className='playerDetails countdownContainer' >
                                        <p className='playerName'>Milan Maiya</p>
                                        <p className='playeChips'>100.00</p>
                                    </div>
                                    <div>
                                        <div className='hiddenCard frontFirstCard card-image mycard1' onLoad={() => CardClassName('mycard1')} style={displayCard} >
                                            <div className="flip-card-inner">
                                                <div className="flip-card-front">
                                                    <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                </div>
                                                <div className="flip-card-back">
                                                    <img alt="poker" src={'../Images/Card/5S.png'}></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='hiddenCard frontSecondCard card-image mycard2' onLoad={() => CardClassName('mycard2')} style={displayCard} >
                                            <div className="flip-card-inner">
                                                <div className="flip-card-front">
                                                    <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                </div>
                                                <div className="flip-card-back">
                                                    <img alt="poker" src={'../Images/Card/9H.png'}></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='hiddenCard frontThirdCard card-image mycard3' onLoad={() => CardClassName('mycard3')} style={displayCard} >
                                            <div className="flip-card-inner">
                                                <div className="flip-card-front">
                                                    <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                </div>
                                                <div className="flip-card-back">
                                                    <img alt="poker" src={'../Images/Card/KS.png'}></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='hiddenCard frontFourCard card-image mycard4' onLoad={() => CardClassName('mycard4')} style={displayCard} >
                                            <div className="flip-card-inner">
                                                <div className="flip-card-front">
                                                    <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                </div>
                                                <div className="flip-card-back">
                                                    <img alt="poker" src={'../Images/Card/AH.png'}></img>
                                                </div>
                                            </div>
                                        </div>
                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                    </div>
                                </div>

                                <div style={animationCardDisplay}>
                                    <div className='animation-card-image' style={getCardAnimationCss(1)}>
                                        <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                    </div>
                                </div>
                                <div className="chipsImage" style={getChipsCss(1)}>
                                    <img alt="poker" src={'../Images/Icons/TableChips.png'} ></img>
                                    <p>10.00</p>
                                </div>
                                <div className="fill-seat-wrapper" style={getFillCss(1)}>
                                    <img alt="poker" className="playerAvatarImg" src='../Images/Avtar/5.png' style={playerProfile}></img>
                                    <div className='playerDetails' >
                                        <p className='playerName'>Jack Rao</p>
                                        <p className='playeChips'>100.00</p>
                                    </div>
                                    <div>
                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                    </div>
                                </div>
                                <div style={animationCardDisplay}>
                                    <div className='animation-card-image' style={getCardAnimationCss(2)}>
                                        <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                    </div>
                                </div>
                                <div className="chipsImage" style={getChipsCss(2)}>
                                    <img alt="poker" src={'../Images/Icons/TableChips.png'} ></img>
                                    <p>10.00</p>
                                </div>
                                <div className="fill-seat-wrapper" style={getFillCss(2)}>
                                    <img alt="poker" className="playerAvatarImg" src='../Images/Avtar/3.png' style={playerProfile}></img>
                                    <div className='playerDetails' >
                                        <p className='playerName'>Paul Zerdin</p>
                                        <p className='playeChips'>100.00</p>
                                    </div>
                                    <div>
                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                    </div>
                                </div>
                                <div style={animationCardDisplay}>
                                    <div className='animation-card-image' style={getCardAnimationCss(3)}>
                                        <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                    </div>
                                </div>
                                <div className="chipsImage" style={getChipsCss(3)}>
                                    <img alt="poker" src={'../Images/Icons/TableChips.png'} ></img>
                                    <p>10.00</p>
                                </div>
                                <div className="fill-seat-wrapper" style={getFillCss(3)}>
                                    <img alt="poker" className="playerAvatarImg" src='../Images/Avtar/1.png' style={playerProfile}></img>
                                    <div className='playerDetails' >
                                        <p className='playerName'>Megha Bambhaniya</p>
                                        <p className='playeChips'>100.00</p>
                                    </div>
                                    <div>
                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                    </div>
                                </div>
                                <div style={animationCardDisplay}>
                                    <div className='animation-card-image' style={getCardAnimationCss(4)}>
                                        <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                    </div>
                                </div>
                                <div className="chipsImage" style={getChipsCss(4)}>
                                    <img alt="poker" src={'../Images/Icons/TableChips.png'} ></img>
                                    <p>10.00</p>
                                </div>
                                <div className="fill-seat-wrapper" style={getFillCss(4)}>
                                    <img alt="poker" className="playerAvatarImg" src='../Images/Avtar/1.png' style={playerProfile}></img>
                                    <div className='playerDetails' >
                                        <p className='playerName'>Megha Bambhaniya</p>
                                        <p className='playeChips'>100.00</p>
                                    </div>
                                    <div>
                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                    </div>
                                </div>
                                <div style={animationCardDisplay}>
                                    <div className='animation-card-image' style={getCardAnimationCss(5)}>
                                        <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                    </div>
                                </div>
                                <div className="chipsImage" style={getChipsCss(5)}>
                                    <img alt="poker" src={'../Images/Icons/TableChips.png'} ></img>
                                    <p>10.00</p>
                                </div>
                                <div className="fill-seat-wrapper" style={getFillCss(5)}>
                                    <img alt="poker" className="playerAvatarImg" src='../Images/Avtar/3.png' style={playerProfile}></img>
                                    <div className='playerDetails' >
                                        <p className='playerName'>Paul Zerdin</p>
                                        <p className='playeChips'>100.00</p>
                                    </div>
                                    <div>
                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                    </div>
                                </div>
                                <div style={animationCardDisplay}>
                                    <div className='animation-card-image' style={getCardAnimationCss(6)}>
                                        <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                    </div>
                                </div>
                                <div className="chipsImage" style={getChipsCss(6)}>
                                    <img alt="poker" src={'../Images/Icons/TableChips.png'} ></img>
                                    <p>10.00</p>
                                </div>
                                <div className="fill-seat-wrapper" style={getFillCss(6)}>
                                    <img alt="poker" className="playerAvatarImg" src='../Images/Avtar/3.png' style={playerProfile}></img>
                                    <div className='playerDetails' >
                                        <p className='playerName'>Mona Yom</p>
                                        <p className='playeChips'>120.00</p>
                                    </div>
                                    <div>
                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                    </div>
                                </div>
                                <div style={animationCardDisplay}>
                                    <div className='animation-card-image' style={getCardAnimationCss(7)}>
                                        <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                    </div>
                                </div>
                                <div className="chipsImage" style={getChipsCss(7)}>
                                    <img alt="poker" src={'../Images/Icons/TableChips.png'} ></img>
                                    <p>10.00</p>
                                </div>
                                <div className="fill-seat-wrapper" style={getFillCss(7)}>
                                    <img alt="poker" className="playerAvatarImg" src='../Images/Avtar/3.png' style={playerProfile}></img>
                                    <div className='playerDetails' >
                                        <p className='playerName'>John Deo</p>
                                        <p className='playeChips'>500.00</p>
                                    </div>
                                    <div>
                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                    </div>
                                </div>
                                <div style={animationCardDisplay}>
                                    <div className='animation-card-image' style={getCardAnimationCss(8)}>
                                        <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                    </div>
                                </div>
                                <div className="chipsImage" style={getChipsCss(8)}>
                                    <img alt="poker" src={'../Images/Icons/TableChips.png'} ></img>
                                    <p>10.00</p>
                                </div>
                                <div className="fill-seat-wrapper" style={getFillCss(8)}>
                                    <img alt="poker" className="playerAvatarImg" src='../Images/Avtar/3.png' style={playerProfile}></img>
                                    <div className='playerDetails' >
                                        <p className='playerName'>Badshah</p>
                                        <p className='playeChips'>100.00</p>
                                    </div>
                                    <div>
                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                    </div>
                                </div> */}

                                {emptyTable ?
                                    <div >
                                        {tableSeats?.map((seat, key) => {
                                            if (playerInfo.length > 0) {
                                                return (
                                                    <div key={key} className='emptyTable'>
                                                        {playerInfo?.map((player, index) => {
                                                            if (checkPlayerSeat(player, seat)) {
                                                                return (
                                                                    <div key={index}>
                                                                        {gameStart == true && player.BetAmount > 0 ?
                                                                            <div className="chipsImage" style={getChipsCss(seat)}>
                                                                                <img alt="poker" src={'../Images/Icons/TableChips.png'} ></img>
                                                                                <p>{winningAmount.toFixed(2)}</p>
                                                                            </div>
                                                                            : ''}
                                                                        {winnerSeatIndex == seat && winningAmount > 0 ?
                                                                            <div className="chipsImage" style={getChipsCss(seat)}>
                                                                                <img alt="poker" src={'../Images/Icons/TableChips.png'} ></img>
                                                                                <p>{winningAmount.toFixed(2)}</p>
                                                                            </div>
                                                                            : ''}
                                                                        <div className="fill-seat-wrapper" style={getFillCss(seat)}>
                                                                            {player.status == "Ideal" ? <div className="overlay" style={playerProfile}></div> :
                                                                                player.idealPlayer == true ? <div className="overlay" style={playerProfile}></div> :
                                                                                    idealPlayer && idealPlayer.playerId == player.id ? <div className="overlay" style={playerProfile}></div> : ''}
                                                                            {/* {winnerSeatIndex == seat ? <img alt="poker" className='winnerImage' src={'../Images/WinnerRing.png'}></img> : ''} */}
                                                                            <img alt="poker" className="playerAvatarImg" src={playerProfilePhoto(player.avatar)} style={playerProfile}></img>
                                                                            {gamePlayData.playerId == player.id ?
                                                                                <div className={gamePlayData.playerId == player.id ? 'playerDetails countdownContainer' : 'playerDetails'} style={{ transition: 'background-position ' + maxTimer + 's ease-out' }}>
                                                                                    {player.isDealer == true ? <img alt="poker" className={key > 1 && key < 5 ? 'dealerIconRight' : ' dealerIconLeft'} src={'../Images/dealer.png'}></img> : ''}
                                                                                    <p className='playerName' title={player.username}>{player.username.length > 10 ? player.username.slice(0, 10) + '...' : player.username}</p>
                                                                                    <p className='playeChips'>{player.BuyInAmount.toFixed(2)}</p>
                                                                                </div>


                                                                                :
                                                                                <div className={winnerSeatIndex == seat ? 'playerDetails winner-profile' : 'playerDetails'} style={{ transition: 'background-position ' + maxTimer + 's ease-out' }}>
                                                                                    {player.isDealer == true ? <img alt="poker" className={key > 1 && key < 5 ? 'dealerIconRight' : ' dealerIconLeft'} src={'../Images/dealer.png'}></img> : ''}
                                                                                    <p className='playerName' title={player.username}>{player.username.length > 10 ? player.username.slice(0, 10) + '...' : player.username}</p>
                                                                                    <p className='playeChips'>{player.BuyInAmount.toFixed(2)}</p>
                                                                                </div>
                                                                            }
                                                                            {gameStart == true ?
                                                                                gameType == 'omaha' ?
                                                                                    <div>
                                                                                        {player.id != playerData.playerId ?
                                                                                            <div className='cards'>
                                                                                                {gameFinishedPlayersCards.playersCards && gameFinishedPlayersCards.playersCards.length > 0 ?
                                                                                                    <div>
                                                                                                        {gameFinishedPlayersCards.playersCards.map((playersCards, pkey) => {
                                                                                                            if (playersCards.playerId == player.id) {
                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        <div>
                                                                                                                            {displayCard1 == true ? <div className='hiddenCard backFirstCard card-image playercard1 displayForcly' onLoad={() => CardClassName('playercard1')} style={displayBackCard} >
                                                                                                                                <div className="flip-card-inner">
                                                                                                                                    <div className="flip-card-front">
                                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                    </div>
                                                                                                                                    <div className="flip-card-back">
                                                                                                                                        <img alt="poker" src={'../Images/Card/' + playersCards.cards[0] + '.png'}></img>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div> : ""}
                                                                                                                            {displayCard2 == true ? <div className='hiddenCard backSecondCard card-image playercard2 displayForcly' onLoad={() => CardClassName('playercard2')} style={displayBackCard} >
                                                                                                                                <div className="flip-card-inner">
                                                                                                                                    <div className="flip-card-front">
                                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                    </div>
                                                                                                                                    <div className="flip-card-back">
                                                                                                                                        <img alt="poker" src={'../Images/Card/' + playersCards.cards[1] + '.png'}></img>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div> : ""}
                                                                                                                            {displayCard3 == true ? <div className='hiddenCard backThirdCard card-image playercard3 displayForcly' onLoad={() => CardClassName('playercard3')} style={displayBackCard} >
                                                                                                                                <div className="flip-card-inner">
                                                                                                                                    <div className="flip-card-front">
                                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                    </div>
                                                                                                                                    <div className="flip-card-back">
                                                                                                                                        <img alt="poker" src={'../Images/Card/' + playersCards.cards[2] + '.png'}></img>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div> : ""}
                                                                                                                            {displayCard4 == true ? <div className='hiddenCard backFourCard card-image playercard4 displayForcly' onLoad={() => CardClassName('playercard4')} style={displayBackCard} >
                                                                                                                                <div className="flip-card-inner">
                                                                                                                                    <div className="flip-card-front">
                                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                    </div>
                                                                                                                                    <div className="flip-card-back">
                                                                                                                                        <img alt="poker" src={'../Images/Card/' + playersCards.cards[3] + '.png'}></img>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div> : ""}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            } else {
                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                        })}
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div>
                                                                                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                    </div>

                                                                                                }
                                                                                            </div>
                                                                                            :
                                                                                            <div>
                                                                                                {playerCards && playerCards.cards ?
                                                                                                    <div>
                                                                                                        {playerCards.flipAnimation == true ?
                                                                                                            <div>
                                                                                                                {displayCard1 == true ? <div className='hiddenCard frontFirstCard card-image mycard1' onLoad={() => CardClassName('mycard1')} style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                        <div className="flip-card-back">
                                                                                                                            <img alt="poker" src={getCard(1)}></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""}
                                                                                                                {displayCard2 == true ? <div className='hiddenCard frontSecondCard card-image mycard2' onLoad={() => CardClassName('mycard2')} style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                        <div className="flip-card-back">
                                                                                                                            <img alt="poker" src={getCard(2)}></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""}
                                                                                                                {displayCard3 == true ? <div className='hiddenCard frontThirdCard card-image mycard3' onLoad={() => CardClassName('mycard3')} style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                        <div className="flip-card-back">
                                                                                                                            <img alt="poker" src={getCard(3)}></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""}
                                                                                                                {displayCard4 == true ? <div className='hiddenCard frontFourCard card-image mycard4' onLoad={() => CardClassName('mycard4')} style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                        <div className="flip-card-back">
                                                                                                                            <img alt="poker" src={getCard(4)}></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""}
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div>
                                                                                                                {displayCard1 == true ? <div className='hiddenCard frontFirstCard card-image' style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""}
                                                                                                                {displayCard2 == true ? <div className='hiddenCard frontSecondCard card-image' style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""}
                                                                                                                {displayCard3 == true ? <div className='hiddenCard frontThirdCard card-image' style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""}
                                                                                                                {displayCard4 == true ? <div className='hiddenCard frontFourCard card-image' style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""}
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div>
                                                                                                        {displayCard1 == true ? <div className='hiddenCard frontFirstCard card-image card-image-animation' style={displayCard} >
                                                                                                            <div className="flip-card-inner">
                                                                                                                <div className="flip-card-front">
                                                                                                                    <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div> : ""}
                                                                                                        {displayCard2 == true ? <div className='hiddenCard frontSecondCard card-image card-image-animation' style={displayCard} >
                                                                                                            <div className="flip-card-inner">
                                                                                                                <div className="flip-card-front">
                                                                                                                    <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div> : ""}
                                                                                                        {displayCard3 == true ? <div className='hiddenCard frontThirdCard card-image card-image-animation' style={displayCard} >
                                                                                                            <div className="flip-card-inner">
                                                                                                                <div className="flip-card-front">
                                                                                                                    <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div> : ""}
                                                                                                        {displayCard4 == true ? <div className='hiddenCard frontFourCard card-image card-image-animation' style={displayCard} >
                                                                                                            <div className="flip-card-inner">
                                                                                                                <div className="flip-card-front">
                                                                                                                    <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div> : ""}
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    :
                                                                                    <div>
                                                                                        {player.id != playerData.playerId ?
                                                                                            <div className='cards'>
                                                                                                {gameFinishedPlayersCards.playersCards && gameFinishedPlayersCards.playersCards.length > 0 ?
                                                                                                    <div>
                                                                                                        {gameFinishedPlayersCards.playersCards.map((playersCards, pkey) => {
                                                                                                            if (playersCards.playerId == player.id) {
                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        <div>
                                                                                                                            {displayCard2 == true ? <div className='hiddenCard backSecondCard card-image playercard1 displayForcly' onLoad={() => CardClassName('playercard1')} style={displayBackCard} >
                                                                                                                                <div className="flip-card-inner">
                                                                                                                                    <div className="flip-card-front">
                                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                    </div>
                                                                                                                                    <div className="flip-card-back">
                                                                                                                                        <img alt="poker" src={'../Images/Card/' + playersCards.cards[0] + '.png'}></img>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div> : ""}
                                                                                                                            {displayCard3 == true ? <div className='hiddenCard backThirdCard card-image playercard2 displayForcly' onLoad={() => CardClassName('playercard2')} style={displayBackCard} >
                                                                                                                                <div className="flip-card-inner">
                                                                                                                                    <div className="flip-card-front">
                                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                    </div>
                                                                                                                                    <div className="flip-card-back">
                                                                                                                                        <img alt="poker" src={'../Images/Card/' + playersCards.cards[1] + '.png'}></img>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div> : ""}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            } else {
                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                        })}
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div>
                                                                                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                            :

                                                                                            <div>
                                                                                                {playerCards && playerCards.cards ?
                                                                                                    <div>
                                                                                                        {playerCards.flipAnimation == true ?
                                                                                                            <div>
                                                                                                                {displayCard2 == true ? <div className='hiddenCard frontSecondCard card-image mycard1' onLoad={() => CardClassName('mycard1')} style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                        <div className="flip-card-back">
                                                                                                                            <img alt="poker" src={getCard(1)}></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""}
                                                                                                                {displayCard3 == true ? <div className='hiddenCard frontThirdCard card-image mycard2' onLoad={() => CardClassName('mycard2')} style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                        <div className="flip-card-back">
                                                                                                                            <img alt="poker" src={getCard(2)}></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""}
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div>
                                                                                                                {displayCard2 == true ? <div className='hiddenCard frontSecondCard card-image' style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""}
                                                                                                                {displayCard3 == true ? <div className='hiddenCard frontThirdCard card-image' style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""}
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div>
                                                                                                        {displayCard2 == true ? <div className='hiddenCard frontSecondCard card-image card-image-animation' style={displayCard} >
                                                                                                            <div className="flip-card-inner">
                                                                                                                <div className="flip-card-front">
                                                                                                                    <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div> : ""}
                                                                                                        {displayCard3 == true ? <div className='hiddenCard frontThirdCard card-image card-image-animation' style={displayCard} >
                                                                                                            <div className="flip-card-inner">
                                                                                                                <div className="flip-card-front">
                                                                                                                    <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div> : ""}
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                : ''}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            } else if (checkSeatAvailable(seat)) {
                                                                return (
                                                                    <div>
                                                                        {gameStart == false ?
                                                                            <div key={index} className="empty-seat-wrapper" onClick={() => selectBuyIn(seat)} style={getCss(seat)}>
                                                                                <img alt="poker" src={EmptySeat} style={emptySeatImg}></img>
                                                                            </div> : ''
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div>
                                                        <div key={key} className='emptyTable'>
                                                            <div className="empty-seat-wrapper" onClick={() => selectBuyIn(seat)} style={getCss(seat)}>
                                                                <img alt="poker" src={EmptySeat} style={emptySeatImg}></img>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                    :
                                    <div className='emptyTable'>
                                        <div >
                                            {tableSeats.map((seat, key) => {
                                                if (playerInfo.length > 0) {
                                                    return (
                                                        <div key={key} className='emptyTable'>
                                                            {playerInfo.map((player, index) => {
                                                                if (checkPlayerSeat(player, seat)) {
                                                                    return (
                                                                        <div key={index} >
                                                                            {gameStart == true && player.BetAmount > 0 ?
                                                                                <div className="chipsImage" style={getChipsCss(seat)}>
                                                                                    <img alt="poker" src={'../Images/Icons/TableChips.png'} ></img>
                                                                                    <p>{player.BetAmount.toFixed(2)}</p>
                                                                                </div>
                                                                                : ''}
                                                                            {winnerSeatIndex == seat && winningAmount > 0 ?
                                                                                <div className="chipsImage" style={getChipsCss(seat)}>
                                                                                    <img alt="poker" src={'../Images/Icons/TableChips.png'} ></img>
                                                                                    <p>{winningAmount.toFixed(2)}</p>
                                                                                </div>
                                                                                : ''}
                                                                            <div style={animationCardDisplay}>
                                                                                <div className='animation-card-image' style={getCardAnimationCss(seat)}>
                                                                                    <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                                                                </div>
                                                                            </div>
                                                                            <div className="fill-seat-wrapper" style={getFillCss(seat)}>
                                                                                {player.status == "Ideal" ? <div className="overlay" style={playerProfile}></div> :
                                                                                    player.idealPlayer == true ? <div className="overlay" style={playerProfile}></div> :
                                                                                        idealPlayer && idealPlayer.playerId == player.id ? <div className="overlay" style={playerProfile}></div> : ''}
                                                                                {/* {winnerSeatIndex == seat ? <img alt="poker" className='winnerImage' src={'../Images/WinnerRing.png'}></img> : ''} */}
                                                                                <img alt="poker" className="playerAvatarImg" src={playerProfilePhoto(player.avatar)} style={playerProfile}></img>
                                                                                {playerActionData.playerId == player.id && getActionName(playerActionData.action) != '' ?
                                                                                    <p className='playerAction'>{getActionName(playerActionData.action)}</p> : ''}

                                                                                {isPanelOpen == true && gamePlayData.playerId == player.id ?
                                                                                    <div className={gamePlayData.playerId == player.id ? 'playerDetails countdownContainer' : 'playerDetails'} style={{ transition: 'background-position ' + maxTimer + 's ease-out' }}>
                                                                                        {player.isDealer == true ? <img alt="poker" className={key > 1 && key < 5 ? 'dealerIconRight' : ' dealerIconLeft'} src={'../Images/dealer.png'}></img> : ''}
                                                                                        <p className='playerName' title={player.username}>{player.username.length > 10 ? player.username.slice(0, 10) + '...' : player.username}</p>
                                                                                        <p className='playeChips'>{player.BuyInAmount.toFixed(2)}</p>
                                                                                    </div>
                                                                                    :
                                                                                    <div className={winnerSeatIndex == seat ? 'playerDetails winner-profile' : 'playerDetails'} style={{ transition: 'background-position ' + maxTimer + 's ease-out' }}>
                                                                                        {player.isDealer == true ? <img alt="poker" className={key > 1 && key < 5 ? 'dealerIconRight' : ' dealerIconLeft'} src={'../Images/dealer.png'}></img> : ''}
                                                                                        <p className='playerName' title={player.username}>{player.username.length > 10 ? player.username.slice(0, 10) + '...' : player.username}</p>
                                                                                        <p className='playeChips'>{player.BuyInAmount.toFixed(2)}</p>
                                                                                    </div>
                                                                                }
                                                                                {gameStart == true ?
                                                                                    gameType == 'omaha' ?
                                                                                        <div>
                                                                                            {player.id != playerData.playerId ?
                                                                                                <div>
                                                                                                    {gameFinishedPlayersCards.playersCards && gameFinishedPlayersCards.playersCards.length > 0 ?
                                                                                                        <div>
                                                                                                            {gameFinishedPlayersCards.playersCards.map((playersCards, pkey) => {
                                                                                                                if (playersCards.playerId == player.id) {
                                                                                                                    return (
                                                                                                                        <div>
                                                                                                                            <div>
                                                                                                                                {displayCard1 == true ? <div className='hiddenCard backFirstCard card-image playercard1 displayForcly' onLoad={() => CardClassName('playercard1')} style={displayBackCard} >
                                                                                                                                    <div className="flip-card-inner">
                                                                                                                                        <div className="flip-card-front">
                                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                        </div>
                                                                                                                                        <div className="flip-card-back">
                                                                                                                                            <img alt="poker" src={'../Images/Card/' + playersCards.cards[0] + '.png'}></img>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div> : ""}
                                                                                                                                {displayCard2 == true ? <div className='hiddenCard backSecondCard card-image playercard2 displayForcly' onLoad={() => CardClassName('playercard2')} style={displayBackCard} >
                                                                                                                                    <div className="flip-card-inner">
                                                                                                                                        <div className="flip-card-front">
                                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                        </div>
                                                                                                                                        <div className="flip-card-back">
                                                                                                                                            <img alt="poker" src={'../Images/Card/' + playersCards.cards[1] + '.png'}></img>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div> : ""}
                                                                                                                                {displayCard3 == true ? <div className='hiddenCard backThirdCard card-image playercard3 displayForcly' onLoad={() => CardClassName('playercard3')} style={displayBackCard} >
                                                                                                                                    <div className="flip-card-inner">
                                                                                                                                        <div className="flip-card-front">
                                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                        </div>
                                                                                                                                        <div className="flip-card-back">
                                                                                                                                            <img alt="poker" src={'../Images/Card/' + playersCards.cards[2] + '.png'}></img>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div> : ""}
                                                                                                                                {displayCard4 == true ? <div className='hiddenCard backFourCard card-image playercard4 displayForcly' onLoad={() => CardClassName('playercard4')} style={displayBackCard} >
                                                                                                                                    <div className="flip-card-inner">
                                                                                                                                        <div className="flip-card-front">
                                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                        </div>
                                                                                                                                        <div className="flip-card-back">
                                                                                                                                            <img alt="poker" src={'../Images/Card/' + playersCards.cards[3] + '.png'}></img>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div> : ""}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                } else {
                                                                                                                    return (
                                                                                                                        <div>
                                                                                                                            {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                                            {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                                            {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                                            {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                }
                                                                                                            })}
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div>
                                                                                                            {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                            {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                            {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                            {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                                :
                                                                                                <div>
                                                                                                    {playerCards && playerCards.cards ?
                                                                                                        <div>
                                                                                                            {playerCards.flipAnimation == true ?
                                                                                                                <div>
                                                                                                                    {displayCard1 == true ? <div className='hiddenCard frontFirstCard card-image mycard1' onLoad={() => CardClassName('mycard1')} style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                            <div className="flip-card-back">
                                                                                                                                <img alt="poker" src={getCard(1)}></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                    {displayCard2 == true ? <div className='hiddenCard frontSecondCard card-image mycard2' onLoad={() => CardClassName('mycard2')} style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                            <div className="flip-card-back">
                                                                                                                                <img alt="poker" src={getCard(2)}></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                    {displayCard3 == true ? <div className='hiddenCard frontThirdCard card-image mycard3' onLoad={() => CardClassName('mycard3')} style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                            <div className="flip-card-back">
                                                                                                                                <img alt="poker" src={getCard(3)}></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                    {displayCard4 == true ? <div className='hiddenCard frontFourCard card-image mycard4' onLoad={() => CardClassName('mycard4')} style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                            <div className="flip-card-back">
                                                                                                                                <img alt="poker" src={getCard(4)}></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                </div>
                                                                                                                :
                                                                                                                <div>
                                                                                                                    {displayCard1 == true ? <div className='hiddenCard frontFirstCard card-image' style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                    {displayCard2 == true ? <div className='hiddenCard frontSecondCard card-image' style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                    {displayCard3 == true ? <div className='hiddenCard frontThirdCard card-image' style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                    {displayCard4 == true ? <div className='hiddenCard frontFourCard card-image' style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div>
                                                                                                            {displayCard1 == true ? <div className='hiddenCard frontFirstCard card-image card-image-animation' style={displayCard} >
                                                                                                                <div className="flip-card-inner">
                                                                                                                    <div className="flip-card-front">
                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> : ""}
                                                                                                            {displayCard2 == true ? <div className='hiddenCard frontSecondCard card-image card-image-animation' style={displayCard} >
                                                                                                                <div className="flip-card-inner">
                                                                                                                    <div className="flip-card-front">
                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> : ""}
                                                                                                            {displayCard3 == true ? <div className='hiddenCard frontThirdCard card-image card-image-animation' style={displayCard} >
                                                                                                                <div className="flip-card-inner">
                                                                                                                    <div className="flip-card-front">
                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> : ""}
                                                                                                            {displayCard4 == true ? <div className='hiddenCard frontFourCard card-image card-image-animation' style={displayCard} >
                                                                                                                <div className="flip-card-inner">
                                                                                                                    <div className="flip-card-front">
                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> : ""}
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        :
                                                                                        <div>
                                                                                            {player.id != playerData.playerId ?
                                                                                                <div className='cards'>
                                                                                                    {gameFinishedPlayersCards.playersCards && gameFinishedPlayersCards.playersCards.length > 0 ?
                                                                                                        <div>
                                                                                                            {gameFinishedPlayersCards.playersCards.map((playersCards, pkey) => {
                                                                                                                if (playersCards.playerId == player.id) {
                                                                                                                    return (
                                                                                                                        <div>
                                                                                                                            <div>
                                                                                                                                {displayCard2 == true ? <div className='hiddenCard backSecondCard card-image playercard1 displayForcly' onLoad={() => CardClassName('playercard1')} style={displayBackCard} >
                                                                                                                                    <div className="flip-card-inner">
                                                                                                                                        <div className="flip-card-front">
                                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                        </div>
                                                                                                                                        <div className="flip-card-back">
                                                                                                                                            <img alt="poker" src={'../Images/Card/' + playersCards.cards[0] + '.png'}></img>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div> : ""}
                                                                                                                                {displayCard3 == true ? <div className='hiddenCard backThirdCard card-image playercard2 displayForcly' onLoad={() => CardClassName('playercard2')} style={displayBackCard} >
                                                                                                                                    <div className="flip-card-inner">
                                                                                                                                        <div className="flip-card-front">
                                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                        </div>
                                                                                                                                        <div className="flip-card-back">
                                                                                                                                            <img alt="poker" src={'../Images/Card/' + playersCards.cards[1] + '.png'}></img>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div> : ""}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                } else {
                                                                                                                    return (
                                                                                                                        <div>
                                                                                                                            {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                                            {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                }
                                                                                                            })}
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div>
                                                                                                            {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                            {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                                :
                                                                                                <div>
                                                                                                    {playerCards && playerCards.cards ?
                                                                                                        <div>
                                                                                                            {playerCards.flipAnimation == true ?
                                                                                                                <div>
                                                                                                                    {displayCard2 == true ? <div className='hiddenCard frontSecondCard card-image mycard1' onLoad={() => CardClassName('mycard1')} style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                            <div className="flip-card-back">
                                                                                                                                <img alt="poker" src={getCard(1)}></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                    {displayCard3 == true ? <div className='hiddenCard frontThirdCard card-image mycard2' onLoad={() => CardClassName('mycard2')} style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                            <div className="flip-card-back">
                                                                                                                                <img alt="poker" src={getCard(2)}></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                </div>
                                                                                                                :
                                                                                                                <div>
                                                                                                                    {displayCard2 == true ? <div className='hiddenCard frontSecondCard card-image' style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                    {displayCard3 == true ? <div className='hiddenCard frontThirdCard card-image' style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div>
                                                                                                            {displayCard2 == true ? <div className='hiddenCard frontSecondCard card-image card-image-animation' style={displayCard} >
                                                                                                                <div className="flip-card-inner">
                                                                                                                    <div className="flip-card-front">
                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> : ""}
                                                                                                            {displayCard3 == true ? <div className='hiddenCard frontThirdCard card-image card-image-animation' style={displayCard} >
                                                                                                                <div className="flip-card-inner">
                                                                                                                    <div className="flip-card-front">
                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> : ""}
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    : ''}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            })}
                                                        </div>
                                                    )
                                                } else {
                                                    return (<div key={key} className='emptyTable'>
                                                        <div className="empty-seat-wrapper winner-profile" onClick={() => selectBuyIn(seat)} style={getCss(seat)}>
                                                            <img alt="poker" src={EmptySeat} style={emptySeatImg} className='profile-image'></img>
                                                        </div>
                                                    </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                }
                                {/* <div className='footerAction'>
                                    <div className='footerActionBtn'>
                                        <button onClick={() => resetCardAnimationPostion()} className='footerBtn btnDanger'>Back Animation</button>
                                        <button onClick={() => cardAnimation()} className='footerBtn btnDanger'>Animation</button>
                                        <button onClick={() => soundCheck(1)} className='footerBtn btnDanger'>Chips</button>
                                        <button onClick={() => soundCheck(2)} className='footerBtn btnDanger'>fold</button>
                                        <button onClick={() => soundCheck(3)} className='footerBtn btnDanger'>deal</button>
                                        <button onClick={() => soundCheck(4)} className='footerBtn btnDanger'>check</button>
                                        <button onClick={() => soundCheck(5)} className='footerBtn btnDanger'>myturn</button>
                                        <button onClick={() => soundCheck(6)} className='footerBtn btnDanger'>winner</button>
                                        <button onClick={() => soundCheck(7)} className='footerBtn btnDanger'>collect</button>
                                        <button onClick={() => soundCheck(8)} className='footerBtn btnDanger'>cardshuffle</button>
                                    </div>
                                </div> */}

                                {gameStart ?
                                    isPanelOpen == true && gamePlayData.playerId == playerData.playerId ?
                                        <div>

                                            <div className='footerAction'>
                                                {gamePlayData.buttonAction.raise || gamePlayData.buttonAction.bet ?
                                                    <div className='footerSliderInput '>
                                                        <div className='footerSliderBox'>
                                                            <div className='raiseInput gap-3 d-flex'>
                                                                <span>{Number(raiseAmount).toFixed(2)}</span>
                                                                <input
                                                                    value={raiseAmount}
                                                                    step="0.1"
                                                                    min={minRaise}
                                                                    max={maxRaise}
                                                                    type="range"
                                                                    onChange={(e) => raiseAmountChange(e.target.value)}
                                                                    style={{ width: "80%" }}
                                                                ></input>
                                                            </div>
                                                            <button className='raiseBtn' onClick={(e) => raiseAmountChange(raiseAmount >= minRaise ? raiseAmount - 0.1 : 0)}>-</button>
                                                            <button className='raiseBtn' onClick={(e) => raiseAmountChange(raiseAmount <= maxRaise ? raiseAmount + 0.1 : 0)}>+</button>
                                                        </div>
                                                    </div>
                                                    : ''}
                                                <div className='footerActionBtn'>
                                                    <button className='footerBtn btnDanger' onClick={() => PlayerAction(6, false, 0)}>FOLD</button>
                                                    {gamePlayData.buttonAction.call ? <button className='footerBtn btnDefault' onClick={() => PlayerAction(4, false, 2)}>CALL <span>({gamePlayData.buttonAction.callAmount.toFixed(2)})</span></button> : ''}
                                                    {gamePlayData.buttonAction.check ? <button className='footerBtn btnDefault' onClick={() => PlayerAction(2, false, 0)}>CHECK</button> : ''}
                                                    {gamePlayData.buttonAction.raise ? <button className='footerBtn btnSuccess' onClick={() => PlayerAction(3, true, raiseAmount)}>RAISE <span>({Number(raiseAmount).toFixed(2)})</span></button> : ''}
                                                    {gamePlayData.buttonAction.bet ? <button className='footerBtn btnSuccess' onClick={() => PlayerAction(3, false, raiseAmount)}>BET <span>({Number(raiseAmount).toFixed(2)})</span></button> : ''}
                                                    {gamePlayData.buttonAction.allIn ? <button className='footerBtn btnSuccess' onClick={() => PlayerAction(8, true, gamePlayData.buttonAction.allInAmount)}>ALL IN <span>({gamePlayData.buttonAction.allInAmount.toFixed(2)})</span></button> : ''}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        isFooterPanel ?
                                            <div>
                                                <div className='footerActionCheckBoxLeft game-checkbox'>
                                                    <label className="form-control">
                                                        <input type="checkbox" name="checkbox" checked={sitOut.nextHand} onChange={(e) => sitOutCheckbox(e, 0)} />
                                                        Sit Out Next Hand
                                                    </label>
                                                    <label className="form-control">
                                                        <input type="checkbox" name="checkbox" checked={sitOut.bigBlind} onChange={(e) => sitOutCheckbox(e, 1)} />
                                                        Sit Out Next Big Blind
                                                    </label>
                                                </div>
                                                <div className='footerActionCheckBoxRight game-checkbox'>
                                                    <div className='d-flex'>
                                                        <label className="form-control">
                                                            <input type="checkbox" name="checkbox" checked={defaultAction == 'isFold' ? true : false} onChange={(e) => defaultActionSelection(e, 0)} />
                                                            Fold Any
                                                        </label>
                                                        <label className="form-control">
                                                            <input type="checkbox" name="checkbox" checked={defaultAction == 'isCheck' ? true : false} onChange={(e) => defaultActionSelection(e, 1)} />
                                                            Check/Fold
                                                        </label>
                                                        <label className="form-control">
                                                            <input type="checkbox" name="checkbox" checked={defaultAction == 'isCall' ? true : false} onChange={(e) => defaultActionSelection(e, 2)} />
                                                            Call Any
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    :
                                    ''
                                }

                                {playerInfo.length > 0 ? playerInfo.map((player, index) => {
                                    if (player.id == playerData.playerId)
                                        if (player.status == 'Ideal' && player.id == playerData.playerId) {
                                            return (
                                                <button className='iAmBackBtn gap-2' onClick={() => sendPlayerOnline()}>
                                                    I Am Back
                                                </button>
                                            )
                                        } else if (player.idealPlayer == true && player.id == playerData.playerId) {
                                            return (
                                                <button className='iAmBackBtn gap-2' onClick={() => sendPlayerOnline()}>
                                                    I Am Back
                                                </button>
                                            )
                                        } else if (idealPlayer && idealPlayer.playerId == playerData.playerId) {
                                            return (
                                                <button className='iAmBackBtn gap-2' onClick={() => sendPlayerOnline()}>
                                                    I Am Back
                                                </button>
                                            )
                                        }
                                }) : ''}
                            </div>

                        </div>
                        <div>

                        </div>
                    </div>
                    {isLoader ?
                        <div className='mobileView'>
                            <div className='loader '>
                                <img alt="poker" src={'../Images/LoginLogo.png'} className='gameLogo'></img>
                                <div className='loading'>
                                    <p>Loading...</p>
                                </div>
                                <div className="progress-6"></div>
                            </div>
                        </div>
                        : ''
                    }


                    {tableData ?
                        <Modal
                            isOpen={isRoomModalOpen}
                            onRequestClose={closeModal}
                            style={customStylesMobile}
                            contentLabel="Example Modal"
                            ariaHideApp={false}
                        >
                            <div className="mobileView buyInModal gameModel">
                                <h2>Available Chips : {tableData.playerChips.toFixed(2)}</h2>
                                <hr></hr>
                                <div className='chipsDetails mb-4'>
                                    <div className='d-flex gap-3'>
                                        <div className='minBuyIn p-col-3'>
                                            <p>Min</p>
                                            <span>{minBuyIn.toFixed(2)}</span>
                                        </div>
                                        <div className='chipsBuyIn p-col-6'>
                                            <input
                                                value={start}
                                                type="number"
                                                onChange={(e) => setStart(e.target.value)}
                                                min={minBuyIn}
                                                max={maxBuyIn}
                                                readOnly
                                            ></input>
                                        </div>
                                        <div className='maxBuyIn p-col-3'>
                                            <p>Max</p>
                                            <span>{maxBuyIn.toFixed(2)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='sliderInput center'>
                                    <input
                                        value={start}
                                        step="0.1"
                                        min={minBuyIn}
                                        max={maxBuyIn}
                                        type="range"
                                        onChange={(e) => onStartChange(e.target.value)}
                                        style={{ width: "80%" }}
                                    ></input>
                                </div>
                                {buyIn ?
                                    <button className=' btn selectBuyInBtn' onClick={() => sitOnTable(1)}>Add Chips</button>
                                    :
                                    <button className=' btn selectBuyInBtn' onClick={() => sitOnTable(0)}>Select</button>
                                }

                            </div>
                        </Modal> : ''}
                    <Modal
                        isOpen={isLogoutModalOpen}
                        onRequestClose={closeModal}
                        style={customStylesLogoutMobile}
                        contentLabel="Example Modal"
                        ariaHideApp={false}
                    >
                        <div className='mobileView gameModel'>
                            <div className='modalBody'>
                                <center>
                                    <h2 className='color-1'>Warning!</h2>
                                    <h4 className='color-1 mt-4'>Are you sure you want leave this game?</h4>
                                    <div className='avtarBtnSection'>
                                        <button className='selectBuyInBtn' onClick={() => leaveGame()}>Yes</button>
                                        <button className='selectBuyInBtn' onClick={() => closeModal()}>No</button>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={isBuyHourModalOpen}
                        onRequestClose={closeModal}
                        style={customStylesMobile}
                        contentLabel="Example Modal"
                        ariaHideApp={false}
                    >
                        <div className="mobileView buyInModal gameModel">
                            <h2>Buy Hour </h2>
                            <hr></hr>
                            <div className='chipsDetails mb-4'>
                                <div className='d-flex gap-3'>
                                    <select className='form-control hour-buy-dropdown' value={selectedHour} onChange={(e) => setSelectedHour(e.target.value)}>
                                        <option>Select Hour</option>
                                        {hoursOption.map((hour, index) => {
                                            return (
                                                <option key={index} value={hour.value}>{hour.name}</option>
                                            );
                                        })
                                        };
                                    </select>
                                </div>
                            </div>
                            <div className='avtarBtnSection'>
                                <button className='selectBuyInBtn' onClick={() => buyPackage(0)}>Buy</button>
                                <button className='selectBuyInBtn' onClick={() => buyPackage(1)}>Cancle</button>
                            </div>
                        </div>
                    </Modal>

                </div>
                :
                <div>
                    <div className='fixed-screen'>
                        <div id="mySidenav" className="chatBar" style={sidebarWidth}>
                            <h4 className='chat-heading'> Table Chat</h4>
                            <a href="javascript:void(0)" className="sideBarClosebtn" onClick={() => closeSideBar()}>&times;</a>
                            <hr></hr>

                            <section className="msger" style={openEmojiBox ? { height: '30%' } : {}}>
                                <main className="msger-chat" ref={messageEl}>
                                    {gameChatMessages?.map((messageData, index) => {
                                        return (
                                            <div className={messageData.playerId == playerData.playerId ? 'msg right-msg' : 'msg left-msg'}>
                                                <div className="msg-bubble">
                                                    <div className="msg-info">
                                                        <div className="msg-info-name">{messageData.username}</div>
                                                    </div>
                                                    <div className="msg-text">{messageData.message}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </main>
                            </section>

                            <form className="msger-inputarea" onSubmit={() => sendMessage()}>
                                <div className="msger-input-box">
                                    {openEmojiBox ?
                                        <button type="button" className="plainBtn headerBtn msger-send-btn" onClick={() => setOpenEmojiBox(false)}>
                                            <img alt="poker" src={'../Images/Icons/SmileClose.png'} />
                                        </button> :
                                        <button type="button" className="plainBtn headerBtn msger-send-btn" onClick={() => setOpenEmojiBox(true)}>
                                            <img alt="poker" src={'../Images/Icons/Smile.png'} />
                                        </button>
                                    }
                                    <input type="text" className="msger-input" placeholder="Enter your message..." value={userMessage} onChange={(e) => setUserMessage(e.target.value)} />
                                    {userMessage == '' ?
                                        <button type="button" className="plainBtn headerBtn msger-send-btn">
                                            <img alt="poker" src={'../Images/Icons/Send.png'} />
                                        </button>
                                        :
                                        <button type="submit" className="plainBtn headerBtn msger-send-btn">
                                            <img alt="poker" src={'../Images/Icons/SendFill.png'} />
                                        </button>
                                    }
                                </div>
                                {openEmojiBox ? <div className='msger-emojibox'>
                                    <center>
                                        <Picker data={data} onEmojiSelect={selectEmoji} />
                                    </center>
                                </div>
                                    : ""}
                            </form>

                            <div className='leftChatBox'>
                                <p></p>
                            </div>
                        </div>

                        <div className='game-page'>
                            <div className='gameTable'>
                                <div className='gameTableHeader'>
                                    <div className='leftPanel'>
                                        <button type='button' className='plainBtn headerBtn' data-title="Leave Game"><img alt="poker" src={'../Images/Icons/NewBackIcon.png'} onClick={() => setIsLogoutModalOpen(true)} />
                                            {/* <span className='muteTitle'>Table Info</span> */}
                                        </button>
                                    </div>
                                    <div className='chatBtnBox'>
                                        <button type='button' className='plainBtn chatBtn' data-title="Open Chat"><img alt="poker" src={'../Images/Icons/Chat.png'} onClick={() => openSideBar()} />
                                            {/* <span className='muteTitle'>Table Info</span> */}
                                        </button>
                                    </div>
                                    {/* <div className='leftPanel'>
                                        <div className='backIcon'>
                                            <button type='button' className='plainBtn' data-title="Leave Game"><img alt="poker" src={'../Images/Icons/NewBackIcon.png'} onClick={() => setIsLogoutModalOpen(true)} /></button>
                                        </div>
                                    </div> */}
                                    <div className='rightPanel'>
                                        <button type='button' className='plainBtn headerBtn' data-title='Buy Hours'><img alt="poker" src={'../Images/Icons/PlusBox.png'} onClick={() => openHourBuyModal(1)} />
                                            {/* <span className='muteTitle'>Table Info</span> */}
                                        </button>
                                        <div className='playerLeftHours'>
                                            {/* <button type='button' className='plainBtn' data-title='Buy Hours' ><img alt="poker" src={'../Images/Icons/PlusBox.png'} /></button> */}
                                            <div className='d-flex align-item-center'>
                                                <img alt="poker" className="timeIcon" src={'../Images/Icons/Timer.png'} />
                                                <span>{playerData ? playerData.hour_balance : '00.00'}</span>
                                            </div>
                                        </div>
                                        {/* <button type='button' className='plainBtn' data-title='Buy Chips' onClick={() => muteVolume()}>
                                            <img alt="poker" src={'../Images/Icons/BuyChips.png'} />
                                            <span className='muteTitle'>Mute</span>
                                        </button> : */}
                                        {/* {voice == true ?
                                            <button type='button' className='plainBtn' data-title='Buy Chips' onClick={() => setVoice(false)}>
                                                <img alt="poker" src={'../Images/Icons/BuyChips.png'} />
                                                <span className='muteTitle'>Mute</span>
                                            </button> :
                                            <button type='button' className='plainBtn' data-title='Buy Chips' onClick={() => setVoice(true)}>
                                                <img alt="poker" src={'../Images/Icons/BuyChips.png'} />
                                                <span className='muteTitle'>Unmute</span>
                                            </button>} */}
                                        {roomType != socketConfig.CashRegularTexas ?
                                            <button type='button' className='plainBtn headerBtn' data-title='Add money to table' onClick={() => getReBuyInChips()}>
                                                <img alt="poker" src={'../Images/Icons/BuyChips.png'} />
                                                {/* <span className='muteTitle'>Buy Chips</span> */}
                                            </button>
                                            : ''}
                                        <button type='button' className='plainBtn headerBtn' data-title='Info'><img alt="poker" src={'../Images/Icons/InfoIcon.png'} />
                                            {/* <span className='muteTitle'>Table Info</span> */}
                                        </button>
                                        <button type='button' className='plainBtn headerBtn' data-title='Settings'><img alt="poker" src={'../Images/Icons/Setting.png'} />
                                            {/* <span className='muteTitle'>Table Info</span> */}
                                        </button>
                                        <button type='button' className='plainBtn headerBtn' data-title='FullScreen'><img alt="poker" src={'../Images/Icons/FullScreen.png'} />
                                            {/* <span className='muteTitle'>Table Info</span> */}
                                        </button>
                                        {/* <img alt="poker" src={'../Images/Icons/NetworkIcon.png'} /> */}
                                    </div>
                                </div>

                                <div className='tableDetails'>
                                    <p className='color-1'>{roomData.room?.name} - <span style={{ textTransform: "capitalize" }}>{roomData.room?.tableType}</span></p>
                                    <p className='color-2'>Stakes : {roomData.room?.smallBlind}/{roomData.room?.bigBlind}</p>
                                </div>
                                <img alt="poker" className='poker-table-box' src={GameTable} style={tableImage}></img>

                                {cardDistribute ? <div className='hiddenCard hiddenCardShuffle' style={hiddenCard3Css} >
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front">
                                            <img alt="poker" src={'../Images/Icons/BackCard.png'}></img>
                                        </div>
                                    </div>
                                </div> : ''}

                                {gameStart == true ? <div className='tablePot' style={tablePortBlock}>Pot : {tablePot.toFixed(2)}</div> : ''}
                                {winningType ? <div className='winnerType'>{winningType}</div> : ''}
                                {hiddenCards.length > 0 ?
                                    <div className='hiddenCard slide-in hiddenCard1' onLoad={() => hidddenCardClassName('hiddenCard1')} style={hiddenCard1Css} >
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front">
                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                            </div>
                                            <div className="flip-card-back" onLoad={() => cardFlipice(0)}>
                                                <img alt="poker" src={'../Images/Card/' + hiddenCards[0] + '.png'}></img>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                                {hiddenCards.length > 1 ?
                                    <div className='hiddenCard slide-in hiddenCard2' onLoad={() => hidddenCardClassName('hiddenCard2')} style={hiddenCard2Css} >
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front">
                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                            </div>
                                            <div className="flip-card-back" onLoad={() => cardFlipice(1)}>
                                                <img alt="poker" src={'../Images/Card/' + hiddenCards[1] + '.png'}></img>
                                            </div>
                                        </div>
                                    </div> : ''}
                                {hiddenCards.length > 2 ?
                                    <div className='hiddenCard slide-in hiddenCard3' onLoad={() => hidddenCardClassName('hiddenCard3')} style={hiddenCard3Css} >
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front">
                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                            </div>
                                            <div className="flip-card-back" onLoad={() => cardFlipice(2)}>
                                                <img alt="poker" src={'../Images/Card/' + hiddenCards[2] + '.png'}></img>
                                            </div>
                                        </div>
                                    </div> : ''}

                                {hiddenCards.length > 3 ?
                                    <div className='hiddenCard slide-in hiddenCard4' onLoad={() => hidddenCardClassName('hiddenCard4')} style={hiddenCard4Css} >
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front">
                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                            </div>
                                            <div className="flip-card-back" onLoad={() => cardFlipice(3)}>
                                                <img alt="poker" src={'../Images/Card/' + hiddenCards[3] + '.png'}></img>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                                {hiddenCards.length > 4 ?
                                    <div className='hiddenCard slide-in hiddenCard5' onLoad={() => hidddenCardClassName('hiddenCard5')} style={hiddenCard5Css} >
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front">
                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                            </div>
                                            <div className="flip-card-back" onLoad={() => cardFlipice(4)}>
                                                <img alt="poker" src={'../Images/Card/' + hiddenCards[4] + '.png'}></img>
                                            </div>
                                        </div>
                                    </div> : ''}
                                {playerSidePot.mainPot > 0 ?
                                    <div className="collectChipsImage" style={playerChips0}>
                                        <img alt="poker" src={'../Images/Icons/ChipsIcon.png'} ></img>
                                        <p>{playerSidePot.mainPot.toFixed(2)}</p>
                                    </div>
                                    : ''}

                                {/* <div style={animationCardDisplay}>
                                    <div className='animation-card-image' style={getCardAnimationCss(0)}>
                                        <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                    </div>
                                </div>
                                <div className="fill-seat-wrapper" style={getFillCss(0)}>
                                    <img alt="poker" className="playerAvatarImg" src='../Images/Avtar/4.png' style={playerProfile}></img>
                                    <p className='playerAction'>Check</p>
                                    <div className='playerDetails winner-profile' >
                                        <p className='playerName'>Milan Maiya</p>
                                        <p className='playeChips'>100.00</p>
                                    </div>
                                    <div>
                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                    </div>
                                </div>

                                <div style={animationCardDisplay}>
                                    <div className='animation-card-image' style={getCardAnimationCss(1)}>
                                        <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                    </div>
                                </div>
                                <div className="fill-seat-wrapper" style={getFillCss(1)}>
                                    <img alt="poker" className="playerAvatarImg" src='../Images/Avtar/5.png' style={playerProfile}></img>
                                    <div className='playerDetails' >
                                        <p className='playerName'>Jack Rao</p>
                                        <p className='playeChips'>100.00</p>
                                    </div>
                                    <div>
                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                    </div>
                                </div>
                                <div style={animationCardDisplay}>
                                    <div className='animation-card-image' style={getCardAnimationCss(2)}>
                                        <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                    </div>
                                </div>
                                <div className="fill-seat-wrapper" style={getFillCss(2)}>
                                    <img alt="poker" className="playerAvatarImg" src='../Images/Avtar/3.png' style={playerProfile}></img>
                                    <div className='playerDetails' >
                                        <p className='playerName'>Paul Zerdin</p>
                                        <p className='playeChips'>100.00</p>
                                    </div>
                                    <div>
                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                    </div>
                                </div>
                                <div style={animationCardDisplay}>
                                    <div className='animation-card-image' style={getCardAnimationCss(3)}>
                                        <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                    </div>
                                </div>
                                <div className="fill-seat-wrapper" style={getFillCss(3)}>
                                    <img alt="poker" className="playerAvatarImg" src='../Images/Avtar/1.png' style={playerProfile}></img>
                                    <div className='playerDetails' >
                                        <p className='playerName'>Megha Bambhaniya</p>
                                        <p className='playeChips'>100.00</p>
                                    </div>
                                    <div>
                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                    </div>
                                </div>
                                <div style={animationCardDisplay}>
                                    <div className='animation-card-image' style={getCardAnimationCss(4)}>
                                        <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                    </div>
                                </div>
                                <div className="fill-seat-wrapper" style={getFillCss(4)}>
                                    <img alt="poker" className="playerAvatarImg" src='../Images/Avtar/1.png' style={playerProfile}></img>
                                    <div className='playerDetails' >
                                        <p className='playerName'>Megha Bambhaniya</p>
                                        <p className='playeChips'>100.00</p>
                                    </div>
                                    <div>
                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                    </div>
                                </div>
                                <div style={animationCardDisplay}>
                                    <div className='animation-card-image' style={getCardAnimationCss(5)}>
                                        <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                    </div>
                                </div>
                                <div className="fill-seat-wrapper" style={getFillCss(5)}>
                                    <img alt="poker" className="playerAvatarImg" src='../Images/Avtar/3.png' style={playerProfile}></img>
                                    <div className='playerDetails' >
                                        <p className='playerName'>Paul Zerdin</p>
                                        <p className='playeChips'>100.00</p>
                                    </div>
                                    <div>
                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                    </div>
                                </div>
                                <div style={animationCardDisplay}>
                                    <div className='animation-card-image' style={getCardAnimationCss(6)}>
                                        <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                    </div>
                                </div>
                                <div className="fill-seat-wrapper" style={getFillCss(6)}>
                                    <img alt="poker" className="playerAvatarImg" src='../Images/Avtar/3.png' style={playerProfile}></img>
                                    <div className='playerDetails' >
                                        <p className='playerName'>Mona Yom</p>
                                        <p className='playeChips'>120.00</p>
                                    </div>
                                    <div>
                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                    </div>
                                </div>
                                <div style={animationCardDisplay}>
                                    <div className='animation-card-image' style={getCardAnimationCss(7)}>
                                        <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                    </div>
                                </div>
                                <div className="fill-seat-wrapper" style={getFillCss(7)}>
                                    <img alt="poker" className="playerAvatarImg" src='../Images/Avtar/3.png' style={playerProfile}></img>
                                    <div className='playerDetails' >
                                        <p className='playerName'>John Deo</p>
                                        <p className='playeChips'>500.00</p>
                                    </div>
                                    <div>
                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                    </div>
                                </div>
                                <div style={animationCardDisplay}>
                                    <div className='animation-card-image' style={getCardAnimationCss(8)}>
                                        <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                    </div>
                                </div>
                                <div className="fill-seat-wrapper" style={getFillCss(8)}>
                                    <img alt="poker" className="playerAvatarImg" src='../Images/Avtar/3.png' style={playerProfile}></img>
                                    <div className='playerDetails countdownContainer' >
                                        <p className='playerName'>Badshah</p>
                                        <p className='playeChips'>100.00</p>
                                    </div>
                                    <div>
                                        {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                        {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                    </div>
                                </div> */}
                                {emptyTable ?
                                    <div >
                                        {tableSeats?.map((seat, key) => {
                                            if (playerInfo.length > 0) {
                                                return (
                                                    <div key={key} className='emptyTable'>
                                                        {playerInfo?.map((player, index) => {
                                                            if (checkPlayerSeat(player, seat)) {
                                                                return (
                                                                    <div key={index}>
                                                                        {gameStart == true && player.BetAmount > 0 ?
                                                                            <div className="chipsImage" style={getChipsCss(seat)}>
                                                                                <img alt="poker" src={'../Images/Icons/TableChips.png'} ></img>
                                                                                <p>{winningAmount.toFixed(2)}</p>
                                                                            </div>
                                                                            : ''}
                                                                        {winnerSeatIndex == seat && winningAmount > 0 ?
                                                                            <div className="chipsImage" style={getChipsCss(seat)}>
                                                                                <img alt="poker" src={'../Images/Icons/TableChips.png'} ></img>
                                                                                <p>{winningAmount.toFixed(2)}</p>
                                                                            </div>
                                                                            : ''}
                                                                        <div className="fill-seat-wrapper" style={getFillCss(seat)}>
                                                                            {player.status == "Ideal" ? <div className="overlay" style={playerProfile}></div> :
                                                                                player.idealPlayer == true ? <div className="overlay" style={playerProfile}></div> :
                                                                                    idealPlayer && idealPlayer.playerId == player.id ? <div className="overlay" style={playerProfile}></div> : ''}
                                                                            {/* {winnerSeatIndex == seat ? <img alt="poker" className='winnerImage' src={'../Images/WinnerRing.png'}></img> : ''} */}
                                                                            <img alt="poker" className="playerAvatarImg" src={playerProfilePhoto(player.avatar)} style={playerProfile}></img>
                                                                            {isPanelOpen == true && gamePlayData.playerId == player.id ?
                                                                                <div className={gamePlayData.playerId == player.id ? 'playerDetails countdownContainer' : 'playerDetails'} style={{ transition: 'background-position ' + maxTimer + 's ease-out' }}>
                                                                                    {player.isDealer == true ? <img alt="poker" className={key > 1 && key < 5 ? 'dealerIconRight' : 'dealerIconLeft'} src={'../Images/dealer.png'}></img> : ''}
                                                                                    <p className='playerName' title={player.username}>{player.username.length > 10 ? player.username.slice(0, 10) + '...' : player.username}</p>
                                                                                    <p className='playeChips'>{player.BuyInAmount.toFixed(2)}</p>
                                                                                </div>
                                                                                :
                                                                                <div className={winnerSeatIndex == seat ? 'playerDetails winner-profile' : 'playerDetails'} style={{ transition: 'background-position ' + maxTimer + 's ease-out' }}>
                                                                                    {player.isDealer == true ? <img alt="poker" className={key > 1 && key < 5 ? 'dealerIconRight' : ' dealerIconLeft'} src={'../Images/dealer.png'}></img> : ''}
                                                                                    <p className='playerName' title={player.username}>{player.username.length > 10 ? player.username.slice(0, 10) + '...' : player.username}</p>
                                                                                    <p className='playeChips'>{player.BuyInAmount.toFixed(2)}</p>
                                                                                </div>
                                                                            }
                                                                            {gameStart == true ?
                                                                                gameType == 'omaha' ?
                                                                                    <div>
                                                                                        {player.id != playerData.playerId ?
                                                                                            <div className='cards'>
                                                                                                {gameFinishedPlayersCards.playersCards && gameFinishedPlayersCards.playersCards.length > 0 ?
                                                                                                    <div>
                                                                                                        {gameFinishedPlayersCards.playersCards.map((playersCards, pkey) => {
                                                                                                            if (playersCards.playerId == player.id) {
                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        <div>
                                                                                                                            <div className='hiddenCard backFirstCard card-image playercard1 displayForcly' onLoad={() => CardClassName('playercard1')} style={displayBackCard} >
                                                                                                                                <div className="flip-card-inner">
                                                                                                                                    <div className="flip-card-front">
                                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                    </div>
                                                                                                                                    <div className="flip-card-back">
                                                                                                                                        <img alt="poker" src={'../Images/Card/' + playersCards.cards[0] + '.png'}></img>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className='hiddenCard backSecondCard card-image playercard2 displayForcly' onLoad={() => CardClassName('playercard2')} style={displayBackCard} >
                                                                                                                                <div className="flip-card-inner">
                                                                                                                                    <div className="flip-card-front">
                                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                    </div>
                                                                                                                                    <div className="flip-card-back">
                                                                                                                                        <img alt="poker" src={'../Images/Card/' + playersCards.cards[1] + '.png'}></img>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className='hiddenCard backThirdCard card-image playercard3 displayForcly' onLoad={() => CardClassName('playercard3')} style={displayBackCard} >
                                                                                                                                <div className="flip-card-inner">
                                                                                                                                    <div className="flip-card-front">
                                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                    </div>
                                                                                                                                    <div className="flip-card-back">
                                                                                                                                        <img alt="poker" src={'../Images/Card/' + playersCards.cards[2] + '.png'}></img>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className='hiddenCard backFourCard card-image playercard4 displayForcly' onLoad={() => CardClassName('playercard4')} style={displayBackCard} >
                                                                                                                                <div className="flip-card-inner">
                                                                                                                                    <div className="flip-card-front">
                                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                    </div>
                                                                                                                                    <div className="flip-card-back">
                                                                                                                                        <img alt="poker" src={'../Images/Card/' + playersCards.cards[3] + '.png'}></img>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            } else {
                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img>
                                                                                                                        <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img>
                                                                                                                        <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img>
                                                                                                                        <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                        })}
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div>
                                                                                                        <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img>
                                                                                                        <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img>
                                                                                                        <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img>
                                                                                                        <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img>
                                                                                                    </div>

                                                                                                }
                                                                                            </div>
                                                                                            :
                                                                                            <div>
                                                                                                {playerCards && playerCards.cards ?
                                                                                                    <div>
                                                                                                        {playerCards.flipAnimation == true ?
                                                                                                            <div>
                                                                                                                <div className='hiddenCard frontFirstCard card-image mycard1' onLoad={() => CardClassName('mycard1')} style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                        <div className="flip-card-back">
                                                                                                                            <img alt="poker" src={getCard(1)}></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className='hiddenCard frontSecondCard card-image mycard2' onLoad={() => CardClassName('mycard2')} style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                        <div className="flip-card-back">
                                                                                                                            <img alt="poker" src={getCard(2)}></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className='hiddenCard frontThirdCard card-image mycard3' onLoad={() => CardClassName('mycard3')} style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                        <div className="flip-card-back">
                                                                                                                            <img alt="poker" src={getCard(3)}></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className='hiddenCard frontFourCard card-image mycard4' onLoad={() => CardClassName('mycard4')} style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                        <div className="flip-card-back">
                                                                                                                            <img alt="poker" src={getCard(4)}></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div>
                                                                                                                <div className='hiddenCard frontFirstCard card-image' style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className='hiddenCard frontSecondCard card-image' style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className='hiddenCard frontThirdCard card-image' style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className='hiddenCard frontFourCard card-image' style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div>
                                                                                                        <div className='hiddenCard frontFirstCard card-image card-image-animation' style={displayCard} >
                                                                                                            <div className="flip-card-inner">
                                                                                                                <div className="flip-card-front">
                                                                                                                    <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='hiddenCard frontSecondCard card-image card-image-animation' style={displayCard} >
                                                                                                            <div className="flip-card-inner">
                                                                                                                <div className="flip-card-front">
                                                                                                                    <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='hiddenCard frontThirdCard card-image card-image-animation' style={displayCard} >
                                                                                                            <div className="flip-card-inner">
                                                                                                                <div className="flip-card-front">
                                                                                                                    <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='hiddenCard frontFourCard card-image card-image-animation' style={displayCard} >
                                                                                                            <div className="flip-card-inner">
                                                                                                                <div className="flip-card-front">
                                                                                                                    <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    :
                                                                                    <div>
                                                                                        {player.id != playerData.playerId ?
                                                                                            <div className='cards'>
                                                                                                {gameFinishedPlayersCards.playersCards && gameFinishedPlayersCards.playersCards.length > 0 ?
                                                                                                    <div>
                                                                                                        {gameFinishedPlayersCards.playersCards.map((playersCards, pkey) => {
                                                                                                            if (playersCards.playerId == player.id) {
                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        <div>
                                                                                                                            <div className='hiddenCard backSecondCard card-image playercard1 displayForcly' onLoad={() => CardClassName('playercard1')} style={displayBackCard} >
                                                                                                                                <div className="flip-card-inner">
                                                                                                                                    <div className="flip-card-front">
                                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                    </div>
                                                                                                                                    <div className="flip-card-back">
                                                                                                                                        <img alt="poker" src={'../Images/Card/' + playersCards.cards[0] + '.png'}></img>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className='hiddenCard backThirdCard card-image playercard2 displayForcly' onLoad={() => CardClassName('playercard2')} style={displayBackCard} >
                                                                                                                                <div className="flip-card-inner">
                                                                                                                                    <div className="flip-card-front">
                                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                    </div>
                                                                                                                                    <div className="flip-card-back">
                                                                                                                                        <img alt="poker" src={'../Images/Card/' + playersCards.cards[1] + '.png'}></img>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            } else {
                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img>
                                                                                                                        <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                        })}
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div>
                                                                                                        <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img>
                                                                                                        <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                            :

                                                                                            <div>
                                                                                                {playerCards && playerCards.cards ?
                                                                                                    <div>
                                                                                                        {playerCards.flipAnimation == true ?
                                                                                                            <div>
                                                                                                                <div className='hiddenCard frontSecondCard card-image mycard1' onLoad={() => CardClassName('mycard1')} style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                        <div className="flip-card-back">
                                                                                                                            <img alt="poker" src={getCard(1)}></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className='hiddenCard frontThirdCard card-image mycard2' onLoad={() => CardClassName('mycard2')} style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                        <div className="flip-card-back">
                                                                                                                            <img alt="poker" src={getCard(2)}></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div>
                                                                                                                <div className='hiddenCard frontSecondCard card-image' style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className='hiddenCard frontThirdCard card-image' style={displayCard} >
                                                                                                                    <div className="flip-card-inner">
                                                                                                                        <div className="flip-card-front">
                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div>
                                                                                                        <div className='hiddenCard frontSecondCard card-image card-image-animation' style={displayCard} >
                                                                                                            <div className="flip-card-inner">
                                                                                                                <div className="flip-card-front">
                                                                                                                    <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='hiddenCard frontThirdCard card-image card-image-animation' style={displayCard} >
                                                                                                            <div className="flip-card-inner">
                                                                                                                <div className="flip-card-front">
                                                                                                                    <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                : ''}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            } else if (checkSeatAvailable(seat)) {
                                                                return (
                                                                    <div>
                                                                        {gameStart == false ?
                                                                            <div key={index} className="empty-seat-wrapper" onClick={() => selectBuyIn(seat)} style={getCss(seat)}>
                                                                                <img alt="poker" src={EmptySeat} style={emptySeatImg}></img>
                                                                            </div> : ''
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div>
                                                        <div key={key} className='emptyTable'>
                                                            <div className="empty-seat-wrapper" onClick={() => selectBuyIn(seat)} style={getCss(seat)}>
                                                                <img alt="poker" src={EmptySeat} style={emptySeatImg}></img>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                    :
                                    <div className='emptyTable'>
                                        <div >
                                            {tableSeats.map((seat, key) => {
                                                if (playerInfo.length > 0) {
                                                    return (
                                                        <div key={key} className='emptyTable'>
                                                            {playerInfo.map((player, index) => {
                                                                if (checkPlayerSeat(player, seat)) {
                                                                    return (
                                                                        <div key={index} >
                                                                            {gameStart == true && player.BetAmount > 0 ?
                                                                                <div className="chipsImage" style={getChipsCss(seat)}>
                                                                                    <img alt="poker" src={'../Images/Icons/TableChips.png'} ></img>
                                                                                    <p>{player.BetAmount.toFixed(2)}</p>
                                                                                </div>
                                                                                : ''}
                                                                            {winnerSeatIndex == seat && winningAmount > 0 ?
                                                                                <div className="chipsImage" style={getChipsCss(seat)}>
                                                                                    <img alt="poker" src={'../Images/Icons/TableChips.png'} ></img>
                                                                                    <p>{winningAmount.toFixed(2)}</p>
                                                                                </div>
                                                                                : ''}
                                                                            <div style={animationCardDisplay}>
                                                                                <div className='animation-card-image' style={getCardAnimationCss(seat)}>
                                                                                    <img alt="poker" src={getCard(0)} style={displayBackCard}></img>
                                                                                </div>
                                                                            </div>
                                                                            <div className="fill-seat-wrapper" style={getFillCss(seat)}>
                                                                                {player.status == "Ideal" ? <div className="overlay" style={playerProfile}></div> :
                                                                                    player.idealPlayer == true ? <div className="overlay" style={playerProfile}></div> :
                                                                                        idealPlayer && idealPlayer.playerId == player.id ? <div className="overlay" style={playerProfile}></div> : ''}
                                                                                {/* {winnerSeatIndex == seat ? <img alt="poker" className='winnerImage' src={'../Images/WinnerRing.png'}></img> : ''} */}
                                                                                <img alt="poker" className="playerAvatarImg" src={playerProfilePhoto(player.avatar)} style={playerProfile}></img>
                                                                                {playerActionData.playerId == player.id && getActionName(playerActionData.action) != '' ?
                                                                                    <p className='playerAction'>{getActionName(playerActionData.action)}</p> : ''}
                                                                                {isPanelOpen == true && gamePlayData.playerId == player.id ?
                                                                                    <div className={gamePlayData.playerId == player.id ? 'playerDetails countdownContainer' : 'playerDetails'} style={{ transition: 'background-position ' + maxTimer + 's ease-out' }}>
                                                                                        {player.isDealer == true ? <img alt="poker" className={key > 1 && key < 5 ? 'dealerIconRight' : 'dealerIconLeft'} src={'../Images/dealer.png'}></img> : ''}
                                                                                        <p className='playerName' title={player.username}>{player.username.length > 10 ? player.username.slice(0, 10) + '...' : player.username}</p>
                                                                                        <p className='playeChips'>{player.BuyInAmount.toFixed(2)}</p>
                                                                                    </div>
                                                                                    :
                                                                                    <div className={winnerSeatIndex == seat ? 'playerDetails winner-profile' : 'playerDetails'} style={{ transition: 'background-position ' + maxTimer + 's ease-out' }}>
                                                                                        {player.isDealer == true ? <img alt="poker" className={key > 1 && key < 5 ? 'dealerIconRight' : ' dealerIconLeft'} src={'../Images/dealer.png'}></img> : ''}
                                                                                        <p className='playerName' title={player.username}>{player.username.length > 10 ? player.username.slice(0, 10) + '...' : player.username}</p>
                                                                                        <p className='playeChips'>{player.BuyInAmount.toFixed(2)}</p>
                                                                                    </div>
                                                                                }

                                                                                {gameStart == true ?
                                                                                    gameType == 'omaha' ?
                                                                                        <div>
                                                                                            {player.id != playerData.playerId ?
                                                                                                <div>
                                                                                                    {gameFinishedPlayersCards.playersCards && gameFinishedPlayersCards.playersCards.length > 0 ?
                                                                                                        <div>
                                                                                                            {gameFinishedPlayersCards.playersCards.map((playersCards, pkey) => {
                                                                                                                if (playersCards.playerId == player.id) {
                                                                                                                    return (
                                                                                                                        <div>
                                                                                                                            <div>
                                                                                                                                {displayCard1 == true ? <div className='hiddenCard backFirstCard card-image playercard1 displayForcly' onLoad={() => CardClassName('playercard1')} style={displayBackCard} >
                                                                                                                                    <div className="flip-card-inner">
                                                                                                                                        <div className="flip-card-front">
                                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                        </div>
                                                                                                                                        <div className="flip-card-back">
                                                                                                                                            <img alt="poker" src={'../Images/Card/' + playersCards.cards[0] + '.png'}></img>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div> : ""}
                                                                                                                                {displayCard2 == true ? <div className='hiddenCard backSecondCard card-image playercard2 displayForcly' onLoad={() => CardClassName('playercard2')} style={displayBackCard} >
                                                                                                                                    <div className="flip-card-inner">
                                                                                                                                        <div className="flip-card-front">
                                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                        </div>
                                                                                                                                        <div className="flip-card-back">
                                                                                                                                            <img alt="poker" src={'../Images/Card/' + playersCards.cards[1] + '.png'}></img>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div> : ""}
                                                                                                                                {displayCard3 == true ? <div className='hiddenCard backThirdCard card-image playercard3 displayForcly' onLoad={() => CardClassName('playercard3')} style={displayBackCard} >
                                                                                                                                    <div className="flip-card-inner">
                                                                                                                                        <div className="flip-card-front">
                                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                        </div>
                                                                                                                                        <div className="flip-card-back">
                                                                                                                                            <img alt="poker" src={'../Images/Card/' + playersCards.cards[2] + '.png'}></img>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div> : ""}
                                                                                                                                {displayCard4 == true ? <div className='hiddenCard backFourCard card-image playercard4 displayForcly' onLoad={() => CardClassName('playercard4')} style={displayBackCard} >
                                                                                                                                    <div className="flip-card-inner">
                                                                                                                                        <div className="flip-card-front">
                                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                        </div>
                                                                                                                                        <div className="flip-card-back">
                                                                                                                                            <img alt="poker" src={'../Images/Card/' + playersCards.cards[3] + '.png'}></img>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div> : ""}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                } else {
                                                                                                                    return (
                                                                                                                        <div>
                                                                                                                            {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                                            {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                                            {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                                            {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                }
                                                                                                            })}
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div>
                                                                                                            {displayCard1 == true ? <img alt="poker" className='backFirstCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                            {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                            {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                            {displayCard4 == true ? <img alt="poker" className='backFourCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                                :
                                                                                                <div>
                                                                                                    {playerCards && playerCards.cards ?
                                                                                                        <div>
                                                                                                            {playerCards.flipAnimation == true ?
                                                                                                                <div>
                                                                                                                    {displayCard1 == true ? <div className='hiddenCard frontFirstCard card-image mycard1' onLoad={() => CardClassName('mycard1')} style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                            <div className="flip-card-back">
                                                                                                                                <img alt="poker" src={getCard(1)}></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                    {displayCard2 == true ? <div className='hiddenCard frontSecondCard card-image mycard2' onLoad={() => CardClassName('mycard2')} style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                            <div className="flip-card-back">
                                                                                                                                <img alt="poker" src={getCard(2)}></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                    {displayCard3 == true ? <div className='hiddenCard frontThirdCard card-image mycard3' onLoad={() => CardClassName('mycard3')} style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                            <div className="flip-card-back">
                                                                                                                                <img alt="poker" src={getCard(3)}></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                    {displayCard4 == true ? <div className='hiddenCard frontFourCard card-image mycard4' onLoad={() => CardClassName('mycard4')} style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                            <div className="flip-card-back">
                                                                                                                                <img alt="poker" src={getCard(4)}></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                </div>
                                                                                                                :
                                                                                                                <div>
                                                                                                                    {displayCard1 == true ? <div className='hiddenCard frontFirstCard card-image' style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                    {displayCard2 == true ? <div className='hiddenCard frontSecondCard card-image' style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                    {displayCard3 == true ? <div className='hiddenCard frontThirdCard card-image' style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                    {displayCard4 == true ? <div className='hiddenCard frontFourCard card-image' style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div>
                                                                                                            {displayCard1 == true ? <div className='hiddenCard frontFirstCard card-image card-image-animation' style={displayCard} >
                                                                                                                <div className="flip-card-inner">
                                                                                                                    <div className="flip-card-front">
                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> : ""}
                                                                                                            {displayCard2 == true ? <div className='hiddenCard frontSecondCard card-image card-image-animation' style={displayCard} >
                                                                                                                <div className="flip-card-inner">
                                                                                                                    <div className="flip-card-front">
                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> : ""}
                                                                                                            {displayCard3 == true ? <div className='hiddenCard frontThirdCard card-image card-image-animation' style={displayCard} >
                                                                                                                <div className="flip-card-inner">
                                                                                                                    <div className="flip-card-front">
                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> : ""}
                                                                                                            {displayCard4 == true ? <div className='hiddenCard frontFourCard card-image card-image-animation' style={displayCard} >
                                                                                                                <div className="flip-card-inner">
                                                                                                                    <div className="flip-card-front">
                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> : ""}
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        :
                                                                                        <div>
                                                                                            {player.id != playerData.playerId ?
                                                                                                <div className='cards'>
                                                                                                    {gameFinishedPlayersCards.playersCards && gameFinishedPlayersCards.playersCards.length > 0 ?
                                                                                                        <div>
                                                                                                            {gameFinishedPlayersCards.playersCards.map((playersCards, pkey) => {
                                                                                                                if (playersCards.playerId == player.id) {
                                                                                                                    return (
                                                                                                                        <div>
                                                                                                                            <div>
                                                                                                                                {displayCard2 == true ? <div className='hiddenCard backSecondCard card-image playercard1 displayForcly' onLoad={() => CardClassName('playercard1')} style={displayBackCard} >
                                                                                                                                    <div className="flip-card-inner">
                                                                                                                                        <div className="flip-card-front">
                                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                        </div>
                                                                                                                                        <div className="flip-card-back">
                                                                                                                                            <img alt="poker" src={'../Images/Card/' + playersCards.cards[0] + '.png'}></img>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div> : ""}
                                                                                                                                {displayCard3 == true ? <div className='hiddenCard backThirdCard card-image playercard2 displayForcly' onLoad={() => CardClassName('playercard2')} style={displayBackCard} >
                                                                                                                                    <div className="flip-card-inner">
                                                                                                                                        <div className="flip-card-front">
                                                                                                                                            <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                                        </div>
                                                                                                                                        <div className="flip-card-back">
                                                                                                                                            <img alt="poker" src={'../Images/Card/' + playersCards.cards[1] + '.png'}></img>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div> : ""}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                } else {
                                                                                                                    return (
                                                                                                                        <div>
                                                                                                                            {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                                            {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                }
                                                                                                            })}
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div>
                                                                                                            {displayCard2 == true ? <img alt="poker" className='backSecondCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                            {displayCard3 == true ? <img alt="poker" className='backThirdCard card-image' src={getCard(0)} style={displayBackCard}></img> : ""}
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                                :
                                                                                                <div>
                                                                                                    {playerCards && playerCards.cards ?
                                                                                                        <div>
                                                                                                            {playerCards.flipAnimation == true ?
                                                                                                                <div>
                                                                                                                    {displayCard2 == true ? <div className='hiddenCard frontSecondCard card-image mycard1' onLoad={() => CardClassName('mycard1')} style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                            <div className="flip-card-back">
                                                                                                                                <img alt="poker" src={getCard(1)}></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                    {displayCard3 == true ? <div className='hiddenCard frontThirdCard card-image mycard2' onLoad={() => CardClassName('mycard2')} style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                            <div className="flip-card-back">
                                                                                                                                <img alt="poker" src={getCard(2)}></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                </div>
                                                                                                                :
                                                                                                                <div>
                                                                                                                    {displayCard2 == true ? <div className='hiddenCard frontSecondCard card-image' style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                    {displayCard3 == true ? <div className='hiddenCard frontThirdCard card-image' style={displayCard} >
                                                                                                                        <div className="flip-card-inner">
                                                                                                                            <div className="flip-card-front">
                                                                                                                                <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div> : ""}
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div>
                                                                                                            {displayCard2 == true ? <div className='hiddenCard frontSecondCard card-image card-image-animation' style={displayCard} >
                                                                                                                <div className="flip-card-inner">
                                                                                                                    <div className="flip-card-front">
                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> : ""}
                                                                                                            {displayCard3 == true ? <div className='hiddenCard frontThirdCard card-image card-image-animation' style={displayCard} >
                                                                                                                <div className="flip-card-inner">
                                                                                                                    <div className="flip-card-front">
                                                                                                                        <img alt="poker" src={'../Images/Icons/BackCard.png'} ></img>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> : ""}
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    : ''}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            })}
                                                        </div>
                                                    )
                                                } else {
                                                    return (<div key={key} className='emptyTable'>
                                                        <div className="empty-seat-wrapper winner-profile" onClick={() => selectBuyIn(seat)} style={getCss(seat)}>
                                                            <img alt="poker" src={EmptySeat} style={emptySeatImg} className='profile-image'></img>
                                                        </div>
                                                    </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                }
                                {gameStart ?
                                    isPanelOpen == true && gamePlayData.playerId == playerData.playerId ?
                                        <div>

                                            <div className='footerAction'>
                                                {gamePlayData.buttonAction.raise || gamePlayData.buttonAction.bet ?
                                                    <div className='footerSliderInput '>
                                                        <div className='footerSliderBox'>
                                                            <div className='raiseInput gap-3 d-flex'>
                                                                <span>{Number(raiseAmount).toFixed(2)}</span>
                                                                <input
                                                                    value={raiseAmount}
                                                                    step="0.1"
                                                                    min={minRaise}
                                                                    max={maxRaise}
                                                                    type="range"
                                                                    onChange={(e) => raiseAmountChange(e.target.value)}
                                                                    style={{ width: "80%" }}
                                                                ></input>
                                                            </div>
                                                            <button className='raiseBtn' onClick={(e) => raiseAmountChange(raiseAmount >= minRaise ? raiseAmount - 0.1 : 0)}>-</button>
                                                            <button className='raiseBtn' onClick={(e) => raiseAmountChange(raiseAmount <= maxRaise ? raiseAmount + 0.1 : 0)}>+</button>
                                                        </div>
                                                    </div>
                                                    : ''}
                                                <div className='footerActionBtn'>
                                                    <button className='footerBtn btnDanger' onClick={() => PlayerAction(6, false, 0)}>FOLD</button>
                                                    {gamePlayData.buttonAction.call ? <button className='footerBtn btnDefault' onClick={() => PlayerAction(4, false, 2)}>CALL <span>({gamePlayData.buttonAction.callAmount.toFixed(2)})</span></button> : ''}
                                                    {gamePlayData.buttonAction.check ? <button className='footerBtn btnDefault' onClick={() => PlayerAction(2, false, 0)}>CHECK</button> : ''}
                                                    {gamePlayData.buttonAction.raise ? <button className='footerBtn btnSuccess' onClick={() => PlayerAction(3, true, raiseAmount)}>RAISE <span>({Number(raiseAmount).toFixed(2)})</span></button> : ''}
                                                    {gamePlayData.buttonAction.bet ? <button className='footerBtn btnSuccess' onClick={() => PlayerAction(3, false, raiseAmount)}>BET <span>({Number(raiseAmount).toFixed(2)})</span></button> : ''}
                                                    {gamePlayData.buttonAction.allIn ? <button className='footerBtn btnSuccess' onClick={() => PlayerAction(8, true, gamePlayData.buttonAction.allInAmount)}>ALL IN <span>({gamePlayData.buttonAction.allInAmount.toFixed(2)})</span></button> : ''}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        isFooterPanel ?
                                            <div>
                                                <div className='footerActionCheckBoxLeft game-checkbox'>
                                                    <label className="form-control">
                                                        <input type="checkbox" name="checkbox" checked={sitOut.nextHand} onChange={(e) => sitOutCheckbox(e, 0)} />
                                                        Sit Out Next Hand
                                                    </label>
                                                    <label className="form-control">
                                                        <input type="checkbox" name="checkbox" checked={sitOut.bigBlind} onChange={(e) => sitOutCheckbox(e, 1)} />
                                                        Sit Out Next Big Blind
                                                    </label>
                                                </div>
                                                <div className='footerActionCheckBoxRight game-checkbox'>
                                                    <div className='d-flex'>
                                                        <label className="form-control">
                                                            <input type="checkbox" name="checkbox" checked={defaultAction == 'isFold' ? true : false} onChange={(e) => defaultActionSelection(e, 0)} />
                                                            Fold Any
                                                        </label>
                                                        <label className="form-control">
                                                            <input type="checkbox" name="checkbox" checked={defaultAction == 'isCheck' ? true : false} onChange={(e) => defaultActionSelection(e, 1)} />
                                                            Check/Fold
                                                        </label>
                                                        <label className="form-control">
                                                            <input type="checkbox" name="checkbox" checked={defaultAction == 'isCall' ? true : false} onChange={(e) => defaultActionSelection(e, 2)} />
                                                            Call Any
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    :
                                    ''
                                }
                                {playerInfo.length > 0 ? playerInfo.map((player, index) => {
                                    if (player.id == playerData.playerId)
                                        if (player.status == 'Ideal' && player.id == playerData.playerId) {
                                            return (
                                                <button className='iAmBackBtn gap-2' onClick={() => sendPlayerOnline()}>
                                                    I Am Back
                                                </button>
                                            )
                                        } else if (player.idealPlayer == true && player.id == playerData.playerId) {
                                            return (
                                                <button className='iAmBackBtn gap-2' onClick={() => sendPlayerOnline()}>
                                                    I Am Back
                                                </button>
                                            )
                                        } else if (idealPlayer && idealPlayer.playerId == playerData.playerId) {
                                            return (
                                                <button className='iAmBackBtn gap-2' onClick={() => sendPlayerOnline()}>
                                                    I Am Back
                                                </button>
                                            )
                                        }
                                }) : ''}
                            </div>

                        </div>
                        <div>

                        </div>
                    </div>
                    {isLoader ?
                        <div className='loader'>
                            <img alt="poker" src={'../Images/LoginLogo.png'} className='gameLogo'></img>
                            <div className='loading'>
                                <p>Loading...</p>
                            </div>
                            <div className="progress-6"></div>
                        </div>
                        : ''
                    }

                    {tableData ?
                        <Modal
                            isOpen={isRoomModalOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                            ariaHideApp={false}
                        >
                            <div className="buyInModal gameModel">
                                <h2>Available Chips : {tableData.playerChips.toFixed(2)}</h2>
                                <hr></hr>
                                <div className='chipsDetails mb-4'>
                                    <div className='d-flex gap-3'>
                                        <div className='minBuyIn p-col-3'>
                                            <p>Min</p>
                                            <span>{minBuyIn.toFixed(2)}</span>
                                        </div>
                                        <div className='chipsBuyIn p-col-6'>
                                            <input
                                                value={start}
                                                type="number"
                                                onChange={(e) => setStart(e.target.value)}
                                                min={minBuyIn}
                                                max={maxBuyIn}
                                                readOnly
                                            ></input>
                                        </div>
                                        <div className='maxBuyIn p-col-3'>
                                            <p>Max</p>
                                            <span>{maxBuyIn.toFixed(2)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='sliderInput center'>
                                    <input
                                        value={start}
                                        step="0.1"
                                        min={minBuyIn}
                                        max={maxBuyIn}
                                        type="range"
                                        onChange={(e) => onStartChange(e.target.value)}
                                        style={{ width: "80%" }}
                                    ></input>
                                </div>
                                {buyIn ?
                                    <button className=' btn selectBuyInBtn' onClick={() => sitOnTable(1)}>Add Chips</button>
                                    :
                                    <button className=' btn selectBuyInBtn' onClick={() => sitOnTable(0)}>Select</button>
                                }

                            </div>
                        </Modal> : ''}
                    <Modal
                        isOpen={isLogoutModalOpen}
                        onRequestClose={closeModal}
                        style={customStylesLogout}
                        contentLabel="Example Modal"
                        ariaHideApp={false}
                    >
                        <div className='gameModel'>
                            <div className='modalBody'>
                                <center>
                                    <h2 className='color-1'>Warning!</h2>
                                    <h4 className='color-1 mt-4'>Are you sure you want leave this game?</h4>
                                    <div className='avtarBtnSection'>
                                        <button className='selectBuyInBtn' onClick={() => leaveGame()}>Yes</button>
                                        <button className='selectBuyInBtn' onClick={() => closeModal()}>No</button>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={isBuyHourModalOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                        ariaHideApp={false}
                    >
                        <div className="buyInModal gameModel">
                            <h2>Buy Hour </h2>
                            <hr></hr>
                            <div className='chipsDetails mb-4'>
                                <div className='d-flex gap-3'>
                                    <select className='form-control hour-buy-dropdown' value={selectedHour} onChange={(e) => setSelectedHour(e.target.value)}>
                                        <option>Select Hour</option>
                                        {hoursOption.map((hour, index) => {
                                            return (
                                                <option key={index} value={hour.value}>{hour.name}</option>
                                            );
                                        })
                                        };
                                    </select>
                                </div>
                            </div>
                            <div className='avtarBtnSection'>
                                <button className='selectBuyInBtn' onClick={() => buyPackage(0)}>Buy</button>
                                <button className='selectBuyInBtn' onClick={() => buyPackage(1)}>Cancle</button>
                            </div>
                        </div>
                    </Modal>
                </div>
            }
        </div>
    );
}

export default GameScreen

