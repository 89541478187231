import React from 'react'
import './index.css'
import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import socket from '../../socket';
import socketConfig from '../../Socket/config.js'
import { useCookies } from 'react-cookie';
import Modal from 'react-modal';

const CashGameTable = (props) => {
  const [cookies, setCookie] = useCookies(['roomId', 'roomType', 'gameType', 'gameFormat']);
  const [gameTableData, setGameTable] = useState([])
  const [interval, setIntervalId] = useState('')
  const [stackList, setStackList] = useState([])
  const [dataLimit, setDataLimit] = useState('all')
  const [tableTypes, setTableTypes] = useState('all')
  const [playerLimit, setPlayerLimit] = useState('all')
  const [stack, setStack] = useState('all')
  const [isMobile, setIsMobile] = useState(props.mobileView)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const navigate = useNavigate();
  const playerData = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    socket.emit('GetStacks', [], (res) => {
      setStackList(res.result);
    });

    let data = {
      pokerGameType: tableTypes,
      game: dataLimit,
      stacks: stack,
      maxPlayer: playerLimit,
      playerId: playerData?.playerId,
      appVersion: '2.0',
      device_id: '4w85u349865neiuyjn38572h385uhwnuit4gn',
    }

    socket.emit('SearchLobby', data, (res) => {
      if (res.result) {
        setGameTable(res.result);
      }
    });

    const intervalId = setInterval(() => {
      socket.emit('SearchLobby', data, (res) => {
        if (res.result) {
          setGameTable(res.result);
        }
      });
    }, 5000);

    setIntervalId(intervalId);

    return () => clearInterval(intervalId)

  }, [dataLimit, tableTypes, stack, playerLimit])

  const refreshData = (() => {
    props.refreshData();
  });
  const filterDataTable = (value, type) => {
    clearInterval(interval);
    if (type == 'limit') {
      setDataLimit(value);
    }
    if (type == 'table') {
      setTableTypes(value);
    }
    if (type == 'player') {
      setPlayerLimit(value);
    }
    if (type == 'stack') {
      setStack(value);
    }
  }

  const joinTable = (data) => {
    const playerData = JSON.parse(localStorage.getItem('user'));
    if (playerData.is_able) {
      setCookie('roomId', data.roomId, { path: '/' });
      setCookie('roomType', data.namespaceString, { path: '/' });
      setCookie('gameType', data.pokerGameType, { path: '/' });
      setCookie('gameFormat', 'cash_game', { path: '/' });
      // if (isMobile) {
      //   navigate('/game')
      // } else {
      //   const aspectRatio = 16 / 9; // 4:3 aspect ratio
      //   const width = 1080; // initial width
      //   const height = Math.round(width / aspectRatio); // calculate height based on aspect ratio
      //   const windowFeatures = `resizable=no,width=${width},height=${height}`;
      //   const newWindow = window.open(process.env.REACT_APP_URL + "#/game", "_blank", windowFeatures);
      //   const resizeWindow = () => {
      //     const currentWidth = newWindow.outerWidth;
      //     const currentHeight = newWindow.outerHeight;
      //     const expectedHeight = Math.round(currentWidth / aspectRatio);
      //     if (currentHeight !== expectedHeight) {
      //       newWindow.resizeTo(currentWidth, expectedHeight);
      //     }
      //   };
      //   newWindow.addEventListener("resize", resizeWindow);
      // }
      navigate('/game')
    } else {

    }

    // props.isLoader(true);
    // navigate('/game')
    // localStorage.removeItem("tableData");
    // localStorage.removeItem("roomId");
    // localStorage.removeItem("roomType");
    // localStorage.removeItem("gameFormat");
    // localStorage.setItem('roomId', JSON.stringify(data.roomId));
    // localStorage.setItem('roomType', JSON.stringify(data.namespaceString));
    // localStorage.setItem('gameType', JSON.stringify(data.pokerGameType));
    // localStorage.setItem('gameFormat', JSON.stringify('cash_game'));
    // props.isLoader(false);

  }

  const tableInfo = (room) => {
    props.setRoomDetails({
      pokerGameType: tableTypes,
      roomId: room.roomId,
      type: room.namespaceString,
    })
  }

  const closeModal = () => {
    setIsPopupOpen(false);
  }


  let customStylesModal = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#242234',
      width: '40%',
      height: '50%',
      border: '1px solid #8025F3'
    },
  };

  if (isMobile) {
    customStylesModal = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#242234',
        width: '60%',
        height: '30%',
        border: '1px solid #8025F3'
      },
    };
  }

  return (
    <div>
      {isMobile ?
        <div className="mt-2">
          <div className='tableFilterHead d-flex gap-2'>
            <div className='p-col-3'>
              <div className='mb-p-hd-fill-box'>
                <p>Limit</p>
                <select onChange={(e) => filterDataTable(e.target.value, 'limit')}>
                  <option value="all">All</option>
                  <option value="limit">Limit</option>
                  <option value="no_limit">No Limit</option>
                  <option value="pot_limit">Pot Limit</option>
                </select>
              </div>
            </div>
            <div className='p-col-3'>
              <div className='mb-p-hd-fill-box'>
                <p>Table</p>
                <select onChange={(e) => filterDataTable(e.target.value, 'table')}>
                  <option value="all">All</option>
                  <option value="texas">Texas</option>
                  <option value="omaha">Omaha</option>
                  {/* <option value="PLO5">PLO5</option> */}
                </select>
              </div>
            </div>
            <div className='p-col-3'>
              <div className='mb-p-hd-fill-box'>
                <p>Player</p>
                <select onChange={(e) => filterDataTable(e.target.value, 'player')}>
                  <option value="all">All</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                </select>
              </div>
            </div>
            <div className='p-col-3'>
              <div className='mb-p-hd-fill-box border-none'>
                <p>Stakes</p>
                <select onChange={(e) => filterDataTable(e.target.value, 'stack')}>
                  <option value="all">All</option>
                  {stackList.length > 0 ? stackList.map((stack, index) => {
                    return (
                      <option key={index} value={stack.minStack + '/' + stack.maxStack}>{stack.minStack}/{stack.maxStack}</option>
                    );
                  }) : ''
                  };
                </select>
              </div>
            </div>
          </div>
          <div className='mobileTableList tableFixHead mt-2'>
            <div className='p-lb-table table-borderless'>
              {gameTableData.length > 0 ? gameTableData.map((cashGame, index) => {
                return (
                  <div className='tableListBox'>
                    <div className='d-flex gap-2'>
                      <div className='p-col-6'>
                        <p className='gameTableName m-0'>{cashGame.roomName}</p>
                        <p className='gameStakes m-0'>{cashGame.smallBlind}/{cashGame.bigBlind}</p>
                      </div>
                      <div className='p-col-3'>
                        <p className='gameBuyIn m-0'>{cashGame.minBuyIn}/{cashGame.maxBuyIn}</p>
                      </div>
                      <div className='p-col-2'>
                        <p className='gamePlayer m-0'><img src={'../Images/users.png'} width={'30%'}></img> {cashGame.playerCount}/{cashGame.maxPlayers}</p>
                        <p className='gameType m-0' style={{ textTransform: "capitalize" }}>{cashGame.pokerGameType}</p>
                      </div>
                      <div className='p-col-2 px-1'>
                        <div className='wrapper-div'>
                          {playerData.is_able == true ?
                            <div>
                              {cashGame.pokerGameType == 'texas' ?
                                <button className='btn p-tb-join-btn' onClick={() => joinTable(cashGame)}>Join</button>
                                :
                                <button className='btn p-tb-join-btn' onClick={() => joinTable(cashGame)}>Join</button>
                              }
                            </div>
                            :
                            <button className='btn p-tb-join-btn' onClick={() => setIsPopupOpen(true)}>Join</button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }) :
                <div className='tableListBox  mt-2'>
                  <div className='text-center p-2'>
                    <p className='gameStakes m-1'>No Game Found</p>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        :
        <div className="sectionBox mt-2">
          <div className='tableFilterHead d-flex gap-2'>
            <div className='p-col-3'>
              <div className='p-hd-fill-box'>
                <p>Limit :</p>
                <select onChange={(e) => filterDataTable(e.target.value, 'limit')}>
                  <option value="all">All</option>
                  <option value="limit">Limit</option>
                  <option value="no_limit">No Limit</option>
                  <option value="pot_limit">Pot Limit</option>
                </select>
              </div>
            </div>
            <div className='p-col-3'>
              <div className='p-hd-fill-box'>
                <p>Table :</p>
                <select onChange={(e) => filterDataTable(e.target.value, 'table')}>
                  <option value="all">All</option>
                  <option value="texas">Texas</option>
                  <option value="omaha">Omaha</option>
                  {/* <option value="PLO5">PLO5</option> */}
                </select>
              </div>
            </div>
            <div className='p-col-3'>
              <div className='p-hd-fill-box'>
                <p>Player :</p>
                <select onChange={(e) => filterDataTable(e.target.value, 'player')}>
                  <option value="all">All</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                </select>
              </div>
            </div>
            <div className='p-col-3'>
              <div className='p-hd-fill-box border-none'>
                <p>Stakes :</p>
                <select onChange={(e) => filterDataTable(e.target.value, 'stack')}>
                  <option value="all">All</option>
                  {stackList.length > 0 ? stackList.map((stack, index) => {
                    return (
                      <option key={index} value={stack.minStack + '/' + stack.maxStack}>{stack.minStack}/{stack.maxStack}</option>
                    );
                  }) : ''
                  };
                </select>
              </div>
            </div>
          </div>
          <div className="tableFixHead">
            <table className='table p-lb-table table-borderless'>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Table Name</th>
                  <th>Game</th>
                  {/* <th>Stakes</th> */}
                  <th>Min BuyIn</th>
                  <th>Max BuyIn</th>
                  <th>Players</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {gameTableData.length > 0 ? gameTableData.map((cashGame, index) => {
                  return (
                    <tr key={index} onClick={(e) => { tableInfo(cashGame) }}>
                      <td>{cashGame.type}</td>
                      <td>{cashGame.roomName}
                        {/* - ({cashGame.smallBlind}/{cashGame.bigBlind}) */}
                      </td>
                      <td style={{ textTransform: "capitalize" }}>{cashGame.pokerGameType}</td>
                      {/* <td></td> */}
                      <td>{cashGame.minBuyIn}</td>
                      <td>{cashGame.maxBuyIn}</td>
                      <td>{cashGame.playerCount}/{cashGame.maxPlayers}</td>
                      <td className='join-td'>
                        {playerData.is_able == true ?
                          <div>
                            {cashGame.pokerGameType == 'texas' ?
                              <button className='btn p-tb-join-btn' onClick={() => joinTable(cashGame)}>Join</button>
                              :
                              <button className='btn p-tb-join-btn' onClick={() => joinTable(cashGame)}>Join</button>
                            }
                          </div>
                          :
                          <button className='btn p-tb-join-btn' onClick={() => setIsPopupOpen(true)}>Join</button>
                        }
                      </td>
                    </tr>
                  );
                }) : <tr>
                  <td colSpan="7">No Game Found</td>
                </tr>}
              </tbody>
            </table>
          </div>
        </div>
      }
      <Modal
        isOpen={isPopupOpen}
        onRequestClose={closeModal}
        style={customStylesModal}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className={isMobile ? "gameModel mobileView" : 'gameModel'}>
          <div className='modalBody'>
            <center>
              <h2 className='color-1'>Warning!</h2>
              <h4 className='color-1 mt-4'>Sorry, you have insufficient hours balance to play game. Please buy hour to continue.</h4>
              <div className='avtarBtnSection'>
                <button className='selectBuyInBtn' onClick={() => closeModal()}>Buy Hour</button>
                <button className='selectBuyInBtn' onClick={() => closeModal()}>Close</button>
              </div>
            </center>
          </div>
        </div>
      </Modal>
    </div>

  );
}

export default CashGameTable

