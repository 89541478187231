
import React from 'react'
import './index.css'
import { useState, useEffect } from 'react'
import socket from '../../socket.js'
import { useNavigate } from "react-router-dom";
import InfoTable from '../InfoTable';

const SitNGoGameTable = (props) => {
  const navigate = useNavigate();
  const [gameTableData, setGameTable] = useState([])
  const [playerDataTable, setPlayerDataTable] = useState([])
  const [tableDataTable, setTableDataTable] = useState([])
  const [payoutDataTable, setPayoutDataTable] = useState([])
  const [blindDataTable, setBlindDataTable] = useState([])
  const [stackList, setStackList] = useState([])
  const [dataLimit, setDataLimit] = useState('all')
  const [tableTypes, setTableTypes] = useState('all')
  const [playerLimit, setPlayerLimit] = useState('all')
  const [stack, setStack] = useState('all')
  const [tableInfo, setTableInfo] = useState(false)
  const [roomId, setRoomId] = useState('')
  const [interval, setIntervalId] = useState('')
  const [isMobile, setIsMobile] = useState(props.mobileView)
  const [tournamentInfo, setTournamentInfo] = useState({})
  const [isActive, setIsActive] = useState({
    table: 'tables'
  })
  const playerData = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    socket.emit('GetStacks', [], (res) => {
      setStackList(res.result);
    });

    let data = {
      pokerGameType: tableTypes,
      game: dataLimit,
      stacks: stack,
      maxPlayer: playerLimit,
      playerId: playerData?.playerId,
      appVersion: '2.0',
      device_id: '4w85u349865neiuyjn38572h385uhwnuit4gn',
    }

    if (tableInfo == false) {
      socket.emit('SearchSngLobby', data, (res) => {
        if (res.result) {
          console.log(res.result);
          setGameTable(res.result);
        }
      });
    }
    const intervalId = setInterval(() => {
      if (tableInfo == false) {
        socket.emit('SearchSngLobby', data, (res) => {
          if (res.result) {
            setGameTable(res.result);
          }
        });
      }
    }, 5000);

    setIntervalId(intervalId);

    return () => clearInterval(intervalId)

  }, [dataLimit, tableTypes, stack, playerLimit, tableInfo, roomId])


  const filterDataTable = (value, type) => {
    clearInterval(interval);
    if (type == 'limit') {
      setDataLimit(value);
    }
    if (type == 'table') {
      setTableTypes(value);
    }
    if (type == 'player') {
      setPlayerLimit(value);
    }
    if (type == 'stack') {
      setStack(value);
    }
  }

  const sngTournmentInfo = (type, roomId) => {
    if (isMobile) {
      setIsActive({
        table: 'details',
      });
    }
    setTournamentInfo({
      pokerGameType: type,
      roomId: roomId,
      type: 'sng',
    })
    setTableInfo(true)
    sngTournmentSocketData(type, roomId)
    props.setRoomDetails({
      pokerGameType: type,
      roomId: roomId,
      type: 'sng',
    })
  }

  const sngTournmentSocketData = (type, roomId) => {
    let data = {
      pokerGameType: type,
      roomId: roomId,
    }
    socket.emit('SngTournamentPlayers', data, (res) => {
      if (res.result) {
        setPlayerDataTable(res.result)
      }
    });
    socket.emit('SngTournamentTables', data, (res) => {
      if (res.result) {
        setTableDataTable(res.result)
      }
    });
    socket.emit('SngTournamentPayout', data, (res) => {
      if (res.result) {
        console.log(res.result);
        setPayoutDataTable(res.result)
      }
    });
    socket.emit('SngTournamentBlinds', data, (res) => {
      if (res.result) {
        setBlindDataTable(res.result)
      }
    });
  }

  const hideShowDiv = (e) => {
    setIsActive({
      table: e.target.id,
    });
    sngTournmentSocketData(tournamentInfo.type, tournamentInfo.roomId, e.target.id)
  }

  const joinTable = (table) => {
    localStorage.removeItem("tableData");
    localStorage.removeItem("roomId");
    localStorage.removeItem("roomId");
    localStorage.removeItem("roomType");
    localStorage.removeItem("roomType");
    localStorage.removeItem("gameFormat");
    localStorage.setItem('roomId', JSON.stringify(table.roomId));
    localStorage.setItem('roomType', JSON.stringify(table.namespaceString));
    localStorage.setItem('gameType', JSON.stringify(table.pokerGameType));
    localStorage.setItem('gameFormat', JSON.stringify(table.pokerGameFormat));
    props.isLoader(false);
    const aspectRatio = 16 / 9; // 4:3 aspect ratio
    const width = 1080; // initial width
    const height = Math.round(width / aspectRatio); // calculate height based on aspect ratio
    const windowFeatures = `resizable=no,width=${width},height=${height}`;
    const newWindow = window.open(process.env.REACT_APP_URL + "#/game", "_blank", windowFeatures);
    const resizeWindow = () => {
      const currentWidth = newWindow.outerWidth;
      const currentHeight = newWindow.outerHeight;
      const expectedHeight = Math.round(currentWidth / aspectRatio);
      if (currentHeight !== expectedHeight) {
        newWindow.resizeTo(currentWidth, expectedHeight);
      }
    };
    newWindow.addEventListener("resize", resizeWindow);
  }

  return (
    <div>
      {isMobile ?
        <div className="mt-2">
          {!tableInfo ?
            <div>
              <div className='tableFilterHead d-flex gap-2'>
                <div className='p-col-3'>
                  <div className='mb-p-hd-fill-box'>
                    <p>Limit</p>
                    <select onChange={(e) => filterDataTable(e.target.value, 'limit')}>
                      <option value="all">All</option>
                      <option value="limit">Limit</option>
                      <option value="no_limit">No Limit</option>
                      <option value="pot_limit">Pot Limit</option>
                    </select>
                  </div>
                </div>
                <div className='p-col-3'>
                  <div className='mb-p-hd-fill-box'>
                    <p>Table</p>
                    <select onChange={(e) => filterDataTable(e.target.value, 'table')}>
                      <option value="all">All</option>
                      <option value="texas">Texas</option>
                      <option value="omaha">Omaha</option>
                      {/* <option value="PLO5">PLO5</option> */}
                    </select>
                  </div>
                </div>
                <div className='p-col-3'>
                  <div className='mb-p-hd-fill-box'>
                    <p>Player</p>
                    <select onChange={(e) => filterDataTable(e.target.value, 'player')}>
                      <option value="all">All</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                    </select>
                  </div>
                </div>
                <div className='p-col-3'>
                  <div className='mb-p-hd-fill-box border-none'>
                    <p>Stack</p>
                    <select onChange={(e) => filterDataTable(e.target.value, 'stack')}>
                      <option value="all">All</option>
                      {stackList.length > 0 ? stackList.map((stack, index) => {
                        return (
                          <option key={index} value={stack.minStack + '/' + stack.maxStack}>{stack.minStack}/{stack.maxStack}</option>
                        );
                      }) : ''
                      };
                    </select>
                  </div>
                </div>
              </div>
              <div className='mobileTableList tableFixHead mt-2'>
                {gameTableData.length > 0 ? gameTableData.map((cashGame, index) => {
                  return (
                    <div className='tableListBox  mb-2'>
                      <div className='d-flex gap-2' onClick={(e) => { sngTournmentInfo(cashGame.pokerGameType, cashGame.id) }}>
                        <div className='p-col-6'>
                          <p className='gameTableName m-0'>{cashGame.name}</p>
                          <p className='gameTableName m-0'>{cashGame.type}</p>
                        </div>
                        <div className='p-col-3'>
                          <p className='gameDateTime m-0'>{cashGame.blinds}</p>
                          <p className='gameBuyIn m-0'>{cashGame.buyIn}</p>
                        </div>
                        <div className='p-col-2'>
                          <p className='gamePlayer m-0'><img src={'../Images/users.png'} width={'30%'}></img> {cashGame.seat}</p>
                          <p className='gameType m-0' style={{ textTransform: "capitalize" }}>{cashGame.pokerGameType}</p>
                        </div>
                        <div className='p-col-2 px-1'>
                          <p className='gameType m-0'>{cashGame.status}</p>
                        </div>
                      </div>
                    </div>
                  );
                }) :
                  <div className='tableListBox  mt-2'>
                    <div className='text-center p-2'>
                      <p className='gameStakes m-1'>No Game Found</p>
                    </div>
                  </div>
                }
              </div>
            </div>
            :
            <div className='sectionBox'>
              <div className='main-heading'>
                <h2 >Tournament Details</h2>
                <img src={'../Images/close.png'} onClick={() => setTableInfo(false)}></img>
              </div>
              <div className="tableFixHead">
                <div className='d-flex details-sidebar gap-2'>
                  <button className={isActive.table === 'details' ? `btn p-hd-fill-inner-btn  active` : 'btn p-hd-fill-inner-btn '} id="details"
                    onClick={(e) => {
                      hideShowDiv(e)
                    }}>Details</button>
                  <button className={isActive.table === 'tables' ? `btn p-hd-fill-inner-btn active` : 'btn p-hd-fill-inner-btn '} id="tables"
                    onClick={(e) => {
                      hideShowDiv(e)
                    }}>Tables</button>
                  <button className={isActive.table === 'players' ? `btn p-hd-fill-inner-btn active` : 'btn p-hd-fill-inner-btn '} id="players"
                    onClick={(e) => {
                      hideShowDiv(e)
                    }}>Players</button>
                  <button className={isActive.table === 'payouts' ? `btn p-hd-fill-inner-btn active` : 'btn p-hd-fill-inner-btn '} id="payouts"
                    onClick={(e) => {
                      hideShowDiv(e)
                    }}>Payouts</button>
                  <button className={isActive.table === 'blinds' ? `btn p-hd-fill-inner-btn active` : 'btn p-hd-fill-inner-btn '} id="blinds"
                    onClick={(e) => {
                      hideShowDiv(e)
                    }}>Blinds</button>
                </div>
                <div>
                  <div className={isActive.table === 'details' ? `details` : 'details d-none'}>
                    <InfoTable mobileView={isMobile} roomDetails={tournamentInfo}></InfoTable>
                  </div>
                  <div className={isActive.table === 'players' ? `players` : 'players d-none'}>
                    <table className='table p-lb-table table-borderless'>
                      <thead>
                        <tr>
                          <th>Rank</th>
                          <th>Player</th>
                          <th>Winnings</th>
                        </tr>
                      </thead>
                      <tbody>
                        {playerDataTable.length > 0 ? playerDataTable.map((player, index) => {
                          return (
                            <tr key={index}>
                              <td>{player.rank}</td>
                              <td>{player.name}</td>
                              <td>{player.winning}</td>
                            </tr>
                          );
                        }) : <tr>
                          <td colSpan="3">No Players Found</td>
                        </tr>}
                      </tbody>
                    </table>
                  </div>
                  <div className={isActive.table === 'tables' ? `tables` : 'tables d-none'}>
                    <table className='table p-lb-table table-borderless'>
                      <thead>
                        <tr>
                          <th>Table</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableDataTable && tableDataTable.name != '' ?
                          <tr>
                            <td>{tableDataTable.name}</td>
                            <td className='join-td'>
                              <button className='btn p-tb-open-table-btn' onClick={() => joinTable(tableDataTable)}>Open Table</button>
                            </td>
                          </tr>
                          : <tr>
                            <td colSpan="2">No Tables Found</td>
                          </tr>}
                      </tbody>
                    </table>
                  </div>
                  <div className={isActive.table === 'payouts' ? `payouts` : 'payouts d-none'}>
                    <table className='table p-lb-table table-borderless'>
                      <thead>
                        <tr>
                          <th>Position</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {payoutDataTable.length > 0 ? payoutDataTable.map((payout, index) => {
                          return (
                            <tr key={index}>
                              <td>{payout.position}</td>
                              <td>{payout.amount}</td>
                            </tr>
                          );
                        }) : <tr>
                          <td colSpan="2">No Payouts Found</td>
                        </tr>}
                      </tbody>
                    </table>
                  </div>
                  <div className={isActive.table === 'blinds' ? `blinds` : 'blinds d-none'}>
                    <table className='table p-lb-table table-borderless'>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Blinds</th>
                          <th>Duration</th>
                        </tr>
                      </thead>
                      <tbody>
                        {blindDataTable.length > 0 ? blindDataTable.map((blind, index) => {
                          return (
                            <tr key={index}>
                              <td>{blind.index}</td>
                              <td>{blind.blinds}</td>
                              <td>{blind.duaration}</td>
                            </tr>
                          );
                        }) : <tr>
                          <td colSpan="3">No Blinds Found</td>
                        </tr>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        :
        <div className="sectionBox mt-2">
          {!tableInfo ?
            <div>
              <div className='tableFilterHead d-flex gap-2'>
                <div className='p-col-3'>
                  <div className='p-hd-fill-box'>
                    <p>Limit :</p>
                    <select onChange={(e) => filterDataTable(e.target.value, 'limit')}>
                      <option value="all">All</option>
                      <option value="limit">Limit</option>
                      <option value="no_limit">No Limit</option>
                      <option value="pot_limit">Pot Limit</option>
                    </select>
                  </div>
                </div>
                <div className='p-col-3'>
                  <div className='p-hd-fill-box'>
                    <p>Table :</p>
                    <select onChange={(e) => filterDataTable(e.target.value, 'table')}>
                      <option value="all">All</option>
                      <option value="texas">Texas</option>
                      <option value="omaha">Omaha</option>
                      {/* <option value="PLO5">PLO5</option> */}
                    </select>
                  </div>
                </div>
                <div className='p-col-3'>
                  <div className='p-hd-fill-box'>
                    <p>Player :</p>
                    <select onChange={(e) => filterDataTable(e.target.value, 'player')}>
                      <option value="all">All</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                    </select>
                  </div>
                </div>
                <div className='p-col-3'>
                  <div className='p-hd-fill-box border-none'>
                    <p>Stakes :</p>
                    <select onChange={(e) => filterDataTable(e.target.value, 'stack')}>
                      <option value="all">All</option>
                      {stackList.length > 0 ? stackList.map((stack, index) => {
                        return (
                          <option key={index} value={stack.minStack + '/' + stack.maxStack}>{stack.minStack}/{stack.maxStack}</option>
                        );
                      }) : ''
                      };
                    </select>
                  </div>
                </div>
              </div>
              <div className="tableFixHead">

                <table className='table p-lb-table table-borderless'>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Game</th>
                      <th>Name</th>
                      <th>Seat</th>
                      <th>BuyIn</th>
                      <th>Blinds</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {gameTableData.length > 0 ? gameTableData.map((cashGame, index) => {
                      return (
                        <tr key={index} onClick={(e) => { sngTournmentInfo(cashGame.pokerGameType, cashGame.id) }}>
                          <td>{cashGame.type}</td>
                          <td style={{ textTransform: "capitalize" }}>{cashGame.pokerGameType}</td>
                          <td>{cashGame.name}</td>
                          <td>{cashGame.seat}</td>
                          <td>{cashGame.buyIn}</td>
                          <td>{cashGame.blinds}</td>
                          <td>{cashGame.status}</td>
                        </tr>
                      );
                    }) : <tr>
                      <td colSpan="7">No Game Found</td>
                    </tr>}
                  </tbody>
                </table>

              </div>
            </div>
            :
            <div>
              <div className='main-heading'>
                <h2 >Tournament Details</h2>
                <img src={'../Images/close.png'} onClick={() => setTableInfo(false)}></img>
              </div>
              <div className="tableFixHead">
                <div className='d-flex min-h-100'>
                  <div className='p-col-3 details-sidebar'>
                    <button className={isActive.table === 'tables' ? `btn p-hd-fill-inner-btn active` : 'btn p-hd-fill-inner-btn '} id="tables"
                      onClick={(e) => {
                        hideShowDiv(e)
                      }}>Tables</button>
                    <button className={isActive.table === 'players' ? `btn p-hd-fill-inner-btn mt-2 active` : 'btn p-hd-fill-inner-btn mt-2 '} id="players"
                      onClick={(e) => {
                        hideShowDiv(e)
                      }}>Players</button>
                    <button className={isActive.table === 'payouts' ? `btn p-hd-fill-inner-btn mt-2 active` : 'btn p-hd-fill-inner-btn mt-2 '} id="payouts"
                      onClick={(e) => {
                        hideShowDiv(e)
                      }}>Payouts</button>
                    <button className={isActive.table === 'blinds' ? `btn p-hd-fill-inner-btn mt-2 active` : 'btn p-hd-fill-inner-btn mt-2 '} id="blinds"
                      onClick={(e) => {
                        hideShowDiv(e)
                      }}>Blinds</button>
                  </div>
                  <div className='p-col-9'>
                    <div className=''>
                      <div className={isActive.table === 'players' ? `players` : 'players d-none'}>
                        <table className='table p-lb-table table-borderless'>
                          <thead>
                            <tr>
                              <th>Rank</th>
                              <th>Player</th>
                              <th>Winnings</th>
                            </tr>
                          </thead>
                          <tbody>
                            {playerDataTable.length > 0 ? playerDataTable.map((player, index) => {
                              return (
                                <tr key={index}>
                                  <td>{player.rank}</td>
                                  <td>{player.name}</td>
                                  <td>{player.winning}</td>
                                </tr>
                              );
                            }) : <tr>
                              <td colSpan="3">No Players Found</td>
                            </tr>}
                          </tbody>
                        </table>
                      </div>
                      <div className={isActive.table === 'tables' ? `tables` : 'tables d-none'}>
                        <table className='table p-lb-table table-borderless'>
                          <thead>
                            <tr>
                              <th>Table</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableDataTable && tableDataTable.name != '' ?
                              <tr>
                                <td>{tableDataTable.name}</td>
                                <td className='join-td'>
                                  <button className='btn p-tb-open-table-btn' onClick={() => joinTable(tableDataTable)}>Open Table</button>
                                </td>
                              </tr>
                              : <tr>
                                <td colSpan="2">No Tables Found</td>
                              </tr>}
                          </tbody>
                        </table>
                      </div>
                      <div className={isActive.table === 'payouts' ? `payouts` : 'payouts d-none'}>
                        <table className='table p-lb-table table-borderless'>
                          <thead>
                            <tr>
                              <th>Position</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {payoutDataTable.length > 0 ? payoutDataTable.map((payout, index) => {
                              return (
                                <tr key={index}>
                                  <td>{payout.position}</td>
                                  <td>{payout.amount}</td>
                                </tr>
                              );
                            }) : <tr>
                              <td colSpan="2">No Payouts Found</td>
                            </tr>}
                          </tbody>
                        </table>
                      </div>
                      <div className={isActive.table === 'blinds' ? `blinds` : 'blinds d-none'}>
                        <table className='table p-lb-table table-borderless'>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Blinds</th>
                              <th>Duration</th>
                            </tr>
                          </thead>
                          <tbody>
                            {blindDataTable.length > 0 ? blindDataTable.map((blind, index) => {
                              return (
                                <tr key={index}>
                                  <td>{blind.index}</td>
                                  <td>{blind.blinds}</td>
                                  <td>{blind.duaration}</td>
                                </tr>
                              );
                            }) : <tr>
                              <td colSpan="3">No Blinds Found</td>
                            </tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </div>
  );
}

export default SitNGoGameTable
