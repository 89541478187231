import React from 'react'
import { useState, useEffect } from 'react';
import './index.css'
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import socket from '../../socket';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import { PaginationControl } from 'react-bootstrap-pagination-control';

const INITIAL_STATE = {
  errorMsg: '',
  oldPassword: '',
  newPassword: '',
  verifyNewPassword: ''
};

const MyAccount = (props) => {
  const [form, setForm] = useState(INITIAL_STATE);
  const userData = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(true)
  const [userProfilePhoto, setUserProfilePhoto] = useState(userData ? userData.avatar : 0);
  const [userProfilePicOption, setUserProfilePicOption] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15])
  const [userProfileForm, setUserProfileForm] = useState(userData);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
  const [gameHistoryList, setGameHistoryList] = useState({})
  const [gameHistory, setGameHistory] = useState({})
  const [page, setPage] = useState(1);
  const [isGameHistory, setIsGameHistory] = useState(false)
  const [isEditProfile, setIsEditProfile] = useState(false)
  const [preFlop, setPreFlop] = useState({});
  const [flop, setFlop] = useState({});
  const [turn, setTurn] = useState({});
  const [river, setRiver] = useState({});
  const [showDown, setShowDown] = useState({});

  const [isActive, setIsActive] = useState({
    table: 'profile'
  })

  const [isActiveHand, setIsActiveHand] = useState({
    table: 'game'
  })

  useEffect(() => {
    const mobile = window.innerWidth <= 1024;
  }, [window.innerWidth]);


  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.id]: event.target.value,
    });
  };

  const handleProfileChange = (event) => {
    setUserProfileForm({
      ...userProfileForm,
      [event.target.id]: event.target.value,
    });
  };

  const playerProfilePhoto = (img) => {
    return '../Images/Avtar/' + (img + 1) + '.png'
  }

  const changePage = (value) => {
    setPage(value)
    const data = {
      playerId: userData.playerId,
      pageNo: value,
      productName: 'BytePoker'
    }
    socket.emit('playerGameHistory', data, (res) => {
      console.log(res.result);
      setGameHistoryList(res.result.gamesHistoryList);
    });
  }

  const changePlayerAvtar = (type) => {
    if (type == 0) {
      const data = {
        playerId: userData.playerId,
        profilePic: userProfilePhoto,
      }
      socket.emit('playerProfilePic', data, (res) => {
        if (res.status == 'success') {
          let gamePlayer = userData;
          gamePlayer.profilePic = userProfilePhoto
          localStorage.setItem('user', JSON.stringify(gamePlayer));
          props.refreshData();
          closeModal();
        }
      });
    } else {
      setUserProfilePhoto(userData.avatar)
      closeModal();
    }
  }

  const changePlayerProfile = (type) => {
    const data = {
      playerId: userData.playerId,
      firstname: userProfileForm.firstname,
      lastname: userProfileForm.lastname,
      email: userProfileForm.email,
      mobile: userProfileForm.mobile,
    }
    socket.emit('changePlayerProfile', data, (res) => {
      if (res.status == 'success') {
        toast.success(res.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        socket.emit('Playerprofile', {
          playerId: userData.playerId,
          productName: 'BytePoker'
        }, (res) => {
          localStorage.setItem('user', JSON.stringify(res.result));
          props.refreshData();
          setIsEditProfile(false);
        });
      } else {
        toast.error(res.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
  }

  const changePassword = (type) => {
    form.errorMsg = '';
    if (type == 0) {
      const data = {
        playerId: userData.playerId,
        oldPassword: form.oldPassword,
        newPassword: form.newPassword,
        verifyNewPassword: form.verifyNewPassword,
      }
      socket.emit('playerChangePassword', data, (res) => {
        if (res.status == 'success') {
          toast.success(res.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setForm({
            oldPassword: '',
            newPassword: '',
            verifyNewPassword: '',
          });
          closeModal();
        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      });
    } else {
      closeModal();
    }
  }

  const logout = (e) => {
    localStorage.removeItem("user");
    navigate('/')
  }

  const closeModal = () => {
    props.closeMyProfile()
  }

  const openModal = () => {
    setIsLogoutModalOpen(true);
  }
  const closeLogoutModal = () => {
    setIsLogoutModalOpen(false);
  }

  const hideShowDiv = (id) => {
    if (id == 'handHistory') {
      setIsGameHistory(false);
      const data = {
        playerId: userData.playerId,
        pageNo: page,
        productName: 'BytePoker'
      }
      socket.emit('playerGameHistory', data, (res) => {
        console.log(res.result);
        setGameHistoryList(res.result.gamesHistoryList);
      });
      setIsActive({
        table: 'gameplayer',
      })
    }
    setIsActive({
      table: id,
    })
  }

  const hideShowHandTab = (id) => {
    setIsActiveHand({
      table: id,
    })
  }

  const openGameHistory = (gameId) => {
    const data = {
      playerId: userData.playerId,
      gameId: gameId,
      productName: 'BytePoker'
    }
    socket.emit('GameHistory', data, (res) => {
      if (res.result) {
        setIsGameHistory(true);
        hideShowHandTab('gameplayer')
        setGameHistory(res.result.gameHistory);
        const data = res.result.gameHistory;
        let preFlopArr = [];
        let flopArr = [];
        let turnArr = [];
        let riverArr = [];
        let showDownArr = [];
        data.handsEvents.map(((evnt, index) => {
          if (evnt.gameRound == 'Preflop') {
            preFlopArr.push(evnt);
          }
          if (evnt.gameRound == "Flop") {
            flopArr.push(evnt);
          }
          if (evnt.gameRound == 'Turn') {
            turnArr.push(evnt);
          }
          if (evnt.gameRound == 'River') {
            riverArr.push(evnt);
          }
          if (evnt.gameRound == 'ShowDown') {
            showDownArr.push(evnt);
          }
        }));
        setPreFlop(preFlopArr);
        setFlop(flopArr);
        setTurn(turnArr);
        setRiver(riverArr);
        setShowDown(showDownArr);
      }
    });
  }

  const playerActionArr = (action) => {
    if (action == 0) { return 'Small Blind' }
    else if (action == 1) { return 'Big Blind' }
    else if (action == 2) { return 'Check' }
    else if (action == 3) { return 'Bet' }
    else if (action == 4) { return 'Call' }
    else if (action == 5) { return '' }
    else if (action == 6) { return 'Fold' }
    else if (action == 7) { return 'Timeout' }
    else if (action == 8) { return 'All-in' }
    else if (action == 9) { return 'Straddle' }
    else { return 'Straddle' }
  }

  const mobile = window.innerWidth <= 1024;
  let customStylesLogout = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#242234',
      width: '40%',
      height: '50%',
      border: 'none'
    },
  }
  let customStyles = {
    content: {
      inset: '44% auto auto 49.5%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#474B6B',
      width: '60%',
      border: 'none',
      padding: '0px',
      borderRadius: '15px',
    },
  }
  if (mobile) {
    customStylesLogout = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#242234',
        width: '60%',
        height: '30%',
        border: 'none'
      },
    }
    customStyles = {
      content: {
        inset: '44% auto auto 49.5%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#474B6B',
        width: '90%',
        border: 'none',
        padding: '0px',
      },
    }
  }

  return (
    <div >
      <ToastContainer />
      <Modal
        isOpen={isAccountModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className='plyerProfile'>
          <div className={mobile ? "mobileView" : ''}>
            <div className="cashier-modal">
              <div className='d-flex min-h-100'>
                <div className='p-col-3 modal-sidebar min-h-100'>
                  <div className='modal-sidebar-header'>
                    <div className='cashier-heading'>
                      <h2>My Account</h2>
                    </div>
                  </div>
                  <button className={isActive.table === 'profile' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="profile"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Profile</button>
                  <hr></hr>
                  <button className={isActive.table === 'changeavatar' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="changeavatar"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Change Avatar</button>
                  <button className={isActive.table === 'changepassword' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="changepassword"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Change Password</button>
                  <hr></hr>
                  <button className={isActive.table === 'accountSecurity' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="accountSecurity"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Account Security</button>
                  <button className={isActive.table === 'settings' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="settings"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Settings</button>
                  <hr></hr>
                  <button className={isActive.table === 'handHistory' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="handHistory"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Hands History</button>

                  <hr></hr>
                  <button className='btn modal-sidebar-button'
                    onClick={() => openModal(true)}>Logout</button>
                </div>
                <div className='p-col-9 min-h-100 theme-bg'>
                  <div className='cashier-modal-card'>
                    <div className='cashier-modal-heading'>
                      {isActive.table === 'profile' ?
                        <h2 >Profile</h2>
                        : isActive.table === 'changeavatar' ?
                          <h2 >Change Avatar</h2>
                          : isActive.table === 'changepassword' ?
                            <h2 >Change Password</h2>
                            : isActive.table === 'accountSecurity' ?
                              <h2 >Account Settings</h2>
                              : isActive.table === 'settings' ?
                                <h2 >Settings</h2>
                                : isActive.table === 'handHistory' ?
                                  <h2 >Hands History</h2>
                                  : ''
                      }
                      <img src={'../Images/close.png'} onClick={() => closeModal(false)}></img>
                    </div>
                    <div className='cashier-modal-body'>
                      <div className={isActive.table === 'profile' ? `profile` : 'profile d-none'}>
                        <div className='playerProfileInfo cashier-box'>
                          <Row>
                            {isEditProfile ?
                              <Col lg={6}>
                                <div className='form-group'>
                                  <label>First Name</label>
                                  <div className="inputWithIcon">
                                    <input type="text" placeholder="Not Set" onChange={handleProfileChange} value={userProfileForm.firstname} id="firstname" />
                                  </div>
                                </div>
                                <div className='form-group'>
                                  <label>Last Name</label>
                                  <div className="inputWithIcon">
                                    <input type="text" placeholder="Not Set" onChange={handleProfileChange} value={userProfileForm.lastname} id="lastname" />
                                  </div>
                                </div>
                                {/* <div className='form-group'>
                                  <label>Username</label>
                                  <div className="inputWithIcon">
                                    <input type="text" placeholder="Not Set" onChange={handleProfileChange} value={userProfileForm.username} id="username" disabled/>
                                  </div>
                                </div> */}
                                <div className='form-group'>
                                  <label>Email</label>
                                  <div className="inputWithIcon">
                                    <input type="text" placeholder="Not Set" onChange={handleProfileChange} value={userProfileForm.email} id="email" />
                                  </div>
                                </div>
                                <div className='form-group'>
                                  <label>Mobile</label>
                                  <div className="inputWithIcon">
                                    <input type="text" placeholder="Not Set" onChange={handleProfileChange} value={userProfileForm.mobile} id="mobile" />
                                  </div>
                                </div>
                              </Col>
                              :
                              <Col lg={6}>
                                <div className='mb-1'>
                                  <label>Username</label>
                                  <p className='profileDetails'>{userData?.username ? userData?.username : 'Not Set'}</p>
                                </div>
                                <div className='mb-1'>
                                  <label>First Name</label>
                                  <p className='profileDetails'>{userData?.firstname ? userData?.firstname : 'Not Set'}</p>
                                </div>
                                <div className='mb-1'>
                                  <label>Last Name</label>
                                  <p className='profileDetails'>{userData?.lastname ? userData?.lastname : 'Not Set'}</p>
                                </div>
                                <div className='mb-1'>
                                  <label>Email</label>
                                  <p className='profileDetails'>{userData?.email ? userData?.email : 'Not Set'}</p>
                                </div>
                                <div className='mb-1'>
                                  <label>Mobile</label>
                                  <p className='profileDetails'>{userData?.mobile}</p>
                                </div>
                                <div className='mb-1'>
                                  <label>Total Chips</label>
                                  <p className='profileDetails'>{userData ? Number(userData.chips).toFixed(2) : 0.00}</p>
                                </div>
                                <div className='mb-1'>
                                  <label>Total Balance Hours</label>
                                  <p className='profileDetails'>{userData?.hour_balance ? userData?.hour_balance : '00:00'}</p>
                                </div>
                              </Col>
                            }
                            <Col lg={6}>
                              <div className='plyerProfileAvtar'> 
                                <div className={'playerProfileWithFrame vipLevel'+userData.vipLevel}>
                                  <img src={playerProfilePhoto(userProfilePhoto)} className='plyrProfilePicMain'></img>
                                </div>
                              </div>
                              <div className='vip-level-section text-center'>
                                <h4>VIP Level</h4>
                                <h4><span>{userData.vipLevel == 5 ? 'Diamond' : userData.vipLevel == 4 ? 'Platinum' : userData.vipLevel == 3 ? 'Gold' : userData.vipLevel == 2 ? 'Silver' : 'Bronze'}</span></h4>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {isEditProfile ?
                          <div className='backButtonSection mb-4'>
                            <button type="button" className='defaultBtn' onClick={() => setIsEditProfile(false)}>Cancel</button>
                            <button type="button" className='edtAvtarBtn' onClick={() => changePlayerProfile()}>Save</button>
                          </div>
                          :
                          <div className='backButtonSection mb-4'>
                            <button type="button" className='edtAvtarBtn' onClick={() => setIsEditProfile(true)}>Edit</button>
                          </div>
                        }
                      </div>
                      <div className={isActive.table === 'changeavatar' ? `changeavatar` : 'changeavatar d-none'}>
                        <div>
                          <div className='text-center'>
                            {userProfilePicOption.map((pic, index) => {
                              return userProfilePhoto == index ?
                                <img onClick={() => setUserProfilePhoto(index)} key={index} src={playerProfilePhoto(index)} className='plyrProfilePic activeProfile'></img> :
                                <img onClick={() => setUserProfilePhoto(index)} key={index} src={playerProfilePhoto(index)} className='plyrProfilePic'></img>
                            })}
                          </div>
                          <div className='avtarBtnSection mb-4 text-center'>
                            {/* <button className='edtAvtarBtn' onClick={() => changePlayerAvtar(1)}>Cancel</button> */}
                            <button className='edtAvtarBtn' onClick={() => changePlayerAvtar(0)}>Update</button>
                          </div>
                        </div>
                      </div>
                      <div className={isActive.table === 'changepassword' ? `changepassword` : 'changepassword d-none'}>
                        <div className='playerProfileInfo cashier-box'>
                          <hr></hr>
                          <Row className='justify-content-center'>
                            <Col lg={8}>
                              <div className='form-group'>
                                <label>Current Password</label>
                                <div className="inputWithIcon">
                                  <input id="oldPassword"
                                    type="password"
                                    value={form.oldPassword}
                                    onChange={handleChange}
                                    placeholder="Enter Current Password" />
                                </div>
                              </div>
                            </Col>
                            <Col lg={8}>
                              <div className='form-group'>
                                <label>New Password</label>
                                <div className="inputWithIcon">
                                  <input id="newPassword"
                                    type="password"
                                    value={form.newPassword}
                                    onChange={handleChange}
                                    placeholder="New Password" />
                                </div>
                              </div>
                            </Col>
                            <Col lg={8}>
                              <div className='form-group'>
                                <label>Confirm Password</label>
                                <div className="inputWithIcon">
                                  <input id="verifyNewPassword"
                                    type="text"
                                    value={form.verifyNewPassword}
                                    onChange={handleChange}
                                    placeholder="Confirm Password" />
                                </div>
                              </div>
                            </Col>
                          </Row>

                        </div>
                        <div className='avtarBtnSection mb-4 text-center'>
                          {/* <button type="button" className='defaultBtn' onClick={() => changePassword(1)}>Cancel</button> */}
                          <button type="button" className='edtAvtarBtn' onClick={() => changePassword(0)}>Change</button>
                        </div>
                      </div>
                      <div className={isActive.table === 'accountSecurity' ? `accountSecurity` : 'accountSecurity d-none'}>
                        <div className='acount-security-table'>
                          <table className='table p-lb-table table-borderless'>
                            <tbody>
                              <tr>
                                <td>2 Factory Authentication</td>
                                <td><input type="checkbox" checked={true}></input></td>
                              </tr>
                              <tr>
                                <td>Security Questions</td>
                                <td><input type="checkbox" checked={true}></input></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className={isActive.table === 'settings' ? `settings` : 'settings d-none'}>
                        <div className='acount-security-table'>
                          <table className='table p-lb-table table-borderless'>
                            <tbody>
                              <tr>
                                <td>Sound Settings</td>
                                <td><input type="checkbox" checked={true}></input></td>
                              </tr>
                              <tr>
                                <td>Vibration Settings</td>
                                <td><input type="checkbox" checked={true}></input></td>
                              </tr>
                              <tr>
                                <td>Push Settings</td>
                                <td><input type="checkbox" checked={true}></input></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className={isActive.table === 'handHistory' ? `handHistory` : 'handHistory d-none'}>
                        {isGameHistory ?
                          <div className='gameHistoryData'>
                            <div className='playerProfileInfo pt-0'>
                              <Row>
                                <Col lg={12}>
                                  <div className='d-flex gap-2 login_header no_border m-0 mb-2'>
                                    <h6 className='active' onClick={() => setIsGameHistory(false)}>Go Back</h6>
                                  </div>
                                  <div className='cashier-box'>
                                    <Row>
                                      <Col lg={6}>
                                        <div className='mb-1'>
                                          <label>Game Name</label>
                                          <p className='profileDetails'>{gameHistory.game?.tableName}</p>
                                        </div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className='mb-1'>
                                          <label>Game ID</label>
                                          <p className='profileDetails'>{gameHistory.gameName}</p>
                                        </div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className='mb-1'>
                                          <label>Stakes</label>
                                          <p className='profileDetails'>{gameHistory.stack}</p>
                                        </div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className='mb-1'>
                                          <label>Date Time</label>
                                          <p className='profileDetails'>{moment(gameHistory.dataTime).format("DD MMM, YYYY hh:mm A")}</p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                                <Col lg={12}>
                                  <div className={mobile ? "mobileView" : ''}>
                                    <div className='d-flex gap-2 login_header'>
                                      <h6 className={isActiveHand.table === 'gameplayer' ? 'active' : ''} id="gameplayer" onClick={(e) => { hideShowHandTab(e.target.id) }}>Players</h6>
                                      {gameHistory?.winners?.length > 0 ? <h6 className={isActiveHand.table === 'winner' ? 'active' : ''} id="winner" onClick={(e) => { hideShowHandTab(e.target.id) }}>Winners</h6> : ''}
                                      {preFlop?.length > 0 ? <h6 className={isActiveHand.table === 'preflop' ? 'active' : ''} id="preflop" onClick={(e) => { hideShowHandTab(e.target.id) }}>Pre Flop</h6> : ''}
                                      {flop?.length > 0 ? <h6 className={isActiveHand.table === 'flop' ? 'active' : ''} id="flop" onClick={(e) => { hideShowHandTab(e.target.id) }}>Flop</h6> : ''}
                                      {turn?.length > 0 ? <h6 className={isActiveHand.table === 'turn' ? 'active' : ''} id="turn" onClick={(e) => { hideShowHandTab(e.target.id) }}>Turn</h6> : ''}
                                      {river?.length > 0 ? <h6 className={isActiveHand.table === 'river' ? 'active' : ''} id="river" onClick={(e) => { hideShowHandTab(e.target.id) }}>River</h6> : ''}
                                      {showDown?.length > 0 ? <h6 className={isActiveHand.table === 'showdown' ? 'active' : ''} id="showdown" onClick={(e) => { hideShowHandTab(e.target.id) }}>Showdown</h6> : ''}
                                    </div>
                                    <div className='cashier-box'>
                                      <div className='theme-bg'>
                                        <div className={isActiveHand.table === 'winner' ? `winner` : 'winner d-none'}>
                                          {gameHistory?.winners?.length > 0 ?
                                            <div>
                                              <h4 className='gameNameTitle gameDateilsTitle'>Winners</h4>
                                              <table className='table p-lb-table table-borderless'>
                                                <thead>
                                                  <tr>
                                                    <th>Player</th>
                                                    <th>Winning Amount</th>
                                                    <th>Best Cards</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {gameHistory?.winners?.map((winner, index) => {
                                                    return (
                                                      <tr key={index}>
                                                        <td>{winner.playerName}</td>
                                                        <td>{winner.winningAmount}</td>
                                                        <td>
                                                          {winner.winningHands.length > 0 ? winner.winningHands.map((card, k) => {
                                                            return (
                                                              <img key={k} src={'../Images/Card/' + card + '.png'} className='historyCard mx-1'></img>
                                                            )
                                                          }) : ''}</td>
                                                      </tr>
                                                    );
                                                  })}
                                                </tbody>
                                              </table>
                                            </div>
                                            : ''}
                                        </div>
                                        <div className={isActiveHand.table === 'gameplayer' ? `gameplayer` : 'gameplayer d-none'}>
                                          <h4 className='gameNameTitle gameDateilsTitle'>Players List</h4>
                                          <table className='table p-lb-table table-borderless'>
                                            <thead>
                                              <tr>
                                                <th>Player</th>
                                                <th>Cards</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {gameHistory?.players?.length > 0 ? gameHistory?.players?.map((plr, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <td>{plr.playerName}</td>
                                                    <td>
                                                      {plr.cards.length > 0 ? plr.cards.map((card, k) => {
                                                        return (
                                                          <img key={k} src={'../Images/Card/' + card + '.png'} className='historyCard mx-1'></img>
                                                        )
                                                      }) : ''}</td>
                                                  </tr>
                                                );
                                              }) : <tr>
                                                <td colSpan="7">No Player Found</td>
                                              </tr>}
                                            </tbody>
                                          </table>
                                        </div>
                                        <div className={isActiveHand.table === 'preflop' ? `preflop` : 'preflop d-none'}>
                                          {preFlop?.length > 0 ?
                                            <div>
                                              <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - Pre Flop</h4>
                                              <table className='table p-lb-table table-borderless'>
                                                <thead>
                                                  <tr>
                                                    <th>Player</th>
                                                    <th>Action</th>
                                                    <th>Chips</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {preFlop.map((data, index) => {
                                                    return (
                                                      <tr key={index}>
                                                        <td style={{ width: '40%' }}>{data.playerName}</td>
                                                        <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                                        <td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(2) : ''}</td>
                                                      </tr>
                                                    );
                                                  })}
                                                </tbody>
                                              </table>
                                            </div>
                                            : ''}
                                        </div>
                                        <div className={isActiveHand.table === 'flop' ? `flop` : 'flop d-none'}>
                                          {flop?.length > 0 ?
                                            <div>
                                              <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - Flop

                                              </h4>
                                              <table className='table p-lb-table table-borderless'>
                                                <tbody>
                                                  {gameHistory?.tableCards?.Flop.length > 0 ?
                                                    <tr>
                                                      <td className='flopTitle' style={{ width: '40%' }}>Flop</td>
                                                      <td style={{ width: '30%' }}>
                                                        {gameHistory?.tableCards?.Flop.map((card) => {
                                                          return (
                                                            <img className='historyCard' src={'../Images/Card/' + card + '.png'}></img>
                                                          )
                                                        })
                                                        }
                                                      </td>
                                                      <td style={{ width: '30%' }}></td>
                                                    </tr>
                                                    : ''}
                                                  {flop.map((data, index) => {
                                                    return (
                                                      <tr key={index}>
                                                        <td style={{ width: '40%' }}>{data.playerName}</td>
                                                        <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                                        <td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(2) : ''}</td>
                                                      </tr>
                                                    );
                                                  })}
                                                </tbody>
                                              </table>
                                            </div>
                                            : ''}
                                        </div>
                                        <div className={isActiveHand.table === 'turn' ? `turn` : 'turn d-none'}>
                                          {turn?.length > 0 ?
                                            <div>
                                              <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - Turn</h4>
                                              <table className='table p-lb-table table-borderless'>
                                                <tbody>
                                                  {gameHistory?.tableCards?.Turn.length > 0 ?
                                                    <tr >
                                                      <td style={{ width: '40%' }}>Turn</td>
                                                      <td style={{ width: '30%' }}>
                                                        {gameHistory?.tableCards?.Turn.map((card) => {
                                                          return (
                                                            <img className='historyCard' src={'../Images/Card/' + card + '.png'}></img>
                                                          )
                                                        })
                                                        }
                                                      </td>
                                                      <td style={{ width: '30%' }}></td>
                                                    </tr>
                                                    : ''}
                                                  {turn.map((data, index) => {
                                                    return (
                                                      <tr key={index}>
                                                        <td style={{ width: '40%' }}>{data.playerName}</td>
                                                        <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                                        <td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(2) : ''}</td>
                                                      </tr>
                                                    );
                                                  })}
                                                </tbody>
                                              </table>
                                            </div>
                                            : ''}
                                        </div>
                                        <div className={isActiveHand.table === 'river' ? `river` : 'river d-none'}>
                                          {river?.length > 0 ?
                                            <div>
                                              <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - River</h4>
                                              <table className='table p-lb-table table-borderless'>
                                                <tbody>
                                                  {gameHistory?.tableCards?.River.length > 0 ?
                                                    <tr >
                                                      <td style={{ width: '40%' }}>River</td>
                                                      <td style={{ width: '30%' }}>
                                                        {gameHistory?.tableCards?.River.map((card) => {
                                                          return (
                                                            <img className='historyCard' src={'../Images/Card/' + card + '.png'}></img>
                                                          )
                                                        })
                                                        }
                                                      </td>
                                                      <td style={{ width: '30%' }}></td>
                                                    </tr>
                                                    : ''}
                                                  {river.map((data, index) => {
                                                    return (
                                                      <tr key={index}>
                                                        <td style={{ width: '40%' }}>{data.playerName}</td>
                                                        <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                                        <td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(2) : ''}</td>
                                                      </tr>
                                                    );
                                                  })}
                                                </tbody>
                                              </table>
                                            </div>
                                            : ''}
                                        </div>
                                        <div className={isActiveHand.table === 'showdown' ? `showdown` : 'showdown d-none'}>
                                          {showDown?.length > 0 ?
                                            <div>
                                              <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - Show Down</h4>
                                              <table className='table p-lb-table table-borderless'>
                                                <thead>
                                                  <tr>
                                                    <th>Player</th>
                                                    <th>Action</th>
                                                    <th>Chips</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {showDown.map((data, index) => {
                                                    return (
                                                      <tr key={index}>
                                                        <td style={{ width: '40%' }}>{data.playerName}</td>
                                                        <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                                        <td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(2) : ''}</td>
                                                      </tr>
                                                    );
                                                  })}
                                                </tbody>
                                              </table>
                                            </div>
                                            : ''}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          :
                          <div className='playerProfileInfo'>
                            <table className='table p-lb-table table-borderless'>
                              <thead>
                                <tr>
                                  <th>Date & Time</th>
                                  <th>Game Name</th>
                                  <th>Winning Amount</th>
                                  <th>Hands</th>
                                  <th>Best Cards</th>
                                </tr>
                              </thead>
                              <tbody>
                                {gameHistoryList.length > 0 ? gameHistoryList.map((history, index) => {
                                  return (
                                    <tr key={index} onClick={() => openGameHistory(history.gameId)}>
                                      <td>{moment(history.dateTime).format("DD MMM, YYYY hh:mm A")}</td>
                                      <td>{history.game?.tableName}</td>
                                      <td>{history.winner?.winningAmount}</td>
                                      <td >
                                        {history.handCards.length > 0 ? history.handCards.map((card, k) => {
                                          return (
                                            <img key={k} src={'../Images/Card/' + card + '.png'} className='historyCard mx-1'></img>
                                          )
                                        }) : ''}</td>
                                      <td >
                                        {history.winner?.winningHands?.length > 0 ? history.winner.winningHands.map((winningCard, k) => {
                                          return (
                                            <img key={k} src={'../Images/Card/' + winningCard + '.png'} className='historyCard mx-1'></img>
                                          )
                                        }) : ''}</td>
                                    </tr>
                                  );
                                }) : <tr>
                                  <td colSpan="7">No Game Found</td>
                                </tr>}
                              </tbody>
                            </table>
                            <div className="historyPagination">
                              <PaginationControl
                                page={page}
                                between={0}
                                total={497}
                                limit={25}
                                changePage={(page) => {
                                  changePage(page);
                                }}
                                ellipsis={1}
                              />
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal >

      <Modal
        isOpen={isLogoutModalOpen}
        onRequestClose={closeLogoutModal}
        style={customStylesLogout}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className={mobile ? "gameModel mobileView" : 'gameModel'}>
          <div className='modalBody'>
            <center>
              <h2 className='color-1'>Warning!</h2>
              <h4 className='color-1 mt-4'>Are you sure you want leave this game?</h4>
              <div className='avtarBtnSection'>
                <button className='selectBuyInBtn' onClick={() => logout()}>Yes</button>
                <button className='selectBuyInBtn' onClick={() => closeLogoutModal()}>No</button>
              </div>
            </center>
          </div>
        </div>
      </Modal>
    </div >
  );
};

export default MyAccount

