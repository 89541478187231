import React from 'react'
import { useState, useEffect } from 'react';
import './index.css'
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import * as io from 'socket.io-client';
import socketConfig from '../../Socket/config.js'
import { PaginationControl } from 'react-bootstrap-pagination-control';
import socket from '../../socket';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginImg from '../../Images/LoginLogo12.png'

const Cashier = (props) => {
  const [codeForm, setCodeForm] = useState({ code: '' });
  const userData = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(true)
  const [depositePaymentMethod, setDepositePaymentMethod] = useState('manual');
  const [depositAmount, setDepositAmount] = useState('');
  const [isCardMethodSelected, setIsCardMethodSelected] = useState(false);
  const [isMobile, setIsMobile] = useState(false)
  const [depositeCard, setDepositeCard] = useState('visa');
  const [lockedBonusHistory, setLockedBonusHistory] = useState({})
  const [isActiveHistory, setIsActiveHistory] = useState({
    table: 'DepositHistory'
  })
  const [isActive, setIsActive] = useState({
    table: 'allTra'
  })

  useEffect(() => {
    const mobile = window.innerWidth <= 1024;
    setIsMobile(mobile);
  }, [window.innerWidth]);


  const claimCode = (id) => {
    const data = {
      playerId: userData.playerId,
      historyId: id,
    }
    socket.emit('claimCode', data, (res) => {
      if (res.status == 'success') {
        toast.success(res.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        hideShowDiv('lockedBonus');
      } else {
        toast.error(res.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
  }
  const reedemCode = () => {
    const data = {
      playerId: userData.playerId,
      code: codeForm.code
    }
    socket.emit('reedemCode', data, (res) => {
      if (res.status == 'success') {
        toast.success(res.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setCodeForm({
          code: ''
        });
      } else {
        toast.error(res.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setCodeForm({
          code: ''
        });
      }
    });
  }

  const handleChange = (event) => {
    setCodeForm({
      ...codeForm,
      [event.target.id]: event.target.value,
    });
  };

  const closeModal = () => {
    // setIsLoading(false);
    // setUserProfileEdit(false);
    // setChangePasswordForm(false);
    // setIsAccountModalOpen(false);
    // setIsGameHistoryModalOpen(false);
    // setIsSettingModalOpen(false);
    // setIsLogoutModalOpen(false);
    // setPage(1);
    props.closeCashier()
  }

  const buyPackage = (hour) => {
    const data = {
      playerId: userData.playerId,
      selectedHour: hour
    }
    socket.emit('buyHourlyPackage', data, (res) => {
      if (res.status == 'success') {
        toast.success(res.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error(res.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
  }

  const hideShowDiv = (id) => {
    if (id == 'deposit') {
      setDepositePaymentMethod('card');
      setIsCardMethodSelected(false)
      setDepositeCard('visa')
    }
    if (id == 'cashout') {
      setDepositePaymentMethod('bankTransfer');
    }
    if (id == 'redeemCode') {
      setCodeForm({
        code: ''
      });
    }
    if (id == 'lockedBonus') {
      const data = {
        playerId: userData.playerId,
        productName: 'BytePoker'
      }
      socket.emit('lockedBonus', data, (res) => {
        if (res.status == 'success') {
          console.log(res.result.bonusCodeHistory);
          setLockedBonusHistory(res.result.bonusCodeHistory);
        } else {
          setLockedBonusHistory({});
        }
      });
    }
    setIsActive({
      table: id,
    })
  }

  const hideShowHandTab = (id) => {
    setIsActiveHistory({
      table: id,
    })
  }

  const mobile = window.innerWidth <= 1024;

  let customStyles = {
    content: {
      inset: '44% auto auto 49.5%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#474B6B',
      width: '60%',
      border: 'none',
      padding: '0px',
      borderRadius: '15px',
    },
  }

  if (mobile) {
    customStyles = {
      content: {
        inset: '44% auto auto 49.5%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#474B6B',
        width: '90%',
        border: 'none',
        padding: '0px',
      },
    }
  }

  return (
    <div >
      <ToastContainer />
      <Modal
        isOpen={isAccountModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className='plyerProfile'>
          <div className={mobile ? "mobileView" : ''}>
            <div className="cashier-modal">
              <div className='d-flex min-h-100'>
                <div className='p-col-3 modal-sidebar min-h-100'>
                  <div className='modal-sidebar-header'>
                    <div className='login-logo'>
                      <img src={LoginImg} className="gameLogo" />
                    </div>
                    <div className='cashier-heading'>
                      <h2>Cashier</h2>
                    </div>
                  </div>
                  <button className={isActive.table === 'deposit' ? `btn modal-sidebar-button  active` : 'btn modal-sidebar-button'} id="deposit"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Deposit</button>
                  <button className={isActive.table === 'cashout' ? `btn modal-sidebar-button   active` : 'btn modal-sidebar-button  '} id="cashout"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Cash Out</button>
                  <hr></hr>
                  <button className={isActive.table === 'allTra' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="allTra"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Transaction History</button>
                  <hr></hr>
                  <button className={isActive.table === 'buyHour' ? `btn modal-sidebar-button  active` : 'btn modal-sidebar-button  '} id="buyHour"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Buy Hours</button>
                  <button className={isActive.table === 'redeemCode' ? `btn modal-sidebar-button   active` : 'btn modal-sidebar-button  '} id="redeemCode"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Reedem Code</button>
                  <hr></hr>
                  <button className={isActive.table === 'lockedBonus' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="lockedBonus"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Locked Bonus</button>
                  {/* <hr></hr>
                  <button className={isActive.table === 'buyHourHisoty' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button  '} id="buyHourHisoty"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Buy Hours History</button>
                  <button className={isActive.table === 'depositHisoty' ? `btn modal-sidebar-button  active` : 'btn modal-sidebar-button  '} id="depositHisoty"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Deposit History</button>
                  <button className={isActive.table === 'cashoutHistory' ? `btn modal-sidebar-button  active` : 'btn modal-sidebar-button  '} id="cashoutHistory"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Cash Out History</button> */}
                </div>
                <div className='p-col-9 min-h-100 theme-bg'>
                  <div className='cashier-modal-card'>
                    <div className='cashier-modal-heading'>
                      {isActive.table === 'allTra' ?
                        <h2 >All Transaction</h2>
                        : isActive.table === 'buyHour' ?
                          <h2 >Buy Hours</h2>
                          : isActive.table === 'deposit' ?
                            <h2 >Deposit</h2>
                            : isActive.table === 'cashout' ?
                              <h2 >Cashout</h2>
                              : isActive.table === 'depositHisoty' ?
                                <h2 >Deposit History</h2>
                                : isActive.table === 'cashoutHistory' ?
                                  <h2 >Cashout History</h2>
                                  : isActive.table === 'redeemCode' ?
                                    <h2 >Reedem Code</h2>
                                    : isActive.table === 'lockedBonus' ?
                                      <h2 >Locked Bonus</h2>
                                      : ''
                      }
                      <img src={'../Images/close.png'} onClick={() => closeModal(false)}></img>
                    </div>
                    <div className='cashier-modal-body'>
                      <div className={isActive.table === 'allTra' ? `allTra` : 'allTra d-none'}>
                        <div className='cahsierForm'>
                          <div className='d-flex gap-2 login_header'>
                            <h6 className={isActiveHistory.table === 'DepositHistory' ? 'active' : ''} id="DepositHistory" onClick={(e) => { hideShowHandTab(e.target.id) }}>Deposit History</h6>
                            <h6 className={isActiveHistory.table === 'CashHistory' ? 'active' : ''} id="CashHistory" onClick={(e) => { hideShowHandTab(e.target.id) }}>Cash Out History</h6>
                            <h6 className={isActiveHistory.table === 'BuyHourHistory' ? 'active' : ''} id="BuyHourHistory" onClick={(e) => { hideShowHandTab(e.target.id) }}>Buy Hours History</h6>
                          </div>
                          {isActiveHistory.table === 'CashHistory' ?
                            <div>
                              <table className='table p-lb-table table-borderless'>
                                <thead>
                                  <tr>
                                    <th>Sr No.</th>
                                    <th>Date & Time</th>
                                    <th>Refrerence No</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                    <th>Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>10-05-2023 3:45 pm</td>
                                    <td>RN15412198425</td>
                                    <td>Card Payment</td>
                                    <td>Success</td>
                                    <td>500</td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>10-05-2023 3:45 pm</td>
                                    <td>RN15412135412</td>
                                    <td>Crypto Payment</td>
                                    <td>Success</td>
                                    <td>1000</td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>10-05-2023 3:45 pm</td>
                                    <td>RN1541284512</td>
                                    <td>Cash Payment</td>
                                    <td>Success</td>
                                    <td>5000</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            : isActiveHistory.table === 'BuyHourHistory' ?
                              <div>
                                <table className='table p-lb-table table-borderless'>
                                  <thead>
                                    <tr>
                                      <th>Sr No.</th>
                                      <th>Date & Time</th>
                                      <th>Refrerence No</th>
                                      <th>Type</th>
                                      <th>Status</th>
                                      <th>Hour</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>1</td>
                                      <td>10-05-2023 3:45 pm</td>
                                      <td>RN15412198425</td>
                                      <td>Card Payment</td>
                                      <td>Success</td>
                                      <td>2</td>
                                    </tr>
                                    <tr>
                                      <td>2</td>
                                      <td>10-05-2023 3:45 pm</td>
                                      <td>RN15412135412</td>
                                      <td>Crypto Payment</td>
                                      <td>Success</td>
                                      <td>10</td>
                                    </tr>
                                    <tr>
                                      <td>3</td>
                                      <td>10-05-2023 3:45 pm</td>
                                      <td>RN1541284512</td>
                                      <td>Cash Payment</td>
                                      <td>Success</td>
                                      <td>20</td>
                                    </tr>
                                    <tr>
                                      <td>4</td>
                                      <td>10-05-2023 3:45 pm</td>
                                      <td>RN1541284512</td>
                                      <td>Cash Payment</td>
                                      <td>Success</td>
                                      <td>2</td>
                                    </tr>
                                    <tr>
                                      <td>5</td>
                                      <td>10-05-2023 3:45 pm</td>
                                      <td>RN1541284512</td>
                                      <td>Cash Payment</td>
                                      <td>Success</td>
                                      <td>5</td>
                                    </tr>
                                    <tr>
                                      <td>6</td>
                                      <td>10-05-2023 3:45 pm</td>
                                      <td>RN1541284512</td>
                                      <td>Cash Payment</td>
                                      <td>Success</td>
                                      <td>20</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              : <div>
                                <table className='table p-lb-table table-borderless'>
                                  <thead>
                                    <tr>
                                      <th>Sr No.</th>
                                      <th>Date & Time</th>
                                      <th>Refrerence No</th>
                                      <th>Type</th>
                                      <th>Status</th>
                                      <th>Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>1</td>
                                      <td>1-05-2023 1:00 pm</td>
                                      <td>RN15498745125</td>
                                      <td>Card Payment</td>
                                      <td>Success</td>
                                      <td>1000</td>
                                    </tr>
                                    <tr>
                                      <td>2</td>
                                      <td>18-05-2023 2:45 pm</td>
                                      <td>RN154129451251</td>
                                      <td>Card Payment</td>
                                      <td>Success</td>
                                      <td>500</td>
                                    </tr>
                                    <tr>
                                      <td>3</td>
                                      <td>05-07-2023 6:45 pm</td>
                                      <td>RN15412987451</td>
                                      <td>Cash Payment</td>
                                      <td>Success</td>
                                      <td>200</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>}
                        </div>
                      </div>
                      <div className={isActive.table === 'buyHour' ? `buyHour` : 'buyHour d-none'}>
                        <div className='buyHour-card-body'>
                          <div className='buyHourBox'>
                            <div className='package-name-header'>
                              <p>STARTER</p>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('02:00')}>Buy Now</button>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 2 Hour</li>
                                <li>$25 per hour</li>
                              </ul>
                            </div>
                          </div>
                          <div className='buyHourBox'>
                            <div className='package-name-header'>
                              <p>BOOSTER</p>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('05:00')}>Buy Now</button>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 5 Hour</li>
                                <li>$22 per hour</li>
                              </ul>
                            </div>
                          </div>
                          <div className='buyHourBox'>
                            <div className='package-name-header'>
                              <p>BRONZE</p>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('10:00')}>Buy Now</button>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 10 Hour</li>
                                <li>$20 per hour</li>
                              </ul>
                            </div>
                          </div>
                          <div className='buyHourBox'>
                            <div className='package-name-header'>
                              <p>SILVER</p>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('20:00')}>Buy Now</button>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 20 Hour</li>
                                <li>$18 per hour</li>
                              </ul>
                            </div>
                          </div>
                          <div className='buyHourBox'>
                            <div className='package-name-header'>
                              <p>GOLD</p>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('30:00')}>Buy Now</button>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 30 Hour</li>
                                <li>$15 per hour</li>
                              </ul>
                            </div>
                          </div>
                          <div className='buyHourBox'>
                            <div className='package-name-header'>
                              <p>PLATINUM</p>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('50:00')}>Buy Now</button>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 50 Hour</li>
                                <li>$12 per hour</li>
                              </ul>
                            </div>
                          </div>
                          <div className='buyHourBox'>
                            <div className='package-name-header'>
                              <p>TITANIUM</p>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('75:00')}>Buy Now</button>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 75 Hour</li>
                                <li>$10 per hour</li>
                              </ul>
                            </div>
                          </div>
                          <div className='buyHourBox'>
                            <div className='package-name-header'>
                              <p>DIAMOND</p>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('100:00')}>Buy Now</button>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 100 Hour</li>
                                <li>$5 per hour</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={isActive.table === 'deposit' ? `deposit cashier-box` : 'deposit d-none'}>
                        {isCardMethodSelected ?
                          <form className='cahsierForm'>
                            <div className='form-group'>
                              <div>

                                <div className='d-flex gap-2'>
                                  <div className='card-payment-details'>

                                    <div className='form-group mt-0'>
                                      <label>Amount</label>
                                      <button className='btn payment-method-btn active m-1 mt-0'>$ 100</button>
                                    </div>
                                    <div className='form-group mt-0 mt-1 mb-4'>
                                      <label>Card Service</label>
                                      {depositeCard === 'visa' ?
                                        <button className="btn payment-method-btn m-1 active">
                                          <div className='icon'><img src={'../Images/visa.png'} /></div>
                                          <p className='payment-method-name'>VISA</p>
                                        </button>
                                        : depositeCard === 'master' ?
                                          <button className="btn payment-method-btn m-1 active">
                                            <div className='icon'><img src={'../Images/mastercard.png'} /></div>
                                            <p className='payment-method-name'>MasterCard</p>
                                          </button>
                                          : depositeCard === 'maestro' ?
                                            <button className="btn payment-method-btn m-1 active">
                                              <div className='icon'><img src={'../Images/maestro.png'} /></div>
                                              <p className='payment-method-name'>Maestro</p>
                                            </button>
                                            : depositeCard === 'americanexpress' ?
                                              <button className="btn payment-method-btn m-1 active">
                                                <div className='icon'><img src={'../Images/americanexpress.png'} /></div>
                                                <p className='payment-method-name'>American Express</p>
                                              </button>
                                              : ''}
                                    </div>
                                    <div className='form-group mt-4'>
                                      <button className='btn payment-method-btn m-1 mt-4' onClick={(e) => setIsCardMethodSelected(false)}>Back</button>
                                    </div>
                                  </div>
                                  <div className='card-payment'>
                                    <div className='form-group mt-0'>
                                      <label>Card Holder Name</label>
                                      <input type="text" placeholder="Enter Card Holder Name"></input>
                                    </div>
                                    <div className='form-group mt-3'>
                                      <label>Card Number</label>
                                      <input type="text" placeholder="XXXX-XXXX-XXXX-XXXX"></input>
                                    </div>
                                    <div className='d-flex gap-2'>
                                      <div className='form-group mt-3'>
                                        <label>Expired Date</label>
                                        <input type="text" placeholder="01/30"></input>
                                      </div>
                                      <div className='form-group mt-3'>
                                        <label>CVV</label>
                                        <input type="password" placeholder="***"></input>
                                      </div>
                                    </div>
                                    <div className='form-group mt-3'>
                                      <button type="button" className='deposit_withdraw_btn' >SUBMIT</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </form>
                          :
                          <form className='cahsierForm'>
                            <div className='form-group mt-0'>
                              <label>Amount</label>
                              {depositAmount == 'custom' ?
                                <div className="inputWithIcon">
                                  <input type="text" placeholder="Amount..."></input>
                                </div>
                                : ""
                              }
                            </div>
                            <div className='form-group mt-0'>
                              <div className='d-flex'>
                                <button className={depositAmount == '100' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('100')}>$ 100</button>
                                <button className={depositAmount == '500' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('500')}>$ 500</button>
                                <button className={depositAmount == '1000' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('1000')}>$ 1,000</button>
                                <button className={depositAmount == '2000' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('2000')}>$ 2,000</button>
                                <button className={depositAmount == 'custom' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('custom')}>Custom</button>
                              </div>
                            </div>
                            <div className='d-flex gap-2 login_header mt-3'>
                              <h6 className={depositePaymentMethod == 'card' ? 'active' : ''} onClick={(e) => setDepositePaymentMethod('card')}>Card</h6>
                              <h6 className={depositePaymentMethod == 'crypto' ? 'active' : ''} onClick={(e) => setDepositePaymentMethod('crypto')}>Cryptocurrency</h6>
                              <h6 className={depositePaymentMethod == 'other' ? 'active' : ''} onClick={(e) => setDepositePaymentMethod('other')}>Other</h6>
                              <h6 className={depositePaymentMethod == 'manual' ? 'active' : ''} onClick={(e) => setDepositePaymentMethod('manual')}>Manual</h6>
                            </div>
                            <div className='form-group'>
                              {depositePaymentMethod === 'manual' ?
                                <div>
                                  <div className='form-group mt-0'>
                                    <label>Deposit Receipt</label>
                                    <div className="inputWithIcon mt-1">
                                      <input type="file"></input>
                                    </div>
                                  </div>
                                  <div className='form-group mt-3'>
                                    <button type="button" className='deposit_withdraw_btn' >DEPOSIT</button>
                                  </div>
                                </div>
                                : depositePaymentMethod === 'card' ?
                                  <div>
                                    {isMobile ?
                                      <div >
                                        <div className='d-flex'>
                                          <button className={depositeCard === 'visa' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="visa" onClick={(e) => setDepositeCard(e.target.id)}>
                                            <div className='icon'><img src={'../Images/visa.png'} id="visa" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                            <p className='payment-method-name' id="visa" onClick={(e) => setDepositeCard(e.target.id)}>VISA</p>
                                          </button>
                                          <button className={depositeCard === 'master' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="master" onClick={(e) => setDepositeCard(e.target.id)}>
                                            <div className='icon'><img src={'../Images/mastercard.png'} id="master" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                            <p className='payment-method-name' id="master" onClick={(e) => setDepositeCard(e.target.id)}>MasterCard</p>
                                          </button>
                                        </div>
                                        <div className='d-flex'>
                                          <button className={depositeCard === 'maestro' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="maestro" onClick={(e) => setDepositeCard(e.target.id)}>
                                            <div className='icon'><img src={'../Images/maestro.png'} id="maestro" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                            <p className='payment-method-name' id="maestro" onClick={(e) => setDepositeCard(e.target.id)}>Maestro</p>
                                          </button>
                                          <button className={depositeCard === 'americanexpress' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="americanexpress" onClick={(e) => setDepositeCard(e.target.id)}>
                                            <div className='icon'><img src={'../Images/americanexpress.png'} id="americanexpress" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                            <p className='payment-method-name' id="americanexpress" onClick={(e) => setDepositeCard(e.target.id)}>American Express</p>
                                          </button>
                                        </div>
                                      </div>
                                      :
                                      <div className='d-flex'>
                                        <button className={depositeCard === 'visa' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="visa" onClick={(e) => setDepositeCard(e.target.id)}>
                                          <div className='icon'><img src={'../Images/visa.png'} id="visa" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                          <p className='payment-method-name' id="visa" onClick={(e) => setDepositeCard(e.target.id)}>VISA</p>
                                        </button>
                                        <button className={depositeCard === 'master' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="master" onClick={(e) => setDepositeCard(e.target.id)}>
                                          <div className='icon'><img src={'../Images/mastercard.png'} id="master" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                          <p className='payment-method-name' id="master" onClick={(e) => setDepositeCard(e.target.id)}>MasterCard</p>
                                        </button>
                                        <button className={depositeCard === 'maestro' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="maestro" onClick={(e) => setDepositeCard(e.target.id)}>
                                          <div className='icon'><img src={'../Images/maestro.png'} id="maestro" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                          <p className='payment-method-name' id="maestro" onClick={(e) => setDepositeCard(e.target.id)}>Maestro</p>
                                        </button>
                                        <button className={depositeCard === 'americanexpress' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="americanexpress" onClick={(e) => setDepositeCard(e.target.id)}>
                                          <div className='icon'><img src={'../Images/americanexpress.png'} id="americanexpress" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                          <p className='payment-method-name' id="americanexpress" onClick={(e) => setDepositeCard(e.target.id)}>American Express</p>
                                        </button>
                                      </div>
                                    }
                                    <div className='form-group mt-3'>
                                      <label>Bonus Deposite Code</label>
                                      <input type="text" placeholder="Bonus Deposite Code"></input>
                                    </div>
                                    <div className='form-group mt-3'>
                                      <button type="button" className='deposit_withdraw_btn' onClick={(e) => setIsCardMethodSelected(true)}>DEPOSIT</button>
                                    </div>
                                  </div>
                                  : depositePaymentMethod === 'crypto' ?
                                    <div>
                                      <div className='d-flex'>
                                        <button className={depositeCard === 'bitcoin' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="bitcoin" onClick={(e) => setDepositeCard(e.target.id)}>
                                          <div className='icon'><img src={'../Images/bitcoin.png'} id="bitcoin" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                          <p className='payment-method-name' id="bitcoin" onClick={(e) => setDepositeCard(e.target.id)}>Bitcoin</p>
                                        </button>
                                      </div>
                                      <div className='form-group mt-3'>
                                        <label>Bonus Deposite Code</label>
                                        <input type="text" placeholder="Bonus Deposite Code"></input>
                                      </div>
                                      <div className='form-group mt-3'>
                                        <button type="button" className='deposit_withdraw_btn' >DEPOSIT</button>
                                      </div>
                                    </div>
                                    : depositePaymentMethod === 'other' ?
                                      <div>
                                        <div className='d-flex'>
                                          <button className={depositeCard === 'paypal' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="paypal" onClick={(e) => setDepositeCard(e.target.id)}>
                                            <div className='icon'><img src={'../Images/paypal.png'} id="paypal" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                            <p className='payment-method-name' id="paypal" onClick={(e) => setDepositeCard(e.target.id)}>Paypal</p>
                                          </button>
                                        </div>
                                        <div className='form-group mt-3'>
                                          <label>Bonus Deposite Code</label>
                                          <input type="text" placeholder="Bonus Deposite Code"></input>
                                        </div>
                                        <div className='form-group mt-3'>
                                          <button type="button" className='deposit_withdraw_btn' >DEPOSIT</button>
                                        </div>
                                      </div>
                                      : ''}
                            </div>

                          </form>
                        }
                      </div>
                      <div className={isActive.table === 'cashout' ? `cashout cashier-box` : 'cashout d-none'}>
                        <form className='cahsierForm'>
                          <div className='form-group mt-0'>
                            <label>Amount</label>
                            {depositAmount == 'custom' ?
                              <div className="inputWithIcon">
                                <input type="text" placeholder="Amount..."></input>
                              </div>
                              : ""
                            }
                          </div>
                          <div className='form-group mt-0'>
                            <div className='d-flex'>
                              <button className={depositAmount == '100' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('100')}>$ 100</button>
                              <button className={depositAmount == '500' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('500')}>$ 500</button>
                              <button className={depositAmount == '1000' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('1000')}>$ 1,000</button>
                              <button className={depositAmount == '2000' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('2000')}>$ 2,000</button>
                              <button className={depositAmount == 'custom' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('custom')}>Custom</button>
                            </div>
                          </div>
                          <div className='d-flex gap-2 login_header mt-3'>
                            <h6 className={depositePaymentMethod == 'bankTransfer' ? 'active' : ''} onClick={(e) => setDepositePaymentMethod('bankTransfer')}>Bank Transform</h6>
                          </div>
                          <div className='form-group'>
                            {depositePaymentMethod === 'card' ?
                              <div>
                                <div className='d-flex'>
                                  <button className={depositeCard === 'visa' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="visa" onClick={(e) => setDepositeCard(e.target.id)}>
                                    <div className='icon'><img src={'../Images/visa.png'} id="visa" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                    <p className='payment-method-name' id="visa" onClick={(e) => setDepositeCard(e.target.id)}>VISA</p>
                                  </button>
                                  <button className={depositeCard === 'master' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="master" onClick={(e) => setDepositeCard(e.target.id)}>
                                    <div className='icon'><img src={'../Images/mastercard.png'} id="master" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                    <p className='payment-method-name' id="master" onClick={(e) => setDepositeCard(e.target.id)}>MasterCard</p>
                                  </button>
                                  <button className={depositeCard === 'maestro' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="maestro" onClick={(e) => setDepositeCard(e.target.id)}>
                                    <div className='icon'><img src={'../Images/maestro.png'} id="maestro" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                    <p className='payment-method-name' id="maestro" onClick={(e) => setDepositeCard(e.target.id)}>Maestro</p>
                                  </button>
                                  <button className={depositeCard === 'americanexpress' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="americanexpress" onClick={(e) => setDepositeCard(e.target.id)}>
                                    <div className='icon'><img src={'../Images/americanexpress.png'} id="americanexpress" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                    <p className='payment-method-name' id="americanexpress" onClick={(e) => setDepositeCard(e.target.id)}>American Express</p>
                                  </button>
                                </div>
                                <div className='form-group mt-3'>
                                  <button type="button" className='deposit_withdraw_btn' >WITHDRAW</button>
                                </div>
                              </div>
                              : depositePaymentMethod === 'bankTransfer' ?
                                <div>
                                  <div className='form-group mt-0'>
                                    <label>Bank Name</label>
                                    <input type="text" placeholder=" Enter Bank Name"></input>
                                  </div>
                                  <div className='form-group mt-0'>
                                    <label>Bank Account Number</label>
                                    <input type="text" placeholder="Enter Bank Account Number"></input>
                                  </div>
                                  <div className='form-group mt-0'>
                                    <label>Bank Code</label>
                                    <input type="text" placeholder="Enter Bank Code"></input>
                                  </div>
                                  <div className='form-group mt-3'>
                                    <button type="button" className='deposit_withdraw_btn' >WITHDRAW</button>
                                  </div>
                                </div>
                                : ''}
                          </div>

                        </form>
                      </div>
                      <div className={isActive.table === 'redeemCode' ? `redeemCode cashier-box` : 'redeemCode d-none'}>
                        <form className='cahsierForm'>
                          <div className='form-group mt-0'>
                            <label>Promotional Or Bonus Code</label>
                            <div className="inputWithIcon">
                              <input type="text"
                                id="code"
                                value={codeForm.code}
                                onChange={handleChange}
                                placeholder="Enter Bonus Code" />
                              {/* <span className='invalid-text'>Codee has beed expired</span> */}
                            </div>
                          </div>
                          <div className='form-group'>
                            <div>
                              <div className='form-group mt-3'>
                                <button type="button" className='deposit_withdraw_btn' onClick={() => reedemCode()}>REEDEM CODE</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className={isActive.table === 'lockedBonus' ? `lockedBonus cashier-box` : 'lockedBonus d-none'}>
                        <div className='cahsierForm'>
                          <table className='table p-lb-table table-borderless'>
                            <thead>
                              <tr>
                                <th>Sr No.</th>
                                <th>Code</th>
                                <th>Created Date</th>
                                <th>Bonus Amount</th>
                                <th>Expiry Date</th>
                                <th>Status</th>
                                <th>Claim</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lockedBonusHistory.length > 0 ? lockedBonusHistory.map((history, index) => {
                                return (
                                  <tr key={index}>
                                    <th>{index + 1}</th>
                                    <th>{history.name}</th>
                                    <th>{moment(history.createdAt).format("DD MMM, YYYY")}</th>
                                    <th>{history.amount}</th>
                                    <th>{moment(history.valid_till).format("DD MMM, YYYY")}</th>
                                    <th>{history.status}</th>
                                    <th>{history.status != 'Claimed' ?
                                      <button type="button" className='claim_btn' onClick={() => claimCode(history._id)}>Claim</button> : '-'}</th>
                                  </tr>
                                );
                              }) : <tr>
                                <td colSpan="7">No Data Found</td>
                              </tr>}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {isLoading && userProfileEdit ?
          <div>
            <div>
              {userProfilePicOption.map((pic, index) => {
                return userProfilePhoto == index ?
                  <img onClick={() => setUserProfilePhoto(index)} key={index} src={playerProfilePhoto(index)} className='plyrProfilePic activeProfile'></img> :
                  <img onClick={() => setUserProfilePhoto(index)} key={index} src={playerProfilePhoto(index)} className='plyrProfilePic'></img>
              })}
            </div>
            <div className='avtarBtnSection'>
              <button className='edtAvtarBtn' onClick={() => changePlayerAvtar(0)}>Update</button>
              <button className='edtAvtarBtn' onClick={() => changePlayerAvtar(1)}>Cancle</button>
            </div>
          </div>
          : isLoading && changePasswordForm ?
            <div className='plyerProfile'>
              <center>
                <form>
                  <div className='form-group'>
                    <label>Current Password</label>
                    <input className='form-control'></input>
                  </div>
                  <div className='form-group'>
                    <label>New Password</label>
                    <input className='form-control'></input>
                  </div>
                  <div className='form-group'>
                    <label>Confirm Password</label>
                    <input className='form-control'></input>
                  </div>
                  <div className='avtarBtnSection'>
                    <button className='edtAvtarBtn' onClick={() => changePassword(0)}>Change</button>
                    <button className='edtAvtarBtn' onClick={() => changePassword(1)}>Cancle</button>
                  </div>
                </form>
              </center>
            </div>
            : isLoading ?
              
              : <div className="modalLoading" ><img src={'../Images/Load.gif'}></img></div>
        } */}
      </Modal >
    </div >
  );
};

export default Cashier

