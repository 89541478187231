import React from 'react'
import { useState, useEffect, useRef } from 'react'
import '../Css/Lobby.css';
import CashGameTable from '../Components/CashGameTable';
import TournamentGameTable from '../Components/TournamentGameTable';
import BegineerGameTable from '../Components/BegineerGameTable';
import CashierGameTable from '../Components/CashierGameTable';
import SitNGoGameTable from '../Components/SitNGoGameTable';
import InfoTable from '../Components/InfoTable';
import MyInfo from '../Components/MyInfo';
import { useNavigate } from "react-router-dom";
import UserProfile from '../Images/UserProfile.png'
import moment from 'moment';
import socket from '../socket';
// import Modal from 'react-modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Cashier from '../Components/Cashier';
import MyAccount from '../Components/MyAccount';
import VIPLevel from '../Components/VIPLevel';

const LobbyScreen = () => {
  localStorage.removeItem("tableData");
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState({
    table: 'cashGame'
  })
  const [roomDetails, setNewRoomDetails] = useState([]);
  const [isRoomDetails, setIsRoomDetails] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [time, setTime] = useState('00:00');
  const [userData, setUserData] = useState({});
  const [gameData, setGameData] = useState({});
  const [sidebarWidth, setSidebarWidth] = useState({});
  const [sidebarMobileWidth, setSidebarMobileWidth] = useState({});
  const [isCashierOpen, setIsCashierOpen] = useState(false);
  const [isMyProfileOpen, setIsMyProfileOpen] = useState(false);
  const [isVipLevelOpen, setIsVipLevelOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false)
  const [interval, setIntervalId] = useState('')
  const [userLocation, setUserLocation] = useState('');
  const [isAllowed, setIsAllowed] = useState(true);
  const [hourRemainingPopup, setHourRemainingPopup] = useState(false);
  const [thirtyMinRemainingPopup, setThirtyMinRemainingPopup] = useState(true);
  const [twentyMinRemainingPopup, setTwentyMinRemainingPopup] = useState(true);
  const [tenMinRemainingPopup, setTenMinRemainingPopup] = useState(true);
  const [hourRemainingPopupMsg, setHourRemainingPopupMsg] = useState('');
  const [isTableInfo, setTableInfo] = useState({
    tableInfo: false,
    myInfo: true
  })

  const elementRef = useRef(null);


  useEffect(() => {
    const mobile = window.innerWidth <= 1024;
    setIsMobile(mobile);
  }, [window.innerWidth]);


  useEffect(() => {
    setInterval(() => setTime(moment().format('hh:mm A')), 1000);
    const user = JSON.parse(localStorage.getItem('user'));
    setIsLoader(false);
    if (!user) {
      navigate('/')
    } else {
      const data = {
        playerId: user.playerId,
        productName: 'BytePoker'
      }
      socket.emit('gamePlayedLastTime', data, (res) => {
      });
      // socket.on('balanceHourRemaining', (e) => {
      //   console.log('balanceHourRemaining')
      //   console.log(e)
      // });

      socket.emit('gameData', data, (res) => {
        if (res.status == 'success') {
          setGameData(res.result);
        }
      });

      socket.emit('Playerprofile', data, (res) => {
        localStorage.setItem('user', JSON.stringify(res.result));
        console.log(res.result);
        setUserData(res.result);
      });

      const intervalId = setInterval(() => {
        socket.emit('gamePlayedLastTime', data, (res) => {
        });
        socket.emit('Playerprofile', data, (res) => {
          localStorage.setItem('user', JSON.stringify(res.result));
          setUserData(res.result);
          if (res.result.hour_balance == "00:30") {
            if (thirtyMinRemainingPopup == true) {
              setHourRemainingPopup(true);
              setHourRemainingPopupMsg('You are running low balance of hour. Please recharge your playing hour.');
            }
          }
          if (res.result.hour_balance == "00:20") {
            if (twentyMinRemainingPopup) {
              setHourRemainingPopup(true);
              setHourRemainingPopupMsg('You are running low balance of hour. Please recharge your playing hour.');
            }
          }
          if (res.result.hour_balance == "00:10") {
            if (tenMinRemainingPopup) {
              setHourRemainingPopup(true);
              setHourRemainingPopupMsg('You are running low balance of hour. Please recharge your playing hour.');
            }
          }
          // if (res.result.hour_balance == "00:00") {
          //   setHourRemainingPopup(true);
          //   setHourRemainingPopupMsg('You are running low balance of hour. Please buy hour to continue play.');
          // }
        });
      }, 5000);

      const fetchUserLocation = async () => {
        try {
          const response = await fetch('https://ipinfo.io/?token=09898bb4ccfadf');
          const data = await response.json();
          setUserLocation(data.region);
        } catch (error) {
          console.error('Error fetching user location:', error);
        }
      };
      fetchUserLocation();

      setIntervalId(intervalId);

      return () => clearInterval(intervalId)
    }
  }, [thirtyMinRemainingPopup, twentyMinRemainingPopup, tenMinRemainingPopup]);


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const closeReminderPopup = async () => {
    setHourRemainingPopup(false);
    if (thirtyMinRemainingPopup == true) {
      setThirtyMinRemainingPopup(false);
    }
    if (twentyMinRemainingPopup == true) {
      setTwentyMinRemainingPopup(false);
    }
    if (tenMinRemainingPopup == true) {
      setTenMinRemainingPopup(false);
    }
  }

  useEffect(() => {
    // Check if the user is in Texas
    if (userLocation !== 'TX') {
      setIsAllowed(true);
    } else {
      setIsAllowed(true);
    }
  }, [userLocation]);


  const handleFullScreen = () => {
    if (elementRef.current) {
      if (elementRef.current.requestFullscreen) {
        elementRef.current.requestFullscreen();
      } else if (elementRef.current.mozRequestFullScreen) { // Firefox
        elementRef.current.mozRequestFullScreen();
      } else if (elementRef.current.webkitRequestFullscreen) { // Chrome, Safari, and Opera
        elementRef.current.webkitRequestFullscreen();
      } else if (elementRef.current.msRequestFullscreen) { // IE/Edge
        elementRef.current.msRequestFullscreen();
      }
    }
  };

  const setRoomDetails = (e) => {
    setNewRoomDetails(e)
    setIsRoomDetails(true)
  }

  const hideShowDiv = (e) => {
    if (isActive.table != e.target.id) {
      setIsActive({
        table: e.target.id,
      })
      setNewRoomDetails({})
      setIsRoomDetails(false)
    }
  }
  const tableInfoFunc = (e) => {
    setTableInfo({
      tableInfo: true,
    })
  }
  const myInfoFunc = (e) => {
    setTableInfo({
      tableInfo: false,
      myInfo: true
    })
  }
  const hideRoomInfo = () => {
    setNewRoomDetails({})
    setIsRoomDetails(false)
  }
  const refreshData = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    const data = {
      playerId: user.playerId,
      productName: 'BytePoker'
    }

    socket.emit('Playerprofile', data, (res) => {
      localStorage.setItem('user', JSON.stringify(res.result));
      setUserData(res.result);
    });
  }

  const playerProfilePhoto = (img) => {
    return '../Images/Avtar/' + (img + 1) + '.png'
  }

  const openSideBar = () => {
    setSidebarWidth({ width: '20.5%' });
    setSidebarMobileWidth({ width: '100%' });
  }

  const closeSideBar = () => {
    setSidebarWidth({ width: '0%' });
    setSidebarMobileWidth({ width: '0%' });
  }

  const openCashier = () => {
    setIsCashierOpen(true);
    closeSideBar();
  }

  const closeCashier = () => {
    setIsCashierOpen(false)
  }

  const openMyProfile = () => {
    setIsMyProfileOpen(true);
    closeSideBar();
  }

  const closeMyProfile = () => {
    setIsMyProfileOpen(false);
    closeSideBar();
  }

  const openMyVipLevel = () => {
    setIsVipLevelOpen(true);
    closeSideBar();
  }

  const closeMyVipLevel = () => {
    setIsVipLevelOpen(false);
    closeSideBar();
  }

  const logout = (e) => {
    localStorage.removeItem("user");
    navigate('/')
  }

  let customStylesLogout = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#242234',
      width: '40%',
      height: '50%',
      border: '1px solid #8025F3'
    },
  };

  if (isMobile) {
    customStylesLogout = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#242234',
        width: '60%',
        height: '30%',
        border: '1px solid #8025F3'
      },
    };
  }

  return (
    <div ref={elementRef}>

      {isAllowed ? (
        <div>
        </div>
      ) : (
        <div>
          <Modal
            isOpen={true}
            style={customStylesLogout}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <div className={isMobile ? "gameModel mobileView" : 'gameModel'}>
              <div className='modalBody'>
                <center>
                  <h2 className='color-1'>Warning!</h2>
                  <h4 className='color-1 mt-4'>Sorry, this game is only available to Texas residents.</h4>
                  <div className='avtarBtnSection'>
                    <button className='selectBuyInBtn' onClick={() => logout()}>Logout</button>
                  </div>
                </center>
              </div>
            </div>
          </Modal>
        </div>
      )}
      {hourRemainingPopup ? (
        <div>
          <Modal
            isOpen={true}
            style={customStylesLogout}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <div className={isMobile ? "gameModel mobileView" : 'gameModel'}>
              <div className='modalBody'>
                <center>
                  <h2 className='color-1'>Warning!</h2>
                  <h4 className='color-1 mt-4'>{hourRemainingPopupMsg}</h4>
                  <div className='avtarBtnSection'>
                    <button className='selectBuyInBtn' onClick={() => closeReminderPopup()}>Close</button>
                  </div>
                </center>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <div></div>
      )}
      {isMobile ?
        <div className='mobileView'>
          <div id="mySidenav" className="sidenav" style={sidebarMobileWidth}>
            <a href="javascript:void(0)" className="sideBarClosebtn" onClick={() => closeSideBar()}>&times;</a>
            <center>
              <div className='sidebarUserImg'>
                <img src={playerProfilePhoto(userData ? userData.avatar : '')} ></img>
                <div className='userInfo'>
                  <p className='userName'>{userData ? userData.username : ''}</p>
                  <p className='userBalance'>$ {userData ? userData.chips?.toFixed(2) : 0.00}</p>
                </div>
                {/* <div className='timeInfo'>
                  <p className='userTime'>Remaining Time : {userData ? userData.hour_balance : '00.00'}</p>
                </div> */}
              </div>
            </center>
            <MyInfo refreshData={refreshData} closeSideBar={closeSideBar} openSideBar={openSideBar}></MyInfo>
          </div>

          {isLoader ? <div className='loader'>
            <img src={'../Images/Loader.gif'}></img>
          </div>
            : ''
          }

          <div className='lobby-page p-1'>
            <div className='p-relative'>
              <div className='mbuserProfile d-flex gap-2'>
                <div className='brandLogo'>
                  <img src={'../Images/logo.png'} ></img>
                </div>
                <div className='img'>
                  <img src={playerProfilePhoto(userData ? userData.avatar : '')} ></img>
                </div>
                <div className='userInfo'>
                  <p className='mbUserName'>{userData ? userData.username : ''}</p>
                  <p className='mbUserBalance'>$ {userData ? userData.chips?.toFixed(2) : ''}</p>
                </div>
                <a href="javascript:void(0)" className="mbsideBarMenubtn" onClick={() => openSideBar()}>
                  <img src={'../Images/Menu.png'}></img>
                </a>
              </div>
            </div>
            <div className='d-flex gap-2 p-relative z-index-0'>
              <button className={isActive.table === 'cashGame' ? `btn p-hd-fill-btn mt-2 active` : 'btn p-hd-fill-btn mt-2'} id="cashGame"
                onClick={(e) => {
                  hideShowDiv(e)
                }}>Cash Games</button>
              <button className={isActive.table === 'tournamentGame' ? `btn p-hd-fill-btn mt-2 active` : 'btn p-hd-fill-btn mt-2'} id="tournamentGame"
                onClick={(e) => {
                  hideShowDiv(e)
                }}>Tournaments</button>
              <button className={isActive.table === 'sitngoGame' ? `btn p-hd-fill-btn mt-2 active` : 'btn p-hd-fill-btn mt-2'} id="sitngoGame"
                onClick={(e) => {
                  hideShowDiv(e)
                }}>Sit & Go</button>
              <button className={isActive.table === 'cashierTable' ? `btn p-hd-fill-btn mt-2  active` : 'btn p-hd-fill-btn mt-2 '} id="cashierTable"
                onClick={(e) => {
                  openCashier(e)
                }}>Cashier</button>
            </div>
            <div className='h-100'>
              <div className={isActive.table === 'cashGame' ? `cashGame` : 'cashGame d-none'}>
                {isActive.table === 'cashGame' ? <CashGameTable mobileView={isMobile} setRoomDetails={setRoomDetails} isLoader={setIsLoader}></CashGameTable> : ''}
              </div>
              <div className={isActive.table === 'tournamentGame' ? `tournamentGame` : 'tournamentGame d-none'}>
                {isActive.table === 'tournamentGame' ? <TournamentGameTable mobileView={isMobile} setRoomDetails={setRoomDetails} isLoader={setIsLoader}></TournamentGameTable> : ''}
              </div>
              <div className={isActive.table === 'sitngoGame' ? `sitngoGame` : 'sitngoGame d-none'}>
                {isActive.table === 'sitngoGame' ? <SitNGoGameTable mobileView={isMobile} setRoomDetails={setRoomDetails} isLoader={setIsLoader}></SitNGoGameTable> : ''}
              </div>
              <div className={isActive.table === 'cashierTable' ? `cashierTable` : 'cashierTable d-none'}>
                <CashierGameTable mobileView={isMobile} tableInfoFunc={tableInfoFunc} activeTab={'allTra'}></CashierGameTable>
              </div>
              {isCashierOpen ?
                <Cashier closeCashier={closeCashier}></Cashier> :
                ''}
              {/* <div className='mbtextSection mt-2'>
                <h4>Monday, Participate for the tournament just at $ 12 USD.</h4>
                <p>Till now, 1400 players have  been registered. </p>
                <h4>Win the Biggest Jackpot !</h4>
                <p>Total, $ 60,000 USD . Play more hands, Increase the chance to be a winner.</p>
              </div> */}
            </div>
          </div>

          <div className='lobbyFooter'>
            <div className='footerLeft'>
              {/* <img className='m-0' src={'../Images/fullscreen.png'} onClick={() => handleFullScreen()}></img> */}
              <img className='m-0' src={'../Images/fullscreen.png'} ></img>
              <img src={'../Images/wifi.png'}></img>
              <img src={'../Images/clock.png'}></img>  <span className='footerText'>{time}</span>
              <img src={'../Images/users.png'}></img>  <span className='footerText'>{gameData.players ? gameData.players : 0} Players </span>
              <img src={'../Images/tables.png'}></img>  <span className='footerText'>{gameData.table ? gameData.table : 0} Tables </span>
              <img src={'../Images/tournaments.png'}></img>  <span className='footerText'>{gameData.tournament ? gameData.tournament : 0} Tournaments </span>
              <img src={'../Images/Timer.png'}></img>  <span className='footerText timeLeftText'>Time Left : <span className="timeLeftFont">{userData ? userData.hour_balance : '00.00'} </span></span>
            </div>
            <div className='footerRight'>
              <span className='footerText text-mute mr-1'>v10.7.2.78c54b9</span>
            </div>
          </div>
        </div>
        :
        <div>
          <div id="mySidenav" className="sidenav" style={sidebarWidth}>
            <a href="javascript:void(0)" className="sideBarClosebtn" onClick={() => closeSideBar()}>&times;</a>
            <center>
              <div className='sidebarUserImg'>
                <img src={playerProfilePhoto(userData ? userData.avatar : '')} ></img>
                <div className='userInfo'>
                  <p className='userName'>{userData ? userData.username : ''}</p>
                  <p className='userBalance'>$ {userData ? userData.chips?.toFixed(2) : 0.00}</p>
                </div>
                {/* <div className='timeInfo'>
                  <p className='userTime'>Remaining Time : {userData ? userData.hour_balance : '00.00'}</p>
                </div> */}
              </div>
            </center>
            <MyInfo refreshData={refreshData} closeSideBar={closeSideBar} openSideBar={openSideBar}></MyInfo>
          </div>

          {isLoader ? <div className='loader'>
            <img src={'../Images/Loader.gif'}></img>
          </div>
            : ''
          }
          <div className='lobby-page'>
            <div className='d-flex'>
              <div className='p-col-9 min-h-100'>
                <div className='d-flex min-h-100'>
                  <div className='p-col-3 min-h-100'>
                    <div className='pokerLogo  min-h-100 text-center'>
                      <img src={'../Images/logo.png'}></img>
                    </div>
                  </div>
                  <div className='p-col-9 mx-2 min-h-100'>
                    {/* <div className='d-flex gap-2'>
                  <div className='p-col-3'>
                    <button className='btn p-hd_btn' ></button>
                  </div>
                  <div className='p-col-3 min-h-100'>
                    <button className='btn p-hd_btn' ></button>
                  </div>
                  <div className='p-col-3 min-h-100'>
                    <button className='btn p-hd_btn' ></button>
                  </div>
                  <div className='p-col-3 min-h-100'>
                    <button className='btn p-hd_btn' ></button>
                  </div>
                </div> */}
                    <div className='d-flex gap-2 min-h-100'>
                      <div className='p-col-6'>
                      </div>

                      <div className='p-col-4 min-h-100 text-center'>

                        {/* <div className='playerLeftHours text-center'>
                          <img className="timeIcon" src={'../Images/Icons/Watch.png'} />
                        </div> */}
                        {/* <div className='timeInfo'>
                          <p className='userTime'>Remaining Time : {userData ? userData.hour_balance : '00.00'}</p>
                        </div> */}
                      </div>
                      {/* <div className='p-col-3 min-h-100'>
                        <div className='leftTime p-relative  min-h-100 text-center'>
                          <img src={'../Images/clock.png'} ></img>
                          <span>{userData ? userData.hour_balance : '00.00'}</span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='p-col-2-5 ms-0 max-height-80 p-relative  min-h-100'>
                <div className='userProfile d-flex gap-3  min-h-100'>
                  <a href="javascript:void(0)"  onClick={() => openMyVipLevel()} className={'playerProfileWithFrameLobby vipLevelLobby' + userData.vipLevel} >
                    <img src={playerProfilePhoto(userData ? userData.avatar : '')} className='plyrProfilePicMain'></img>
                  </a>
                  {/* <div className='img'>
                    <img src={playerProfilePhoto(userData ? userData.avatar : '')} ></img>
                  </div> */}
                  <div className='userInfo'>
                    <p className='userName'>{userData ? userData.username : ''}</p>
                    <p className="userVipLevel">
                      <span className='userBalance'>$ {userData ? userData.chips?.toFixed(2) : ''} </span>
                      <b>({userData.vipLevel == 5 ? 'Diamond' : userData.vipLevel == 4 ? 'Platinum' : userData.vipLevel == 3 ? 'Gold' : userData.vipLevel == 2 ? 'Silver' : 'Bronze'})</b>
                    </p>
                  </div>
                  <a href="javascript:void(0)" className="sideBarMenubtn" onClick={() => openMyProfile()}>
                    <img src={'../Images/Setting.png'}></img>
                  </a>
                </div>
              </div>
            </div>
            {isVipLevelOpen ?
              <VIPLevel refreshData={refreshData} closeMyProfile={closeMyVipLevel}></VIPLevel> :
              ''}

            {isMyProfileOpen ?
              <MyAccount refreshData={refreshData} closeMyProfile={closeMyProfile}></MyAccount> :
              ''}
            <div className='d-flex h-80'>
              <div className='p-col-9 min-h-100 p-relative'>
                <div className='buttonHeader'>
                  <div className='d-flex gap-2'>
                    <div className='p-col-2 ms-0 p-relative'>
                      <button className={isActive.table === 'cashGame' ? `btn p-hd-fill-btn active` : 'btn p-hd-fill-btn'} id="cashGame"
                        onClick={(e) => {
                          hideShowDiv(e)
                        }}>Cash Games</button>
                    </div>
                    <div className='p-col-2 ms-0 p-relative'>
                      <button className={isActive.table === 'tournamentGame' ? `btn p-hd-fill-btn active` : 'btn p-hd-fill-btn'} id="tournamentGame"
                        onClick={(e) => {
                          hideShowDiv(e)
                        }}>Tournaments</button>
                    </div>
                    <div className='p-col-2 ms-0 p-relative'>
                      <button className={isActive.table === 'sitngoGame' ? `btn p-hd-fill-btn active` : 'btn p-hd-fill-btn'} id="sitngoGame"
                        onClick={(e) => {
                          hideShowDiv(e)
                        }}>Sit & Go</button>
                    </div>
                    <div className='p-col-2 ms-0 p-relative'>
                      <button className={isActive.table === 'cashierTable' ? `btn p-hd-fill-btn active` : 'btn p-hd-fill-btn'} id="cashierTable"
                        onClick={(e) => {
                          openCashier(e)
                        }}>Cashier</button>
                      {/* <button className={isActive.table === 'cashierTable' ? `btn p-hd-fill-btn active` : 'btn p-hd-fill-btn'} id="cashierTable"
                        onClick={handleShow}>Cashier</button> */}
                    </div>
                  </div>
                </div>
                {isCashierOpen ?
                  <Cashier closeCashier={closeCashier}></Cashier> :
                  ''}
                {/* <CashierGameTable tableInfoFunc={tableInfoFunc} activeTab={'allTra'}></CashierGameTable> */}
                {/* <div className='gameTitle'>
          </div> */}
                <div className='d-flex h-100'>
                  {/* <div className='p-col-3 p-relative'>
                    <button className={isActive.table === 'cashGame' ? `btn p-hd-fill-btn mt-2 active` : 'btn p-hd-fill-btn mt-2'} id="cashGame"
                      onClick={(e) => {
                        hideShowDiv(e)
                      }}>Cash Games</button>
                  
                   
                   
                    <div className='bannerImage mt-2'>
                      <div className='blinkImage'></div>
                    </div>
                  </div> */}
                  <div className='p-col-12 lobbyListing'>
                    <div className={isActive.table === 'cashGame' ? `cashGame` : 'cashGame d-none'}>
                      {isActive.table === 'cashGame' ? <CashGameTable setRoomDetails={setRoomDetails} isLoader={setIsLoader}></CashGameTable> : ''}
                    </div>
                    <div className={isActive.table === 'tournamentGame' ? `tournamentGame` : 'tournamentGame d-none'}>
                      {isActive.table === 'tournamentGame' ? <TournamentGameTable setRoomDetails={setRoomDetails} isLoader={setIsLoader}></TournamentGameTable> : ''}
                    </div>
                    <div className={isActive.table === 'sitngoGame' ? `sitngoGame` : 'sitngoGame d-none'}>
                      {isActive.table === 'sitngoGame' ? <SitNGoGameTable setRoomDetails={setRoomDetails} isLoader={setIsLoader}></SitNGoGameTable> : ''}
                    </div>
                    {/* <div className={isActive.table === 'cashierTable' ? `cashierTable` : 'cashierTable d-none'}>
                      <CashierGameTable tableInfoFunc={tableInfoFunc} activeTab={'allTra'}></CashierGameTable>
                    </div> */}
                    {/* <div className='textSection mt-2'>
                      <h4>Monday, Participate for the tournament just at $ 12 USD.</h4>
                      <p>Till now, 1400 players have  been registered. </p>
                      <h4>Win the Biggest Jackpot !</h4>
                      <p>Total, $ 60,000 USD . Play more hands, Increase the chance to be a winner.</p>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className='p-col-2-5 ms-0 p-relative'>
                <div className='lobbySideBox'>
                  <div className='buttonHeader'>
                    <div className='d-flex gap-2'>
                      <div className='p-col-12 ms-0 p-relative'>
                        <img src={'../Images/TopAdd.png'} width={'100%'}></img>
                      </div>
                    </div>

                  </div>
                  <div className='table-my-info'>
                    <InfoTable roomDetails={roomDetails} hideRoomInfo={hideRoomInfo}></InfoTable>
                    {/* <div className='bannerImageSec'>
                      <div className='staticImg'></div>
                    </div> */}
                  </div>
                  <div className='d-flex gap-2'>
                    <div className='p-col-12 ms-0 p-relative '>
                      <img src={'../Images/AddBannerSec.jpg'} width={'100%'}></img>
                    </div>
                  </div>
                </div>
                {/* <div className='table-my-info'>
                <MyInfo refreshData={refreshData}></MyInfo>
              </div> */}
              </div>
            </div>
          </div>
          <div className='lobbyFooter'>
            <div className='footerLeft'>
              {/* <img className='m-0' src={'../Images/fullscreen.png'} onClick={() => handleFullScreen()}></img> */}
              <img className='m-0' src={'../Images/fullscreen.png'} ></img>
              <img src={'../Images/wifi.png'}></img>
              <img src={'../Images/clock.png'}></img>  <span className='footerText'>{time}</span>
              <img src={'../Images/users.png'}></img>  <span className='footerText'>{gameData.players ? gameData.players : 0} Players </span>
              <img src={'../Images/tables.png'}></img>  <span className='footerText'>{gameData.table ? gameData.table : 0} Tables </span>
              <img src={'../Images/tournaments.png'}></img>  <span className='footerText'>{gameData.tournament ? gameData.tournament : 0} Tournaments </span>
              <img src={'../Images/Timer.png'}></img>  <span className='footerText timeLeftText'>Time Left : <span className="timeLeftFont">{userData ? userData.hour_balance : '00.00'} </span></span>
            </div>
            <div className='footerRight'>
              <span className='footerText text-mute mr-1'>v10.7.2.78c54b9</span>
            </div>
          </div>
        </div>
      }
      {/* 
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        <Modal.Body >
          <h4>Centered Modal</h4>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
            consectetur ac, vestibulum at eros.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default LobbyScreen

