import React from 'react'
import '../Css/Login.css';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react'
import socket from '../socket';
import LoginImg from '../Images/LoginLogo12.png'
import axios from 'axios';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { ToastContainer, toast } from 'react-toastify';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

const INITIAL_LOGIN_STATE = {
  username: '',
  password: '',
};

const LOGIN_VALIDATION = {
  username: [
    {
      isValid: (value) => !!value,
      message: 'Username is required.',
    },
  ],
  password: [
    {
      isValid: (value) => !!value,
      message: 'Password is required.',
    },
  ],
};

const INITIAL_SIGNUP_STATE = {
  username: '',
  phone_number: '',
  password: '',
  confirm_password: '',
};

const SIGNUP_VALIDATION = {
  username: [
    {
      isValid: (value) => !!value,
      message: 'Username is required.',
    },
  ],
  phone_number: [
    {
      isValid: (value) => !!value,
      message: 'Phone Number is required.',
    },
  ],
  password: [
    {
      isValid: (value) => !!value,
      message: 'Password is required.',
    },
  ],
  confirm_password: [
    {
      isValid: (value) => !!value,
      message: 'Confirm Password is required.',
    },
  ],
};

const getLoginErrorFields = (form) =>
  Object.keys(form).reduce((acc, key) => {
    if (!LOGIN_VALIDATION[key]) return acc;
    const errorsPerField = LOGIN_VALIDATION[key]
      .map((validation) => ({
        isValid: validation.isValid(form[key]),
        message: validation.message,
      }))
      .filter((errorPerField) => !errorPerField.isValid);
    return { ...acc, [key]: errorsPerField };
  }, {});


const getSignupErrorFields = (form) =>
  Object.keys(form).reduce((acc, key) => {
    if (!SIGNUP_VALIDATION[key]) return acc;
    const errorsPerField = SIGNUP_VALIDATION[key]
      .map((validation) => ({
        isValid: validation.isValid(form[key]),
        message: validation.message,
      }))
      .filter((errorPerField) => !errorPerField.isValid);
    return { ...acc, [key]: errorsPerField };
  }, {});

const LandingScreen = () => {
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState(INITIAL_LOGIN_STATE);
  const [signupForm, setSignupForm] = useState(INITIAL_SIGNUP_STATE);
  const [isLoginError, setLoginErrors] = useState(false);
  const [isSignupError, setSignupErrors] = useState(false);
  const [resErrors, serResErrors] = useState();
  const [resSuccess, setResSuccess] = useState();
  const errorLoginFields = getLoginErrorFields(loginForm);
  const errorSignupFields = getSignupErrorFields(signupForm);
  const userData = JSON.parse(localStorage.getItem('user'));
  const [isMobile, setIsMobile] = useState(false)
  const [isActiveLogin, setIsActiveLogin] = useState('login')
  useEffect(() => {
    const mobile = window.innerWidth <= 1024;
    setIsMobile(mobile);
    if (userData) {
      navigate('/lobby')
    }
  }, [])

  const handleLoginChange = (event) => {
    setLoginForm({
      ...loginForm,
      [event.target.id]: event.target.value,
    });
  };

  const handleSignupChange = (event) => {
    setSignupForm({
      ...signupForm,
      [event.target.id]: event.target.value,
    });
  };

  const changeView = (type) => {
    if (type == 'login') {
      setIsActiveLogin(type);
      setLoginErrors(false);
    }
    if (type == 'signup') {
      setIsActiveLogin(type);
      setSignupErrors(false);
    }
    if (type == 'connect') {
      setIsActiveLogin(type);
      setLoginErrors(false);
      setSignupErrors(false);
    }
  }

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    const hasErrors = Object.values(errorLoginFields).flat().length > 0;
    if (hasErrors) {
      setLoginErrors(true)
      return;
    } else {
      const data = {
        os: 'other',
        appVersion: '2.0',
        username: loginForm.username,
        password: loginForm.password,
        forceLogin: true,
      }
      socket.emit('LoginPlayer', data, (response) => {
        if (response.status == 'fail') {
          serResErrors(response.message)
        } else {
          serResErrors('');
          localStorage.setItem('user', JSON.stringify(response.result));
          navigate('/lobby')
        }
      });
    }
  };

  const handleSignupSubmit = (event) => {
    event.preventDefault();
    const hasErrors = Object.values(errorSignupFields).flat().length > 0;
    if (hasErrors) {
      setSignupErrors(true)
      return;
    } else {
      const data = {
        os: 'other',
        appVersion: '2.0',
        username: signupForm.username,
        mobile: signupForm.phone_number,
        password: signupForm.password,
        device_id: '4w85u349865neiuyjn38572h385uhwnuit4gn',
      }
      socket.emit('RegisterPlayer', data, (response) => {
        if (response.status == 'fail') {
          serResErrors(response.message)
        } else {
          serResErrors('');
          setResSuccess(response.message);
          setIsActiveLogin(true);
        }
      });
    }
  };

  const responseFacebook = (response) => {
    console.log(response);
  }

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: 'application/json'
          }
        })
        .then((res) => {
          const gData = res.data;
          const data = {
            os: 'other',
            appVersion: '2.0',
            name: gData.name,
            username: gData.name,
            email: gData.email,
            id: gData.id,
            profilePic: gData.picture,
            forceLogin: true,
            verifiedEmail: gData.verified_email,
            device_id: '4w85u349865neiuyjn38572h385uhwnuit4gn',
          }
          socket.emit('LoginWithGoogle', data, (response) => {
            if (response.status == 'fail') {
              toast.error(res.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            } else {
              toast.success(response.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              localStorage.setItem('user', JSON.stringify(response.result));
              navigate('/lobby')
            }
          });
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  return (
    <div className="Root">
      <ToastContainer />
      <div className='lobby-page'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-10'>
              <div className={isMobile ? 'login-box mobile-login-box' : 'login-box'}>
                <div className='login-logo'>
                  <img src={LoginImg} className="gameLogo" />
                </div>
                <div className='d-flex gap-2 login_header'>
                  <h6 className={isActiveLogin == 'login' ? 'active' : ''} onClick={() => changeView('login')}>Login</h6>
                  <h6 className={isActiveLogin == 'signup' ? 'active' : ''} onClick={() => changeView('signup')}>Create Account</h6>
                  <h6 className={isActiveLogin == 'connect' ? 'active' : ''} onClick={() => changeView('connect')}>Connect</h6>
                </div>
                {isActiveLogin == 'login' ?
                  <div>
                    <h4 className='login-header-title mt-4'>Welcome</h4>
                    <div className='mt-4'>
                      {resErrors ? (
                        <span className="error-text">{resErrors}</span>
                      ) : ''}
                      < form onSubmit={handleLoginSubmit}>
                        <div className='form-group'>
                          <label>Email Or Username</label>
                          <input
                            id="username"
                            type="text"
                            value={loginForm.username}
                            onChange={handleLoginChange}
                            placeholder="Enter Email Or Username"
                          />
                          {isLoginError && errorLoginFields.username?.length ? (
                            <span className="error-text">
                              {errorLoginFields.username[0].message}
                            </span>
                          ) : null}
                        </div>
                        <div className='form-group'>
                          <label>Password</label>
                          <input
                            id="password"
                            type="password"
                            value={loginForm.password}
                            onChange={handleLoginChange}
                            placeholder="******"
                          />
                          {isLoginError && errorLoginFields.password?.length ? (
                            <span className="error-text">
                              {errorLoginFields.password[0].message}
                            </span>
                          ) : null}
                        </div>
                        <button type="submit" className='signin_btn' >Login</button>
                        {/* <p className='text-center cursor-pointer' onClick={() => navigate('/')}>Cancel</p> */}

                      </form>

                      <div className='omb_login'>
                        <div className="row omb_row-sm-offset-3 omb_loginOr">
                          <div className="col-xs-12">
                            <hr className="omb_hrOr" />
                            <span className="omb_spanOr">Or continue with</span>
                          </div>
                        </div>
                        <div className='social_btn_box'>
                          <FacebookLogin
                            appId="1088597931155576"
                            callback={responseFacebook}
                            render={renderProps => (
                              <button className='social_btn fb_login' onClick={renderProps.onClick}></button>
                            )}
                          />
                          <button className='social_btn g_login' onClick={() => login()} ></button>
                        </div>
                        <h6 className='extra_text mt-2'>Gambling isn't forbidden by my local authorities and I'm at least 18 years old.</h6>
                      </div>
                    </div>
                  </div> : isActiveLogin == 'signup' ?
                    <div>
                      <h4 className='login-header-title mt-4'>Create an Account</h4>
                      <div className='mt-4'>
                        {resErrors ? (
                          <span className="error-text">{resErrors}</span>
                        ) : ''}
                        {resSuccess ? (
                          <p style={{ color: 'green' }}>{resSuccess}</p>
                        ) : ''}
                        < form onSubmit={handleSignupSubmit}>
                          <div className='form-group'>
                            <label>Email Or Username</label>
                            <input
                              id="username"
                              type="text"
                              value={signupForm.username}
                              onChange={handleSignupChange}
                              placeholder="Enter Email Or Username"
                            />
                            {isSignupError && errorSignupFields.username?.length ? (
                              <span className="error-text">
                                {errorSignupFields.username[0].message}
                              </span>
                            ) : null}
                          </div>
                          <div className='form-group'>
                            <label>Phone Number</label>
                            <input
                              id="phone_number"
                              type="text"
                              value={signupForm.phone_number}
                              onChange={handleSignupChange}
                              placeholder="Enter Phone Number"
                            />
                            {isSignupError && errorSignupFields.phone_number?.length ? (
                              <span className="error-text">
                                {errorSignupFields.phone_number[0].message}
                              </span>
                            ) : null}
                          </div>
                          <div className='d-flex gap-2'>
                            <div className='p-col-6'>
                              <div className='form-group'>
                                <label>Password</label>
                                <input
                                  id="password"
                                  type="password"
                                  value={signupForm.password}
                                  onChange={handleSignupChange}
                                  placeholder="******"
                                />
                                {isSignupError && errorSignupFields.password?.length ? (
                                  <span className="error-text">
                                    {errorSignupFields.password[0].message}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className='p-col-6'>
                              <div className='form-group'>
                                <label>Confirm Password</label>
                                <input
                                  id="confirm_password"
                                  type="text"
                                  value={signupForm.confirm_password}
                                  onChange={handleSignupChange}
                                  placeholder="Enter Confirm Password"
                                />
                                {isSignupError && errorSignupFields.confirm_password?.length ? (
                                  <span className="error-text">
                                    {errorSignupFields.confirm_password[0].message}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <button type="submit" className='signin_btn' >Create Account</button>
                          {/* <p className='text-center cursor-pointer' onClick={() => navigate('/')}>Cancel</p> */}
                        </form>
                        <div className='omb_login'>
                          <div className="row omb_row-sm-offset-3 omb_loginOr">
                            <div className="col-xs-12">
                              <hr className="omb_hrOr" />
                              <span className="omb_spanOr">Or continue with</span>
                            </div>
                          </div>
                          <div className='social_btn_box'>
                            <FacebookLogin
                              appId="1088597931155576"
                              callback={responseFacebook}
                              render={renderProps => (
                                <button className='social_btn fb_login' onClick={renderProps.onClick}></button>
                              )}
                            />
                            <button className='social_btn g_login' onClick={() => login()} ></button>
                          </div>

                          <p className='term_condition_text'>By registering you agree with our <span>Terms & Condition</span></p>
                        </div>
                      </div>
                    </div>
                    :
                    <div>
                      <h4 className='login-header-title mt-4'>Connect With</h4>
                      <div className='d-flex gap-2 mt-4'>
                        <button type="button" className='connect_btn '><img src={'../Images/Icons/metamask.png'} className='connect_icon' />Metamask</button>
                        <button type="button" className='connect_btn'><img src={'../Images/Icons/trustwallet.png'} className='connect_icon' />TrustWallet</button>
                      </div>
                      <div className='d-flex gap-2'>
                        <button type="button" className='connect_btn'><img src={'../Images/Icons/binanceW.png'} className='connect_icon' />Binance Chain Wallet</button>
                        <button type="button" className='connect_btn'><img src={'../Images/Icons/walletconnectW.png'} className='connect_icon' />WalletConnect</button>
                      </div>
                      <div className='d-flex gap-2'>
                        <button type="button" className='connect_btn '><img src={'../Images/Icons/mathwallet.png'} className='connect_icon' />MathWallet</button>
                        <button type="button" className='connect_btn'><img src={'../Images/Icons/TokenPoket.png'} className='connect_icon' />TokenPocket</button>
                      </div>
                      <div className='omb_login'>
                        <div className="row omb_row-sm-offset-3 omb_loginOr">
                          <div className="col-xs-12">
                            <hr className="omb_hrOr" />
                            <span className="omb_spanOr">Or continue with</span>
                          </div>
                        </div>
                        <div className='social_btn_box'>
                          <FacebookLogin
                            appId="1088597931155576"
                            callback={responseFacebook}
                            render={renderProps => (
                              <button className='social_btn fb_login' onClick={renderProps.onClick}></button>
                            )}
                          />
                          <button className='social_btn g_login' onClick={() => login()} ></button>
                        </div>
                        <h6 className='extra_text mt-2'>Gambling isn't forbidden by my local authorities and I'm at least 18 years old.</h6>
                      </div>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
export default LandingScreen
