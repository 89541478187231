import React from 'react'
import { useState, useEffect } from 'react';
import './index.css'
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import * as io from 'socket.io-client';
import socketConfig from '../../Socket/config.js'
import { PaginationControl } from 'react-bootstrap-pagination-control';
import socket from '../../socket';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const INITIAL_STATE = {
  errorMsg: '',
  oldPassword: '',
  newPassword: '',
  verifyNewPassword: ''
};


const MyInfo = (props) => {
  const [form, setForm] = useState(INITIAL_STATE);
  const userData = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  const [userProfileEdit, setUserProfileEdit] = useState(false)
  const [changePasswordForm, setChangePasswordForm] = useState(false)
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false)
  const [isGameHistoryModalOpen, setIsGameHistoryModalOpen] = useState(false)
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false)
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
  const [isGameHistory, setIsGameHistory] = useState(false)
  const [gameHistory, setGameHistory] = useState({})
  const [gameHistoryList, setGameHistoryList] = useState({})
  const [userProfilePicOption, setUserProfilePicOption] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15])
  const [hoursOption, setHoursOption] = useState(['01', '02', '03', '04', '05', '06', '07', '08', '09', 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24])
  const [userProfilePhoto, setUserProfilePhoto] = useState(userData ? userData.avatar : 0);
  const [plyrData, setPlyrData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [preFlop, setPreFlop] = useState({});
  const [flop, setFlop] = useState({});
  const [turn, setTurn] = useState({});
  const [river, setRiver] = useState({});
  const [showDown, setShowDown] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [selectedHour, setSelectedHour] = useState(0);
  const [isActive, setIsActive] = useState({
    table: 'basicInfo'
  })
  useEffect(() => {
    // setIsGameHistory(false);
  }, [])


  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.id]: event.target.value,
    });
  };

  const logout = (e) => {
    localStorage.removeItem("user");
    navigate('/')
  }

  const changePage = (value) => {
    setPage(value)
    const data = {
      playerId: userData.playerId,
      pageNo: value,
      productName: 'BytePoker'
    }
    socket.emit('playerGameHistory', data, (res) => {
      console.log(res.result);
      setGameHistoryList(res.result.gamesHistoryList);
    });
  }

  const openModal = (type) => {
    setIsAccountModalOpen(false);
    setIsGameHistoryModalOpen(false);
    setIsSettingModalOpen(false);
    setIsLogoutModalOpen(false);
    if (type == 'account') {
      const data = {
        playerId: userData.playerId,
        productName: 'BytePoker'
      }
      socket.emit('Playerprofile', data, (res) => {
        setUserProfile(res.result);
      });
      setIsActive({
        table: 'basicInfo',
      })
      setIsAccountModalOpen(true);
    }
    if (type == 'changeAvatar') {
      setIsActive({
        table: 'avtar',
      })
      setIsAccountModalOpen(true);
    }
    if (type == 'changePassword') {
      setIsAccountModalOpen(true);
      setChangePasswordForm(true);
    }
    if (type == 'gameHistory') {
      const data = {
        playerId: userData.playerId,
        pageNo: page,
        productName: 'BytePoker'
      }
      socket.emit('playerGameHistory', data, (res) => {
        console.log(res.result);
        setGameHistoryList(res.result.gamesHistoryList);
      });
      setIsActive({
        table: 'details',
      })
      setIsGameHistoryModalOpen(true);
    }
    if (type == 'settings') {
      setIsActive({
        table: 'settings',
      })
      setIsAccountModalOpen(true);
      // const data = {
      //   playerId: userData.playerId,
      //   pageNo: page,
      //   productName: 'BytePoker'
      // }
      // socket.emit('playerGameHistory', data, (res) => {
      //   console.log(res.result);
      //   setGameHistoryList(res.result.gamesHistoryList);
      // });
    }
    if (type == 'package') {
      setIsActive({
        table: 'package',
      })
      setSelectedHour(0);
      setIsAccountModalOpen(true);
      // const data = {
      //   playerId: userData.playerId,
      //   pageNo: page,
      //   productName: 'BytePoker'
      // }
      // socket.emit('playerGameHistory', data, (res) => {
      //   console.log(res.result);
      //   setGameHistoryList(res.result.gamesHistoryList);
      // });
    }
    if (type == 'logout') {
      setIsLogoutModalOpen(true);
    }
    props.closeSideBar()
  }

  const openGameHistory = (gameId) => {
    const data = {
      playerId: userData.playerId,
      gameId: gameId,
      productName: 'BytePoker'
    }
    socket.emit('GameHistory', data, (res) => {
      if (res.result) {
        setIsGameHistory(true);
        hideShowDiv('details');
        setGameHistory(res.result.gameHistory);
        console.log(res.result.gameHistory);
        const data = res.result.gameHistory;
        let preFlopArr = [];
        let flopArr = [];
        let turnArr = [];
        let riverArr = [];
        let showDownArr = [];
        data.handsEvents.map(((evnt, index) => {
          if (evnt.gameRound == 'Preflop') {
            preFlopArr.push(evnt);
          }
          if (evnt.gameRound == "Flop") {
            flopArr.push(evnt);
          }
          if (evnt.gameRound == 'Turn') {
            turnArr.push(evnt);
          }
          if (evnt.gameRound == 'River') {
            riverArr.push(evnt);
          }
          if (evnt.gameRound == 'ShowDown') {
            showDownArr.push(evnt);
          }
        }));
        setPreFlop(preFlopArr);
        setFlop(flopArr);
        setTurn(turnArr);
        setRiver(riverArr);
        setShowDown(showDownArr);
      }
    });
  }

  const closeModal = () => {
    setIsLoading(false);
    setUserProfileEdit(false);
    setChangePasswordForm(false);
    setIsAccountModalOpen(false);
    setIsGameHistoryModalOpen(false);
    setIsSettingModalOpen(false);
    setIsLogoutModalOpen(false);
    setPage(1);
    props.openSideBar()
  }

  const changePlayerAvtar = (type) => {
    if (type == 0) {
      const data = {
        playerId: userData.playerId,
        profilePic: userProfilePhoto,
      }
      socket.emit('playerProfilePic', data, (res) => {
        if (res.status == 'success') {
          let gamePlayer = userData;
          gamePlayer.profilePic = userProfilePhoto
          localStorage.setItem('user', JSON.stringify(gamePlayer));
          props.refreshData();
          closeModal();
        }
      });
    } else {
      setUserProfilePhoto(userData.avatar)
      closeModal();
    }
  }
  const changePassword = (type) => {
    form.errorMsg = '';
    if (type == 0) {
      const data = {
        playerId: userData.playerId,
        oldPassword: form.oldPassword,
        newPassword: form.newPassword,
        verifyNewPassword: form.verifyNewPassword,
      }
      socket.emit('playerChangePassword', data, (res) => {
        if (res.status == 'success') {
          toast.success(res.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setForm({
            oldPassword: '',
            newPassword: '',
            verifyNewPassword: '',
          });
          closeModal();
        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      });
    } else {
      closeModal();
    }
  }
  const buyPackage = (type) => {
    if (selectedHour != 0) {
      form.errorMsg = '';
      if (type == 0) {
        const data = {
          playerId: userData.playerId,
          selectedHour: selectedHour
        }
        socket.emit('buyHourlyPackage', data, (res) => {
          if (res.status == 'success') {
            toast.success(res.message, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            setTimeout(() => {
              closeModal();
            }, 1000)
          } else {
            toast.error(res.message, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        });
      } else {
        closeModal();
      }
    } else {
      toast.error('Please select hour', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }

  const playerProfilePhoto = (img) => {
    return '../Images/Avtar/' + (img + 1) + '.png'
  }

  const playerActionArr = (action) => {
    if (action == 0) { return 'Small Blind' }
    else if (action == 1) { return 'Big Blind' }
    else if (action == 2) { return 'Check' }
    else if (action == 3) { return 'Bet' }
    else if (action == 4) { return 'Call' }
    else if (action == 5) { return '' }
    else if (action == 6) { return 'Fold' }
    else if (action == 7) { return 'Timeout' }
    else if (action == 8) { return 'All-in' }
    else if (action == 9) { return 'Straddle' }
    else { return 'Straddle' }
  }

  const hideShowDiv = (id) => {
    if (id == 'package') {
      setSelectedHour(0)
    }
    if (id == 'payouts') {
      setForm({
        oldPassword: '',
        newPassword: '',
        verifyNewPassword: '',
      });
    }
    setIsActive({
      table: id,
    })
  }

  const mobile = window.innerWidth <= 1024;

  let customStyles = {
    content: {
      inset: '44% auto auto 49.5%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#474B6B',
      width: '60%',
      border: 'none',
      padding: '0px',
    },
  }
  let customStylesHistory = {
    content: {
      inset: '44% auto auto 49.5%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#474B6B',
      width: '60%',
      border: 'none',
      padding: '0px',
    },
  };
  let customStylesSetting = {
    content: {
      inset: '44% auto auto 49.5%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#474B6B',
      width: '60%',
      border: 'none',
      padding: '0px',
    },
  };

  let customStylesLogout = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#242234',
      width: '40%',
      height: '50%',
      border: 'none'
    },
  };

  if (mobile) {
    customStyles = {
      content: {
        inset: '44% auto auto 49.5%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#474B6B',
        width: '90%',
        border: 'none',
        padding: '0px',
      },
    }
    customStylesHistory = {
      content: {
        inset: '44% auto auto 49.5%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#474B6B',
        width: '90%',
        border: 'none',
        padding: '0px',
      },
    };
    customStylesSetting = {
      content: {
        inset: '44% auto auto 49.5%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#474B6B',
        width: '90%',
        border: 'none',
        padding: '0px',
      },
    };

    customStylesLogout = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#242234',
        width: '60%',
        height: '30%',
        border: 'none'
      },
    };
  }

  return (
    <div >
      <ToastContainer />
      <div className="infotable">
        <button className='btn sidebarBtn' onClick={() => openModal('account')}>
          <img src={'../Images/account.png'} className='sidebarIcon'></img>
          My Account</button>
        <button className='btn sidebarBtn mt-2' onClick={() => openModal('changeAvatar')}>
          <img src={'../Images/avatar.png'} className='sidebarIcon'></img>
          Change Avatar</button>
        <button className='btn sidebarBtn mt-2' onClick={() => openModal('gameHistory')}>
          <img src={'../Images/history.png'} className='sidebarIcon'></img>
          Game History</button>
        {/* <button className='btn sidebarBtn mt-2' onClick={() => openModal('changePassword')}>
          <img src={'../Images/user.png'} className='sidebarIcon'></img>
          Change Password</button> */}
        <button className='btn sidebarBtn mt-2' onClick={() => openModal('settings')}>
          <img src={'../Images/setting.png'} className='sidebarIcon'></img>
          Settings</button>
        <button className='btn sidebarBtn my-2' onClick={() => openModal('logout')}>
          <img src={'../Images/logout.png'} className='sidebarIcon'></img>
          Logout</button>
      </div>
      <Modal
        isOpen={isAccountModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className='plyerProfile'>
          <div className={mobile ? "mobileView" : ''}>
            <div className='main-heading'>
              {isActive.table === 'basicInfo' ?
                <h2 >Profile Information</h2>
                : isActive.table === 'avtar' ?
                  <h2 >Change Avatar</h2>
                  : isActive.table === 'payouts' ?
                    <h2 >Change Password</h2>
                    : isActive.table === 'blinds' ?
                      <h2 >Account Security</h2>
                        : isActive.table === 'settings' ?
                          <h2 >Settings</h2>
                          : ''
              }
              <img src={'../Images/close.png'} onClick={() => closeModal(false)}></img>
            </div>
            <div className="tableFixDetailsHead">
              <div className='d-flex min-h-100'>
                <div className='p-col-3 details-sidebar min-h-100'>
                  <button className={isActive.table === 'basicInfo' ? `btn p-hd-fill-inner-btn  active` : 'btn p-hd-fill-inner-btn'} id="basicInfo"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Profile Information</button>
                  <button className={isActive.table === 'avtar' ? `btn p-hd-fill-inner-btn  mt-2 active` : 'btn p-hd-fill-inner-btn  mt-2'} id="avtar"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Change Avatar</button>
                  <button className={isActive.table === 'payouts' ? `btn p-hd-fill-inner-btn mt-2 active` : 'btn p-hd-fill-inner-btn mt-2 '} id="payouts"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Change Password</button>
                  <button className={isActive.table === 'blinds' ? `btn p-hd-fill-inner-btn mt-2 active` : 'btn p-hd-fill-inner-btn mt-2 '} id="blinds"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Account Security</button>
                  <button className={isActive.table === 'settings' ? `btn p-hd-fill-inner-btn mt-2 active` : 'btn p-hd-fill-inner-btn mt-2 '} id="settings"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Settings</button>
                </div>
                <div className='p-col-9 min-h-100 theme-bg'>
                  <div className=''>
                    <div className={isActive.table === 'basicInfo' ? `basicInfo` : 'basicInfo d-none'}>
                      <div className='plyerProfileAvtar'>
                        <div>
                          <img src={playerProfilePhoto(userProfilePhoto)} className='plyrProfilePic activeProfile'></img>
                        </div>
                      </div>
                      <div className='playerProfileInfo'>
                        <center>
                          <table>
                            <tr>
                              <td width={'50%'}><p><span>Username : </span></p></td>
                              <td width={'50%'} className='text-end'><p>{userData?.username}</p></td>
                            </tr>
                            <tr>
                              <td width={'50%'}><p><span>Email : </span></p></td>
                              <td width={'50%'} className='text-end'><p>{userData?.email}</p></td>
                            </tr>
                            <tr>
                              <td width={'50%'}><p><span>Credit Balance : </span></p></td>
                              <td width={'50%'} className='text-end'><p>{userData ? Number(userData.chips).toFixed(2) : 0.00}</p></td>
                            </tr>
                            <tr>
                              <td width={'50%'}><p><span>Mobile : </span></p></td>
                              <td width={'50%'} className='text-end'><p>{userData?.mobile}</p></td>
                            </tr>
                            <tr>
                              <td width={'50%'}><p><span>Balance Hour : </span></p></td>
                              <td width={'50%'} className='text-end'><p>{userData?.hour_balance}</p></td>
                            </tr>
                          </table>
                        </center>
                      </div>
                    </div>
                    <div className={isActive.table === 'avtar' ? `avtar avtarSection` : 'avtar avtarSection d-none'}>
                      <div>
                        <div>
                          {userProfilePicOption.map((pic, index) => {
                            return userProfilePhoto == index ?
                              <img onClick={() => setUserProfilePhoto(index)} key={index} src={playerProfilePhoto(index)} className='plyrProfilePic activeProfile'></img> :
                              <img onClick={() => setUserProfilePhoto(index)} key={index} src={playerProfilePhoto(index)} className='plyrProfilePic'></img>
                          })}
                        </div>
                        <div className='avtarBtnSection'>
                          <button className='edtAvtarBtn' onClick={() => changePlayerAvtar(0)}>Update</button>
                          <button className='edtAvtarBtn' onClick={() => changePlayerAvtar(1)}>Cancle</button>
                        </div>
                      </div>
                    </div>
                    <div className={isActive.table === 'tables' ? `tables` : 'tables d-none'}>
                      <form>
                        <div className='form-group'>
                          <label>Username</label>
                          <input className='form-control' readOnly={true} value={userData?.username}></input>
                        </div>
                        <div className='form-group'>
                          <label>Mobile Number</label>
                          <input className='form-control' readOnly={true} value={userData?.mobile}></input>
                        </div>
                      </form>

                    </div>
                    <div className={isActive.table === 'payouts' ? `payouts` : 'payouts d-none'}>
                      <div className='plyerProfile'>
                        <center>

                          <form>
                            <table>
                              {form.errorMsg ?
                                <tr>
                                  <td width={'100%'}>
                                    <label className='text-danger'>
                                      {form.errorMsg}
                                    </label>
                                  </td>
                                </tr>
                                : ''}
                              <tr>
                                <td width={'100%'}>
                                  <div className='form-group'>
                                    <label>Current Password</label>
                                    <input
                                      id="oldPassword"
                                      type="password"
                                      value={form.oldPassword}
                                      onChange={handleChange}
                                      placeholder="Current Password"
                                      className='form-control'
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td width={'100%'}>
                                  <div className='form-group'>
                                    <label>New Password</label>
                                    <input
                                      id="newPassword"
                                      type="password"
                                      value={form.newPassword}
                                      onChange={handleChange}
                                      placeholder="New Password"
                                      className='form-control'
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td width={'100%'}>
                                  <div className='form-group'>
                                    <label>Confirm Password</label>
                                    <input
                                      id="verifyNewPassword"
                                      type="text"
                                      value={form.verifyNewPassword}
                                      onChange={handleChange}
                                      placeholder="Confirm Password"
                                      className='form-control'
                                    />
                                  </div>
                                </td>
                              </tr>
                            </table>
                            <div className='avtarBtnSection'>
                              <button type="button" className='edtAvtarBtn' onClick={() => changePassword(0)}>Change</button>
                              <button type="button" className='edtAvtarBtn' onClick={() => changePassword(1)}>Cancle</button>
                            </div>
                          </form>
                        </center>
                      </div>
                    </div>
                    <div className={isActive.table === 'blinds' ? `blinds` : 'blinds d-none'}>
                      <div className='acount-security-table'>
                        <table className='table p-lb-table table-borderless'>
                          <tbody>
                            <tr>
                              <td>2 Factory Authentication</td>
                              <td><input type="checkbox" checked={true}></input></td>
                            </tr>
                            <tr>
                              <td>Security Questions</td>
                              <td><input type="checkbox" checked={true}></input></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className={isActive.table === 'package' ? `package` : 'package d-none'}>
                      <div className='plyerBuyChips'>
                        <center>
                          <table>
                            <tr>
                              <td width={'100%'}>
                                <div className='form-group'>
                                  <label>Select Hour to buy</label>
                                  <select className='form-control hour-buy-dropdown' value={selectedHour} onChange={(e) => setSelectedHour(e.target.value)}>
                                    <option>Select Hour</option>
                                    {hoursOption.map((hour, index) => {
                                      return (
                                        <option key={index} value={hour + ':00'}>{hour + ':00'}</option>
                                      );
                                    })
                                    };
                                  </select>
                                  {/* <input
                                    id="oldPassword"
                                    type="number"
                                    min='0'
                                    
                                    
                                    placeholder="Current Password"
                                    
                                  /> */}
                                </div>
                              </td>
                            </tr>
                          </table>
                          <div className='avtarBtnSection'>
                            <button className='edtAvtarBtn' onClick={() => buyPackage(0)}>Buy</button>
                            <button className='edtAvtarBtn' onClick={() => buyPackage(1)}>Cancle</button>
                          </div>
                        </center>
                      </div>
                    </div>
                    <div className={isActive.table === 'settings' ? `settings` : 'settings d-none'}>
                      <div className='acount-security-table'>
                        <table className='table p-lb-table table-borderless'>
                          <tbody>
                            <tr>
                              <td>Sound Settings</td>
                              <td><input type="checkbox" checked={true}></input></td>
                            </tr>
                            <tr>
                              <td>Vibration Settings</td>
                              <td><input type="checkbox" checked={true}></input></td>
                            </tr>
                            <tr>
                              <td>Push Settings</td>
                              <td><input type="checkbox" checked={true}></input></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {isLoading && userProfileEdit ?
          <div>
            <div>
              {userProfilePicOption.map((pic, index) => {
                return userProfilePhoto == index ?
                  <img onClick={() => setUserProfilePhoto(index)} key={index} src={playerProfilePhoto(index)} className='plyrProfilePic activeProfile'></img> :
                  <img onClick={() => setUserProfilePhoto(index)} key={index} src={playerProfilePhoto(index)} className='plyrProfilePic'></img>
              })}
            </div>
            <div className='avtarBtnSection'>
              <button className='edtAvtarBtn' onClick={() => changePlayerAvtar(0)}>Update</button>
              <button className='edtAvtarBtn' onClick={() => changePlayerAvtar(1)}>Cancle</button>
            </div>
          </div>
          : isLoading && changePasswordForm ?
            <div className='plyerProfile'>
              <center>
                <form>
                  <div className='form-group'>
                    <label>Current Password</label>
                    <input className='form-control'></input>
                  </div>
                  <div className='form-group'>
                    <label>New Password</label>
                    <input className='form-control'></input>
                  </div>
                  <div className='form-group'>
                    <label>Confirm Password</label>
                    <input className='form-control'></input>
                  </div>
                  <div className='avtarBtnSection'>
                    <button className='edtAvtarBtn' onClick={() => changePassword(0)}>Change</button>
                    <button className='edtAvtarBtn' onClick={() => changePassword(1)}>Cancle</button>
                  </div>
                </form>
              </center>
            </div>
            : isLoading ?
              
              : <div className="modalLoading" ><img src={'../Images/Load.gif'}></img></div>
        } */}
      </Modal>

      <Modal
        isOpen={isGameHistoryModalOpen}
        onRequestClose={closeModal}
        style={customStylesHistory}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        {isGameHistory ?
          <div className='gameHistoryData'>
            <div className={mobile ? "mobileView" : ''}>
              <div className='main-heading'>
                <h2 >Game History</h2>
                <img src={'../Images/close.png'} onClick={() => setIsGameHistory(false)}></img>
              </div>
              <div className=" tableFixDetailsHead">
                <div className='d-flex min-h-100'>
                  <div className='p-col-3 details-sidebar'>
                    <button className={isActive.table === 'details' ? `btn p-hd-fill-inner-btn active` : 'btn p-hd-fill-inner-btn '} id="details"
                      onClick={(e) => {
                        hideShowDiv(e.target.id)
                      }}>Game</button>
                    {gameHistory?.winners?.length > 0 ? <button className={isActive.table === 'winner' ? `btn p-hd-fill-inner-btn mt-2 active` : 'btn p-hd-fill-inner-btn mt-2'} id="winner"
                      onClick={(e) => {
                        hideShowDiv(e.target.id)
                      }}>Winners</button> : ''}
                    <button className={isActive.table === 'gameplayer' ? `btn p-hd-fill-inner-btn mt-2 active` : 'btn p-hd-fill-inner-btn mt-2 '} id="gameplayer"
                      onClick={(e) => {
                        hideShowDiv(e.target.id)
                      }}>Players</button>
                    {preFlop?.length > 0 ?
                      <button className={isActive.table === 'preflop' ? `btn p-hd-fill-inner-btn mt-2 active` : 'btn p-hd-fill-inner-btn mt-2 '} id="preflop"
                        onClick={(e) => {
                          hideShowDiv(e.target.id)
                        }}>Pre Flop</button> : ''}
                    {flop?.length > 0 ?
                      <button className={isActive.table === 'flop' ? `btn p-hd-fill-inner-btn mt-2 active` : 'btn p-hd-fill-inner-btn mt-2 '} id="flop"
                        onClick={(e) => {
                          hideShowDiv(e.target.id)
                        }}>Flop</button>
                      : ''}
                    {turn?.length > 0 ?
                      <button className={isActive.table === 'turn' ? `btn p-hd-fill-inner-btn mt-2 active` : 'btn p-hd-fill-inner-btn mt-2 '} id="turn"
                        onClick={(e) => {
                          hideShowDiv(e.target.id)
                        }}>Turn</button>
                      : ''}
                    {river?.length > 0 ?
                      <button className={isActive.table === 'river' ? `btn p-hd-fill-inner-btn mt-2 active` : 'btn p-hd-fill-inner-btn mt-2 '} id="river"
                        onClick={(e) => {
                          hideShowDiv(e.target.id)
                        }}>River</button>
                      : ''}
                    {showDown?.length > 0 ?
                      <button className={isActive.table === 'showdown' ? `btn p-hd-fill-inner-btn mt-2 active` : 'btn p-hd-fill-inner-btn mt-2 '} id="showdown"
                        onClick={(e) => {
                          hideShowDiv(e.target.id)
                        }}>Show Down</button>
                      : ''}
                  </div>
                  <div className='p-col-9 theme-bg'>
                    <div className=''>
                      <div className={isActive.table === 'details' ? `details` : 'details d-none'}>
                        <div>
                          <h4 className='gameNameTitle gameDateilsTitle'>Game Profile</h4>
                          <table className='table p-lb-table table-borderless'>
                            <tbody>
                              <tr>
                                <td>Game Name</td>
                                <td>{gameHistory.game?.tableName}</td>
                              </tr>
                              <tr>
                                <td>Game ID</td>
                                <td>{gameHistory.gameName}</td>
                              </tr>
                              <tr>
                                <td>Stakes</td>
                                <td>{gameHistory.stack}</td>
                              </tr>
                              <tr>
                                <td>Date Time</td>
                                <td>{moment(gameHistory.dataTime).format("DD MMM, YYYY hh:mm A")}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className={isActive.table === 'winner' ? `winner` : 'winner d-none'}>
                        {gameHistory?.winners?.length > 0 ?
                          <div>
                            <h4 className='gameNameTitle gameDateilsTitle'>Winners</h4>
                            <table className='table p-lb-table table-borderless'>
                              <thead>
                                <tr>
                                  <th>Player</th>
                                  <th>Winning Amount</th>
                                  <th>Best Cards</th>
                                </tr>
                              </thead>
                              <tbody>
                                {gameHistory?.winners?.map((winner, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{winner.playerName}</td>
                                      <td>{winner.winningAmount}</td>
                                      <td>
                                        {winner.winningHands.length > 0 ? winner.winningHands.map((card, k) => {
                                          return (
                                            <img key={k} src={'../Images/Card/' + card + '.png'} className='historyCard mx-1'></img>
                                          )
                                        }) : ''}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          : ''}
                      </div>
                      <div className={isActive.table === 'gameplayer' ? `gameplayer` : 'gameplayer d-none'}>
                        <h4 className='gameNameTitle gameDateilsTitle'>Players List</h4>
                        <table className='table p-lb-table table-borderless'>
                          <thead>
                            <tr>
                              <th>Player</th>
                              <th>Cards</th>
                            </tr>
                          </thead>
                          <tbody>
                            {gameHistory?.players?.length > 0 ? gameHistory?.players?.map((plr, index) => {
                              return (
                                <tr key={index}>
                                  <td>{plr.playerName}</td>
                                  <td>
                                    {plr.cards.length > 0 ? plr.cards.map((card, k) => {
                                      return (
                                        <img key={k} src={'../Images/Card/' + card + '.png'} className='historyCard mx-1'></img>
                                      )
                                    }) : ''}</td>
                                </tr>
                              );
                            }) : <tr>
                              <td colSpan="7">No Player Found</td>
                            </tr>}
                          </tbody>
                        </table>
                      </div>
                      <div className={isActive.table === 'preflop' ? `preflop` : 'preflop d-none'}>
                        {preFlop?.length > 0 ?
                          <div>
                            <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - Pre Flop</h4>
                            <table className='table p-lb-table table-borderless'>
                              <thead>
                                <tr>
                                  <th>Player</th>
                                  <th>Action</th>
                                  <th>Chips</th>
                                </tr>
                              </thead>
                              <tbody>
                                {preFlop.map((data, index) => {
                                  return (
                                    <tr key={index}>
                                      <td style={{ width: '40%' }}>{data.playerName}</td>
                                      <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                      <td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(2) : ''}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          : ''}
                      </div>
                      <div className={isActive.table === 'flop' ? `flop` : 'flop d-none'}>
                        {flop?.length > 0 ?
                          <div>
                            <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - Flop

                            </h4>
                            <table className='table p-lb-table table-borderless'>
                              <tbody>
                                {gameHistory?.tableCards?.Flop.length > 0 ?
                                  <tr>
                                    <td className='flopTitle' style={{ width: '40%' }}>Flop</td>
                                    <td style={{ width: '30%' }}>
                                      {gameHistory?.tableCards?.Flop.map((card) => {
                                        return (
                                          <img className='historyCard' src={'../Images/Card/' + card + '.png'}></img>
                                        )
                                      })
                                      }
                                    </td>
                                    <td style={{ width: '30%' }}></td>
                                  </tr>
                                  : ''}
                                {flop.map((data, index) => {
                                  return (
                                    <tr key={index}>
                                      <td style={{ width: '40%' }}>{data.playerName}</td>
                                      <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                      <td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(2) : ''}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          : ''}
                      </div>
                      <div className={isActive.table === 'turn' ? `turn` : 'turn d-none'}>
                        {turn?.length > 0 ?
                          <div>
                            <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - Turn</h4>
                            <table className='table p-lb-table table-borderless'>
                              <tbody>
                                {gameHistory?.tableCards?.Turn.length > 0 ?
                                  <tr >
                                    <td style={{ width: '40%' }}>Turn</td>
                                    <td style={{ width: '30%' }}>
                                      {gameHistory?.tableCards?.Turn.map((card) => {
                                        return (
                                          <img className='historyCard' src={'../Images/Card/' + card + '.png'}></img>
                                        )
                                      })
                                      }
                                    </td>
                                    <td style={{ width: '30%' }}></td>
                                  </tr>
                                  : ''}
                                {turn.map((data, index) => {
                                  return (
                                    <tr key={index}>
                                      <td style={{ width: '40%' }}>{data.playerName}</td>
                                      <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                      <td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(2) : ''}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          : ''}
                      </div>
                      <div className={isActive.table === 'river' ? `river` : 'river d-none'}>
                        {river?.length > 0 ?
                          <div>
                            <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - River</h4>
                            <table className='table p-lb-table table-borderless'>
                              <tbody>
                                {gameHistory?.tableCards?.River.length > 0 ?
                                  <tr >
                                    <td style={{ width: '40%' }}>River</td>
                                    <td style={{ width: '30%' }}>
                                      {gameHistory?.tableCards?.River.map((card) => {
                                        return (
                                          <img className='historyCard' src={'../Images/Card/' + card + '.png'}></img>
                                        )
                                      })
                                      }
                                    </td>
                                    <td style={{ width: '30%' }}></td>
                                  </tr>
                                  : ''}
                                {river.map((data, index) => {
                                  return (
                                    <tr key={index}>
                                      <td style={{ width: '40%' }}>{data.playerName}</td>
                                      <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                      <td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(2) : ''}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          : ''}
                      </div>
                      <div className={isActive.table === 'showdown' ? `showdown` : 'showdown d-none'}>
                        {showDown?.length > 0 ?
                          <div>
                            <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - Show Down</h4>
                            <table className='table p-lb-table table-borderless'>
                              <thead>
                                <tr>
                                  <th>Player</th>
                                  <th>Action</th>
                                  <th>Chips</th>
                                </tr>
                              </thead>
                              <tbody>
                                {showDown.map((data, index) => {
                                  return (
                                    <tr key={index}>
                                      <td style={{ width: '40%' }}>{data.playerName}</td>
                                      <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                      <td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(2) : ''}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <button className='backBtn'><img src={'../Images/close.png'} onClick={() => setIsGameHistory(false)}></img></button>
            <h4 className='historyTitle'>Game History</h4>
            <div className='tableFixHead historyPlayerTable'>

            </div> */}
          </div>
          : <div className={mobile ? "mobileView" : ''}>
            <div className='second-heading'>
              <h2 className='historyTitle mb-0'>Game History</h2>
              <img src={'../Images/close.png'} onClick={() => closeModal()}></img>
            </div>
            <div className=" gameHistoryTableFixHead">
              <table className='table p-lb-table table-borderless'>
                <thead>
                  <tr>
                    <th>Date & Time</th>
                    <th>Game Name</th>
                    <th>Winning Amount</th>
                    <th>Hands</th>
                    <th>Best Cards</th>
                  </tr>
                </thead>
                <tbody>
                  {gameHistoryList.length > 0 ? gameHistoryList.map((history, index) => {
                    return (
                      <tr key={index} onClick={() => openGameHistory(history.gameId)}>
                        <td>{moment(history.dateTime).format("DD MMM, YYYY hh:mm A")}</td>
                        <td>{history.game?.tableName}</td>
                        <td>{history.winner?.winningAmount}</td>
                        <td >
                          {history.handCards.length > 0 ? history.handCards.map((card, k) => {
                            return (
                              <img key={k} src={'../Images/Card/' + card + '.png'} className='historyCard mx-1'></img>
                            )
                          }) : ''}</td>
                        <td >
                          {history.winner?.winningHands?.length > 0 ? history.winner.winningHands.map((winningCard, k) => {
                            return (
                              <img key={k} src={'../Images/Card/' + winningCard + '.png'} className='historyCard mx-1'></img>
                            )
                          }) : ''}</td>
                      </tr>
                    );
                  }) : <tr>
                    <td colSpan="7">No Game Found</td>
                  </tr>}
                </tbody>
              </table>
            </div>
            <div className="historyPagination">
              <PaginationControl
                page={page}
                between={0}
                total={497}
                limit={25}
                changePage={(page) => {
                  changePage(page);
                }}
                ellipsis={1}
              />
            </div>
          </div>
        }
      </Modal>

      <Modal
        isOpen={isSettingModalOpen}
        onRequestClose={closeModal}
        style={customStylesSetting}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div>
          <div className='gameHistoryData'>
            <button className='backBtn'><img src={'../Images/close.png'} onClick={() => closeModal()}></img></button>
            <h4 className='historyTitle'>Settings</h4>
          </div>
          <table className='table p-lb-table table-borderless setting-table'>
            <tbody>
              <tr>
                <td>Sound Settings</td>
                <td><input type="checkbox" checked={true}></input></td>
              </tr>
              <tr>
                <td>Vibration Settings</td>
                <td><input type="checkbox" checked={true}></input></td>
              </tr>
              <tr>
                <td>Push Settings</td>
                <td><input type="checkbox" checked={true}></input></td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
      <Modal
        isOpen={isLogoutModalOpen}
        onRequestClose={closeModal}
        style={customStylesLogout}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className={mobile ? "gameModel mobileView" : 'gameModel'}>
          <div className='modalBody'>
            <center>
              <h2 className='color-1'>Warning!</h2>
              <h4 className='color-1 mt-4'>Are you sure you want leave this game?</h4>
              <div className='avtarBtnSection'>
                <button className='selectBuyInBtn' onClick={() => logout()}>Yes</button>
                <button className='selectBuyInBtn' onClick={() => closeModal()}>No</button>
              </div>
            </center>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MyInfo

