import React from 'react'
import { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import socket from '../../socket';

const CashierGameTable = (props) => {
  const [isMobile, setIsMobile] = useState(props.mobileView)
  const [isActive, setIsActive] = useState({
    table: props.activeTab
  })
  const [hoursOption, setHoursOption] = useState(['01', '02', '03', '04', '05', '06', '07', '08', '09', 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24])
  const [selectedHour, setSelectedHour] = useState(0);
  const userData = JSON.parse(localStorage.getItem('user'));

  const hideShowDiv = (e) => {
    if (e.target.id == 'buyHours') {
      setSelectedHour(0);
    }
    setIsActive({
      table: e.target.id,
    });
  }

  const buyPackage = (hour) => {
    const data = {
      playerId: userData.playerId,
      selectedHour: hour
    }
    socket.emit('buyHourlyPackage', data, (res) => {
      if (res.status == 'success') {
        toast.success(res.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error(res.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });

    // if (selectedHour != 0) {
    //   if (type == 0) {
    //     const data = {
    //       playerId: userData.playerId,
    //       selectedHour: selectedHour
    //     }
    //     socket.emit('buyHourlyPackage', data, (res) => {
    //       if (res.status == 'success') {
    //         toast.success(res.message, {
    //           position: "top-center",
    //           autoClose: 2000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //           theme: "dark",
    //         });
    //       } else {
    //         toast.error(res.message, {
    //           position: "top-center",
    //           autoClose: 2000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //           theme: "dark",
    //         });
    //       }
    //     });
    //   }
    // } else {
    //   toast.error('Please select hour', {
    //     position: "top-center",
    //     autoClose: 2000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    //   });
    // }
  }


  return (
    <div>
      <ToastContainer />
      {isMobile ?
        <div className="sectionBox mt-2">
          <div className='main-heading'>
            <h2 >Cashier</h2>
          </div>
          <div className='d-flex myinfo-details-sidebar gap-2'>
            <button className={isActive.table === 'deposite' ? `btn p-hd-fill-inner-btn active` : 'btn p-hd-fill-inner-btn '} id="deposite"
              onClick={(e) => {
                hideShowDiv(e)
              }}>Deposit</button>

            <button className={isActive.table === 'dHistory' ? `btn p-hd-fill-inner-btn active` : 'btn p-hd-fill-inner-btn'} id="dHistory"
              onClick={(e) => {
                hideShowDiv(e)
              }}>Deposit History</button>
            <button className={isActive.table === 'cashout' ? `btn p-hd-fill-inner-btn active` : 'btn p-hd-fill-inner-btn'} id="cashout"
              onClick={(e) => {
                hideShowDiv(e)
              }}>Cash Out</button>

            <button className={isActive.table === 'coHistory' ? `btn p-hd-fill-inner-btn active` : 'btn p-hd-fill-inner-btn'} id="coHistory"
              onClick={(e) => {
                hideShowDiv(e)
              }}>Cash Out History</button>

            <button className={isActive.table === 'buyHours' ? `btn p-hd-fill-inner-btn active` : 'btn p-hd-fill-inner-btn'} id="buyHours"
              onClick={(e) => {
                hideShowDiv(e)
              }}>Buy Hours</button>

            <button className={isActive.table === 'allTra' ? `btn p-hd-fill-inner-btn active` : 'btn p-hd-fill-inner-btn'} id="allTra"
              onClick={(e) => {
                hideShowDiv(e)
              }}>All Transaction</button>
          </div>
          
          <div className="tableFixHead">
            <div className='min-h-100'>
              <div className={isActive.table === 'cashout' ? `cashout` : 'cashout d-none'}>
                <form className='cahsierForm'>
                  <div className='form-group'>
                    <label>Withdraw Amount</label>
                    <div className="inputWithIcon">
                      <input type="text" placeholder="Amount..."></input>
                    </div>
                  </div>
                  <div className='form-group mt-1'>
                    <div className='d-flex'>
                      <button className='btn payment-method-btn m-1'>$ 100</button>
                      <button className='btn payment-method-btn m-1'>$ 500</button>
                      <button className='btn payment-method-btn m-1'>$ 1,000</button>
                      <button className='btn payment-method-btn m-1'>$ 2,000</button>
                      <button className='btn payment-method-btn m-1'>$ 5,000</button>
                    </div>
                  </div>
                  <div className='form-group  mt-3'>
                    <label>Select Payment Gateway</label>
                    <select className='form-control'>
                      <option>Select Payment Gateway</option>
                      <option>Paysafe</option>
                      <option>SticPay</option>
                      <option>Luxon</option>
                      <option>Skrill (Via Paysafe)</option>
                    </select>
                  </div>

                  <div className='form-group mt-3'>
                    <button className='btn deposite-method-btn'>WITHDRAW</button>
                  </div>
                </form>
              </div>
              <div className={isActive.table === 'deposite' ? `deposite` : 'deposite d-none'}>
                <form className='cahsierForm'>
                  <div className='form-group'>
                    <label>Select Method</label>
                    <select className='form-control'>
                      <option>Select Payment Method</option>
                      <option>Paysafe</option>
                      <option>SticPay</option>
                      <option>Luxon</option>
                      <option>Skrill (Via Paysafe)</option>
                    </select>
                  </div>
                  <div className='form-group'>
                    <label className='text-mute'>Recommended payment methods</label>
                    <div className='d-flex'>
                      <button className='btn payment-method-btn m-1'>Tether</button>
                      <button className='btn payment-method-btn m-1'>Bitcoin</button>
                      <button className='btn payment-method-btn m-1'>Skrill</button>
                      <button className='btn payment-method-btn m-1'>Luxon</button>
                      <button className='btn payment-method-btn m-1'>Paysafe</button>
                    </div>
                  </div>
                  <div className='form-group mt-3'>
                    <label>Deposite Amount</label>
                    <div className="inputWithIcon">
                      <input type="text" placeholder="Amount..."></input>
                    </div>
                  </div>
                  <div className='form-group mt-1'>
                    <div className='d-flex'>
                      <button className='btn payment-method-btn m-1'>$ 100</button>
                      <button className='btn payment-method-btn m-1'>$ 500</button>
                      <button className='btn payment-method-btn m-1'>$ 1,000</button>
                      <button className='btn payment-method-btn m-1'>$ 2,000</button>
                      <button className='btn payment-method-btn m-1'>$ 5,000</button>
                    </div>
                  </div>
                  <div className='form-group mt-3'>
                    <label>Bonus Deposite Code</label>
                    <input type="text" placeholder="Bonus Deposite Code"></input>
                  </div>
                  <div className='form-group mt-3'>
                    <button className='btn deposite-method-btn'>DEPOSIT</button>
                  </div>
                </form>
              </div>
              <div className={isActive.table === 'coHistory' ? `coHistory` : 'coHistory d-none'}>
                <form className='cahsierForm'>
                  <table className='table p-lb-table table-borderless'>
                    <thead>
                      <tr>
                        <th>Sr NO.</th>
                        <th>Date & Time</th>
                        <th>Refrerence No</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>10-05-2023 3:45 pm</td>
                        <td>RN15412198425</td>
                        <td>Card Payment</td>
                        <td>Success</td>
                        <td>500</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>10-05-2023 3:45 pm</td>
                        <td>RN15412135412</td>
                        <td>Card Payment</td>
                        <td>Success</td>
                        <td>1000</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>10-05-2023 3:45 pm</td>
                        <td>RN1541284512</td>
                        <td>Cash Payment</td>
                        <td>Success</td>
                        <td>5000</td>
                      </tr>

                    </tbody>
                  </table>
                </form>
              </div>
              <div className={isActive.table === 'buyHours' ? `buyHours` : 'buyHours d-none'}>
                <div className='cahsierForm buyPackageBox'>
                  <div className='form-group'>
                    {/* <label className='text-center mb-4'>BUY HOUR PACKAGE</label> */}
                    <div className=''>
                      <div className='d-flex gap-3 mt-2'>
                        <div className='p-col-4'>
                          <div className='package-box'>
                            <div className='package-name-header'>
                              <p>STARTER</p>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 2 Hour</li>
                                <li>$25 per hour</li>
                              </ul>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('02:00')}>Buy Now</button>
                            </div>
                          </div>
                        </div>
                        <div className='p-col-4'>
                          <div className='package-box'>
                            <div className='package-name-header'>
                              <p>BOOSTER</p>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 5 Hour</li>
                                <li>$22 per hour</li>
                              </ul>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('05:00')}>Buy Now</button>
                            </div>
                          </div>
                        </div>
                        <div className='p-col-4'>
                          <div className='package-box'>
                            <div className='package-name-header'>
                              <p>BRONZE</p>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 10 Hour</li>
                                <li>$20 per hour</li>
                              </ul>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('10:00')}>Buy Now</button>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className='d-flex gap-3 mt-2'>
                        <div className='p-col-4'>
                          <div className='package-box'>
                            <div className='package-name-header'>
                              <p>SILVER</p>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 20 Hour</li>
                                <li>$18 per hour</li>
                              </ul>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('20:00')}>Buy Now</button>
                            </div>
                          </div>
                        </div>
                        <div className='p-col-4'>
                          <div className='package-box'>
                            <div className='package-name-header'>
                              <p>GOLD</p>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 30 Hour</li>
                                <li>$15 per hour</li>
                              </ul>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('30:00')}>Buy Now</button>
                            </div>
                          </div>
                        </div>
                        <div className='p-col-4'>
                          <div className='package-box'>
                            <div className='package-name-header'>
                              <p>PLATINUM</p>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 50 Hour</li>
                                <li>$12 per hour</li>
                              </ul>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('50:00')}>Buy Now</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex gap-3 mt-2'>
                        <div className='p-col-4'>
                          <div className='package-box'>
                            <div className='package-name-header'>
                              <p>TITANIUM</p>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 75 Hour</li>
                                <li>$10 per hour</li>
                              </ul>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('75:00')}>Buy Now</button>
                            </div>
                          </div>
                        </div>
                        <div className='p-col-4'>
                          <div className='package-box'>
                            <div className='package-name-header'>
                              <p>DIAMOND</p>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 100 Hour</li>
                                <li>$5 per hour</li>
                              </ul>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('100:00')}>Buy Now</button>
                            </div>
                          </div>
                        </div>
                        <div className='p-col-4'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={isActive.table === 'dHistory' ? `dHistory` : 'dHistory d-none'}>
                <div className='cahsierForm'>
                  <table className='table p-lb-table table-borderless'>
                    <thead>
                      <tr>
                        <th>Sr NO.</th>
                        <th>Date & Time</th>
                        <th>Refrerence No</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>10-05-2023 3:45 pm</td>
                        <td>RN15412198425</td>
                        <td>Card Payment</td>
                        <td>Success</td>
                        <td>500</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>10-05-2023 3:45 pm</td>
                        <td>RN15412135412</td>
                        <td>Crypto Payment</td>
                        <td>Success</td>
                        <td>1000</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>10-05-2023 3:45 pm</td>
                        <td>RN1541284512</td>
                        <td>Cash Payment</td>
                        <td>Success</td>
                        <td>5000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className={isActive.table === 'allTra' ? `allTra` : 'allTra d-none'}>
                <div className='cahsierForm'>
                  <table className='table p-lb-table table-borderless'>
                    <thead>
                      <tr>
                        <th>Sr NO.</th>
                        <th>Date & Time</th>
                        <th>Refrerence No</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>10-05-2023 3:45 pm</td>
                        <td>RN15412198425</td>
                        <td>Card Payment</td>
                        <td>Success</td>
                        <td>500</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>10-05-2023 3:45 pm</td>
                        <td>RN15412135412</td>
                        <td>Cash Payment</td>
                        <td>Success</td>
                        <td>1000</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>10-05-2023 3:45 pm</td>
                        <td>RN1541284512</td>
                        <td>Card Payment</td>
                        <td>Success</td>
                        <td>5000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="sectionBox mt-2">
          <div className='main-heading'>
            <h2 >Cashier</h2>
          </div>
          <div className="tableFixHead">
            <div className='d-flex min-h-100'>
              <div className='p-col-3 details-sidebar'>
                <button className={isActive.table === 'deposite' ? `btn p-hd-fill-inner-btn active` : 'btn p-hd-fill-inner-btn '} id="deposite"
                  onClick={(e) => {
                    hideShowDiv(e)
                  }}>Deposit</button>
                <button className={isActive.table === 'coHistory' ? `btn p-hd-fill-inner-btn active` : 'btn p-hd-fill-inner-btn'} id="coHistory"
                  onClick={(e) => {
                    hideShowDiv(e)
                  }}>Deposit History</button>
                <button className={isActive.table === 'cashout' ? `btn p-hd-fill-inner-btn active` : 'btn p-hd-fill-inner-btn'} id="cashout"
                  onClick={(e) => {
                    hideShowDiv(e)
                  }}>Cash Out</button>
                <button className={isActive.table === 'dHistory' ? `btn p-hd-fill-inner-btn active` : 'btn p-hd-fill-inner-btn'} id="dHistory"
                  onClick={(e) => {
                    hideShowDiv(e)
                  }}>Cash Out History</button>
                <button className={isActive.table === 'buyHours' ? `btn p-hd-fill-inner-btn active` : 'btn p-hd-fill-inner-btn '} id="buyHours"
                  onClick={(e) => {
                    hideShowDiv(e)
                  }}>Buy Hours</button>
                <button className={isActive.table === 'allTra' ? `btn p-hd-fill-inner-btn active` : 'btn p-hd-fill-inner-btn'} id="allTra"
                  onClick={(e) => {
                    hideShowDiv(e)
                  }}>All Transaction</button>

              </div>
              <div className='p-col-9'>
                <div className=''>
                  <div className={isActive.table === 'cashout' ? `cashout` : 'cashout d-none'}>
                    <form className='cahsierForm'>
                      <div className='form-group'>
                        <label>Withdraw Amount</label>
                        <div className="inputWithIcon">
                          <input type="text" placeholder="Amount..."></input>
                        </div>
                      </div>
                      <div className='form-group mt-1'>
                        <div className='d-flex'>
                          <button className='btn payment-method-btn m-1'>$ 100</button>
                          <button className='btn payment-method-btn m-1'>$ 500</button>
                          <button className='btn payment-method-btn m-1'>$ 1,000</button>
                          <button className='btn payment-method-btn m-1'>$ 2,000</button>
                          <button className='btn payment-method-btn m-1'>$ 5,000</button>
                        </div>
                      </div>
                      <div className='form-group  mt-3'>
                        <label>Select Payment Gateway</label>
                        <select className='form-control'>
                          <option>Select Payment Gateway</option>
                          <option>Paysafe</option>
                          <option>SticPay</option>
                          <option>Luxon</option>
                          <option>Skrill (Via Paysafe)</option>
                        </select>
                      </div>

                      <div className='form-group mt-3'>
                        <button className='btn deposite-method-btn'>WITHDRAW</button>
                      </div>
                    </form>
                  </div>
                  <div className={isActive.table === 'deposite' ? `deposite` : 'deposite d-none'}>
                    <form className='cahsierForm'>
                      <div className='form-group'>
                        <label>Select Method</label>
                        <select className='form-control'>
                          <option>Select Payment Method</option>
                          <option>Paysafe</option>
                          <option>SticPay</option>
                          <option>Luxon</option>
                          <option>Skrill (Via Paysafe)</option>
                        </select>
                      </div>
                      <div className='form-group'>
                        <label className='text-mute'>Recommended payment methods</label>
                        <div className='d-flex'>
                          <button className='btn payment-method-btn m-1'>Tether</button>
                          <button className='btn payment-method-btn m-1'>Bitcoin</button>
                          <button className='btn payment-method-btn m-1'>Skrill</button>
                          <button className='btn payment-method-btn m-1'>Luxon</button>
                          <button className='btn payment-method-btn m-1'>Paysafe</button>
                        </div>
                      </div>
                      <div className='form-group mt-3'>
                        <label>Deposite Amount</label>
                        <div className="inputWithIcon">
                          <input type="text" placeholder="Amount..."></input>
                        </div>
                      </div>
                      <div className='form-group mt-1'>
                        <div className='d-flex'>
                          <button className='btn payment-method-btn m-1'>$ 100</button>
                          <button className='btn payment-method-btn m-1'>$ 500</button>
                          <button className='btn payment-method-btn m-1'>$ 1,000</button>
                          <button className='btn payment-method-btn m-1'>$ 2,000</button>
                          <button className='btn payment-method-btn m-1'>$ 5,000</button>
                        </div>
                      </div>
                      <div className='form-group mt-3'>
                        <label>Bonus Deposite Code</label>
                        <input type="text" placeholder="Bonus Deposite Code"></input>
                      </div>
                      <div className='form-group mt-3'>
                        <button className='btn deposite-method-btn'>DEPOSIT</button>
                      </div>
                    </form>
                  </div>
                  <div className={isActive.table === 'coHistory' ? `coHistory` : 'coHistory d-none'}>
                    <form className='cahsierForm'>
                      <table className='table p-lb-table table-borderless'>
                        <thead>
                          <tr>
                            <th>Sr NO.</th>
                            <th>Date & Time</th>
                            <th>Refrerence No</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>10-05-2023 3:45 pm</td>
                            <td>RN15412198425</td>
                            <td>Card Payment</td>
                            <td>Success</td>
                            <td>500</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>10-05-2023 3:45 pm</td>
                            <td>RN15412135412</td>
                            <td>Card Payment</td>
                            <td>Success</td>
                            <td>1000</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>10-05-2023 3:45 pm</td>
                            <td>RN1541284512</td>
                            <td>Cash Payment</td>
                            <td>Success</td>
                            <td>5000</td>
                          </tr>

                        </tbody>
                      </table>
                    </form>
                  </div>
                  <div className={isActive.table === 'dHistory' ? `dHistory` : 'dHistory d-none'}>
                    <form className='cahsierForm'>
                      <table className='table p-lb-table table-borderless'>
                        <thead>
                          <tr>
                            <th>Sr NO.</th>
                            <th>Date & Time</th>
                            <th>Refrerence No</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>10-05-2023 3:45 pm</td>
                            <td>RN15412198425</td>
                            <td>Card Payment</td>
                            <td>Success</td>
                            <td>500</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>10-05-2023 3:45 pm</td>
                            <td>RN15412135412</td>
                            <td>Crypto Payment</td>
                            <td>Success</td>
                            <td>1000</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>10-05-2023 3:45 pm</td>
                            <td>RN1541284512</td>
                            <td>Cash Payment</td>
                            <td>Success</td>
                            <td>5000</td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                  <div className={isActive.table === 'buyHours' ? `buyHours` : 'buyHours d-none'}>
                    <div className='cahsierForm buyPackageBox'>
                      <div className='form-group'>
                        {/* <label className='text-center mb-4'>BUY HOUR PACKAGE</label> */}
                        <div className=''>
                          <div className='d-flex gap-3'>
                            <div className='p-col-3'>
                              <div className='package-box'>
                                <div className='package-name-header'>
                                  <p>STARTER</p>
                                </div>
                                <div className='package-box-body'>
                                  <ul>
                                    <li>Get 2 Hour</li>
                                    <li>$25 per hour</li>
                                  </ul>
                                  <button className='btn buy-package-btn' onClick={() => buyPackage('02:00')}>Buy Now</button>
                                </div>
                              </div>
                            </div>
                            <div className='p-col-3'>
                              <div className='package-box'>
                                <div className='package-name-header'>
                                  <p>BOOSTER</p>
                                </div>
                                <div className='package-box-body'>
                                  <ul>
                                    <li>Get 5 Hour</li>
                                    <li>$22 per hour</li>
                                  </ul>
                                  <button className='btn buy-package-btn' onClick={() => buyPackage('05:00')}>Buy Now</button>
                                </div>
                              </div>
                            </div>
                            <div className='p-col-3'>
                              <div className='package-box'>
                                <div className='package-name-header'>
                                  <p>BRONZE</p>
                                </div>
                                <div className='package-box-body'>
                                  <ul>
                                    <li>Get 10 Hour</li>
                                    <li>$20 per hour</li>
                                  </ul>
                                  <button className='btn buy-package-btn' onClick={() => buyPackage('10:00')}>Buy Now</button>
                                </div>
                              </div>
                            </div>
                            <div className='p-col-3'>
                              <div className='package-box'>
                                <div className='package-name-header'>
                                  <p>SILVER</p>
                                </div>
                                <div className='package-box-body'>
                                  <ul>
                                    <li>Get 20 Hour</li>
                                    <li>$18 per hour</li>
                                  </ul>
                                  <button className='btn buy-package-btn' onClick={() => buyPackage('20:00')}>Buy Now</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='d-flex gap-3 mt-2'>
                            <div className='p-col-3'>
                              <div className='package-box'>
                                <div className='package-name-header'>
                                  <p>GOLD</p>
                                </div>
                                <div className='package-box-body'>
                                  <ul>
                                    <li>Get 30 Hour</li>
                                    <li>$15 per hour</li>
                                  </ul>
                                  <button className='btn buy-package-btn' onClick={() => buyPackage('30:00')}>Buy Now</button>
                                </div>
                              </div>
                            </div>
                            <div className='p-col-3'>
                              <div className='package-box'>
                                <div className='package-name-header'>
                                  <p>PLATINUM</p>
                                </div>
                                <div className='package-box-body'>
                                  <ul>
                                    <li>Get 50 Hour</li>
                                    <li>$12 per hour</li>
                                  </ul>
                                  <button className='btn buy-package-btn' onClick={() => buyPackage('50:00')}>Buy Now</button>
                                </div>
                              </div>
                            </div>
                            <div className='p-col-3'>
                              <div className='package-box'>
                                <div className='package-name-header'>
                                  <p>TITANIUM</p>
                                </div>
                                <div className='package-box-body'>
                                  <ul>
                                    <li>Get 75 Hour</li>
                                    <li>$10 per hour</li>
                                  </ul>
                                  <button className='btn buy-package-btn' onClick={() => buyPackage('75:00')}>Buy Now</button>
                                </div>
                              </div>
                            </div>
                            <div className='p-col-3'>
                              <div className='package-box'>
                                <div className='package-name-header'>
                                  <p>DIAMOND</p>
                                </div>
                                <div className='package-box-body'>
                                  <ul>
                                    <li>Get 100 Hour</li>
                                    <li>$5 per hour</li>
                                  </ul>
                                  <button className='btn buy-package-btn' onClick={() => buyPackage('100:00')}>Buy Now</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        {/* <select className='form-control' value={selectedHour} onChange={(e) => setSelectedHour(e.target.value)}>
                          <option>Select Hour</option>
                          {hoursOption.map((hour, index) => {
                            return (
                              <option key={index} value={hour + ':00'}>{hour + ':00'}</option>
                            );
                          })
                          };
                        </select> */}
                      </div>

                      {/* <div className='form-group mt-3'>
                        <button className='btn deposite-method-btn' onClick={() => buyPackage(0)}>Buy Hour</button>
                      </div> */}
                    </div>
                    {/*  <center>
                      <table>
                        <tr>
                          <td width={'100%'}>
                            <div className='form-group'>
                              <label>Select Hour to buy</label>
                              <select className='form-control hour-buy-dropdown'>
                                
                              </select>
                            </div>
                          </td>
                        </tr>
                      </table>
                      <div className='avtarBtnSection'>
                        <button className='edtAvtarBtn' onClick={() => buyPackage(1)}>Cancle</button>
                      </div>
                    </center> */}
                  </div>
                  <div className={isActive.table === 'allTra' ? `allTra` : 'allTra d-none'}>
                    <form className='cahsierForm'>
                      <table className='table p-lb-table table-borderless'>
                        <thead>
                          <tr>
                            <th>Sr NO.</th>
                            <th>Date & Time</th>
                            <th>Refrerence No</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>10-05-2023 3:45 pm</td>
                            <td>RN15412198425</td>
                            <td>Card Payment</td>
                            <td>Success</td>
                            <td>500</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>10-05-2023 3:45 pm</td>
                            <td>RN15412135412</td>
                            <td>Cash Payment</td>
                            <td>Success</td>
                            <td>1000</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>10-05-2023 3:45 pm</td>
                            <td>RN1541284512</td>
                            <td>Card Payment</td>
                            <td>Success</td>
                            <td>5000</td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default CashierGameTable

