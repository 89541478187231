import React from 'react'
import {  HashRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import LandingScreen from './Screens/LandingScreen';
import LoginScreen from './Screens/LoginScreen';
import SignUpScreen from './Screens/SignUpScreen';
import LobbyScreen from './Screens/LobbyScreen';
import GameScreen from './Screens/GameScreen';
import { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <HashRouter >
      <Routes>
        <Route path="/" element={<LandingScreen />} />
        {/* <Route path="/signup" element={<SignUpScreen />} />
        <Route path="/login" element={<LoginScreen />} /> */}
        <Route path="/game" element={<GameScreen />} />
        <Route path="/lobby" element={<LobbyScreen />} />
      </Routes>
    </HashRouter >
  );
}

export default App;




